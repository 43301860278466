import {
  Avatar,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import customAxios from "../../axios"
import printIcon from "./../../assets/img/consultation/id-card icon.png"
import {
  AddBoxOutlined,
  BorderColor,
  CheckCircle,
  CheckCircleOutline,
  ChevronRight,
  Description,
  FastForward,
  History,
  Home,
  HomeOutlined,
  Info,
  LocationCity,
  PersonPinCircle,
  PinDrop,
  Print,
} from "@material-ui/icons"
import userImg from "./../../assets/user.png"
import mixpanel from "mixpanel-browser"
import moment from "moment"
import { toast } from "react-toastify"
import { useHistory } from "react-router"
import QuickEndModal from "./QuickEndModal"
import whatsappLogo from "./../../assets/img/icons/common/whatsapp.png"
function Sidebar({
  appointmentData,
  patientData,
  activeStep,
  setActiveStep,
  setLoading,
}) {
  const history = useHistory()
  const quickEndModalHeaders = [
    {
      label: "Follow-up Date",
      key: "expDate",
      type: "date",
      fullWidth: true,
    },
  ]
  const quickEndAppointment = (modalData) => {
    setModalLoading(true)
    customAxios
      .post("consultation/quick-end", {
        appointmentId: appointmentData._id,
        followUp: modalData.date
          ? { expDate: moment.utc(modalData?.date).format() }
          : null,
      })
      .then((res) => {
        if (res.data.success) {
          setModalLoading(false)

          mixpanel.track("Quick End Consultation with Follow Up")
          mixpanel.people.increment("Quick End Consultation with Follow Up")

          toast.success("Consultaion completed successfully.")
          setQuickEndModalOpen(false)
          history.push("/admin/dashboard")
        } else {
          setModalLoading(false)

          toast.error("Some error occured.")
        }
      })
      .catch((err) => {
        setModalLoading(false)

        mixpanel.track("Quick End Appointment Error", { ...err })

        toast.error("Some error occured.")
        console.log(err)
      })
  }
  const [quickEndModalOpen, setQuickEndModalOpen] = useState(false)
  const [modalData, setModalData] = useState({})
  const [modalLoading, setModalLoading] = useState(false)
  const patientInforoutes = [
    {
      label: "Medical Record",
      icon: AddBoxOutlined,
    },
    {
      label: "Patient Assessment",
      icon: Description,
    },
    {
      label: "Appointment History",
      icon: History,
    },
  ]
  const handleGenerateSticker = () => {
    setLoading(true)
    customAxios

      .get(
        `doctor/patient-sticker/${patientData?._id}?org_id=${appointmentData?.organisation?._id}`
      )
      .then((res) => {
        if (res.data.success) {
          setLoading(false)
          window.open(res.data.data.url, "_blank")

          console.log("765", res.data.data)
        } else {
          setLoading(false)
          toast.error("Some error occured")
        }
      })
      .catch((err) => {
        setLoading(false)
        toast.error("Some error occured")
      })
  }
  return (
    <Grid
      container
      direc
      style={{
        // width:    "300px",
        height: "90vh",
        padding: "1rem",
        backgroundColor: "#fff",
        borderRadius: "40px 0px 40px 0px",
      }}
    >
      <Grid item xs={12} container alignItems="center" justifyContent="center">
        <Grid
          item
          xs={4}
          container
          style={{ flexDirection: "column", alignItems: "center" }}
        >
          <Avatar
            style={{
              objectFit: "contain",
              width: "60px",
              height: "60px",
              backgroundColor: "#5e72e4",
            }}
          >
            {patientData?.name?.split("")[0]}
          </Avatar>
          <Button
            onClick={() => handleGenerateSticker()}
            title="Print patient details on a sticker"
            color="primary"
            size="small"
            style={{ borderRadius: "11px", marginTop: "0.5rem" }}
          >
            Print Sticker
          </Button>
        </Grid>
        <Grid item xs={8}>
          <Typography style={{ fontWeight: "700" }}>
            {patientData?.name}
          </Typography>
          <Typography>
            {patientData?.age} |{" "}
            {patientData?.gender.split("")[0].toUpperCase()} |{" "}
            {patientData?.birthDate}
          </Typography>
          <Typography>{patientData?.phoneNo}</Typography>
          <Typography>
            <span style={{}}># {patientData?.code}</span>
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={2} alignItems="center">
          <Grid item>
            <IconButton
              color="primary"
              title="Call Patient"
              onClick={() => {
                var a = document.createElement("a")
                document.body.appendChild(a)
                a.style = "display: none"
                a.href = `tel:${patientData.phoneNo}`
                a.click()
              }}
            >
              <i class="fas fa-phone-alt fa-flip-horizontal fa-0.75x"></i>{" "}
            </IconButton>
          </Grid>
          {appointmentData?.isOnline ? (
            <Grid item>
              <IconButton
                style={{ color: "green" }}
                title="Chat"
                onClick={() => {
                  history.push(`/admin/chat`, {
                    appointmentId: appointmentData._id,
                  })
                }}
              >
                <img
                  src={whatsappLogo}
                  style={{
                    width: "28px",
                    objectFit: "contain",
                  }}
                />{" "}
              </IconButton>
            </Grid>
          ) : null}
          {appointmentData?.link ? (
            <Grid item>
              <IconButton
                color="primary"
                title="Video call"
                onClick={() => {
                  window.open(appointmentData.link)
                }}
              >
                <i class="fas fa-video"></i>
              </IconButton>
            </Grid>
          ) : null}
        </Grid>
        {patientData?.address ? (
          <Grid item xs={12} style={{ margin: "0.5rem 0" }}>
            <Typography>
              <PinDrop color="primary" fontSize="small" />{" "}
              <span style={{}}> {patientData?.address}</span>
            </Typography>
          </Grid>
        ) : null}
      </Grid>
      <Grid item xs={12} style={{ margin: "0.5rem 0" }}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ fontWeight: "600" }}>Patient Info</Typography>
      </Grid>
      {patientInforoutes?.map((ele) => {
        const { icon: Icon } = ele
        return (
          <Grid
            item
            xs={12}
            container
            onClick={() => setActiveStep(ele.label)}
            onMouseEnter={() => setActiveStep(ele.label)}
            alignItems="center"
            style={{
              cursor: "pointer",
              flexWrap: "nowrap",
              backgroundColor: activeStep === ele.label ? "#5e72e4" : "#fff",
              borderRadius: "11px",
              padding: "0.5rem",
              margin: "0.25rem 0 0 0 ",
            }}
            justifyContent="space-between"
          >
            <Grid item container>
              <Icon
                style={{ color: activeStep === ele.label ? "#fff" : "#5e72e4" }}
              />
              <Typography
                style={{
                  paddingLeft: "1rem",
                  color: activeStep === ele.label ? "#fff" : "#696969",
                }}
              >
                {ele.label}
              </Typography>
            </Grid>
            <Grid item>
              <ChevronRight
                style={{ color: activeStep === ele.label ? "#fff" : "#5e72e4" }}
              />
            </Grid>
          </Grid>
        )
      })}
      <Grid item xs={12} style={{ marginTop: "0.5rem" }}>
        <Typography style={{ fontWeight: "600" }}>Prescription Pad</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        container
        onClick={() => setActiveStep("Write Prescription")}
        alignItems="center"
        onMouseEnter={() => setActiveStep("Write Prescription")}
        style={{
          cursor: "pointer",
          flexWrap: "nowrap",
          borderRadius: "11px",
          padding: "0.5rem",
          margin: "0.25rem 0 0 0 ",

          backgroundColor:
            activeStep === "Write Prescription" ? "#5e72e4" : "#fff",
        }}
        justifyContent="space-between"
      >
        <Grid item container>
          <BorderColor
            style={{
              color: activeStep === "Write Prescription" ? "#fff" : "#5e72e4",
            }}
          />
          <Typography
            style={{
              paddingLeft: "1rem",
              color: activeStep === "Write Prescription" ? "#fff" : "#696969",
            }}
          >
            Write Prescription
          </Typography>
        </Grid>
        <Grid item>
          <ChevronRight
            style={{
              color: activeStep === "Write Prescription" ? "#fff" : "#5e72e4",
            }}
          />
        </Grid>
      </Grid>{" "}
      {/* <Grid
        item
        xs={12}
        container
        onClick={() => setActiveStep("End Consultation")}
        onMouseEnter={() => setActiveStep("End Consultation")}
        alignItems="center"
        style={{
          cursor: "pointer",
          flexWrap: "nowrap",
          borderRadius: "11px",
          padding: "0.5rem",
          margin: "0.25rem 0 0 0 ",

          backgroundColor:
            activeStep === "End Consultation" ? "#5e72e4" : "#fff",
        }}
        justifyContent="space-between"
      >
        <Grid item container>
          <CheckCircleOutline
            style={{
              color: activeStep === "End Consultation" ? "#fff" : "#5e72e4",
            }}
          />
          <Typography
            style={{
              paddingLeft: "1rem",
              color: activeStep === "End Consultation" ? "#fff" : "#696969",
            }}
          >
            End Consultation
          </Typography>
        </Grid>
        <Grid item>
          <ChevronRight
            style={{
              color: activeStep === "End Consultation" ? "#fff" : "#5e72e4",
            }}
          />
        </Grid>
      </Grid>{" "} */}
      <Grid item xs={12} style={{ margin: "0.5rem 0" }}>
        <Divider />
      </Grid>
      <Grid item xs={12} container justifyContent="center">
        <Button
          title="Send a follow-up date and end consultation without prescription details"
          startIcon={<FastForward />}
          fullWidth
          onClick={() => setQuickEndModalOpen(true)}
          style={{ color: "#DD4D4D", border: "1px solid #DD4D4D" }}
        >
          Quick End Consultation
        </Button>
      </Grid>
      {quickEndModalOpen ? (
        <QuickEndModal
          open={quickEndModalOpen}
          onSubmit={quickEndAppointment}
          setOpen={setQuickEndModalOpen}
          modalData={modalData}
          loading={modalLoading}
          message={
            <div>
              Are you sure you want to{" "}
              <span className="text-danger">end the consultation</span>?
            </div>
          }
          setModalData={setModalData}
          dialogTitle="Quick End Consultation"
          submitButtonText="End Consultation"
        />
      ) : null}
    </Grid>
  )
}

export default Sidebar
