import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';

// import Upcoming from '../../../assets/img/Dashboard/upcoming.svg';
// import Completed from '../../../assets/img/Dashboard/completed.svg';
// import Cancelled from '../../../assets/img/Dashboard/cancelled.svg';
import customAxios from '../../../axios';

function DailyMetics() {
	const [upcoming, setUpcoming] = useState('-');
	const [completed, setCompleted] = useState('-');
	const [cancelled, setCancelled] = useState('-');
	const currOrganisation = useSelector((state) => state.user.currOrganisation);
	const allClinics = useSelector((state) => state.user.allClinics);
	useEffect(() => {
		if (currOrganisation) {
			customAxios
				.get(
					!allClinics
						? `/appointments/day/today?org_id=${currOrganisation?._id}&status=all`
						: `/appointments/day/today?&status=all`
				)

				.then((res) => {
					if (res) {
						setUpcoming(res?.data?.data?.length?.toString());
					}
				});
			customAxios
				.get(
					!allClinics
						? `/appointments/day/today?org_id=${currOrganisation?._id}&status=completed`
						: `/appointments/day/today?&status=completed`
				)

				.then((res) => {
					if (res) setCompleted(res?.data?.data?.length?.toString());
				});
			customAxios
				.get(
					!allClinics
						? `/appointments/day/today?org_id=${currOrganisation?._id}&status=cancelled`
						: `/appointments/day/today?&status=cancelled`
				)
				.then((res) => {
					if (res) setCancelled(res?.data?.data?.length?.toString());
				});
		}
	}, [currOrganisation, allClinics]);
	return (
		<Card style={{ height: '93%' }}>
			<CardHeader>
				<h5 className='h3 mb-0'>Daily Metrics</h5>
			</CardHeader>

			<CardBody>
				<Row>
					<Col md={4}>
						<center>
							{/* <img src={Upcoming} alt="upcoming" className="mb-1" /> */}
							<h1 className='display-4 pb-0 mb-0 font-weight-bold' style={{ color: '#98dd29' }}>
								{upcoming}
							</h1>
							<p className=''>Upcoming Appointments</p>
						</center>
					</Col>
					<Col md={4}>
						<center>
							{/* <img src={Completed} alt="upcoming" className="mb-1" /> */}
							<h1 className='display-4 pb-0 mb-0 font-weight-bold' style={{ color: '#5ac6e9' }}>
								{completed}
							</h1>
							<p className=''>Completed Appointments</p>
						</center>
					</Col>
					<Col md={4}>
						<center>
							{/* <img src={Cancelled} alt="upcoming" className="mb-1" /> */}
							<h1 className='display-4 pb-0 mb-0 font-weight-bold' style={{ color: '#fb5555' }}>
								{cancelled}
							</h1>
							<p className=''>Cancelled Appointments</p>
						</center>
					</Col>
				</Row>
			</CardBody>
		</Card>
	);
}

export default DailyMetics;
