/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react"
// react library for routing
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom"
// core components
import AdminNavbar from "../components/Navbars/AdminNavbar.js"
import Sidebar from "../components/Sidebar/Sidebar.js"
import { useDispatch, useSelector } from "react-redux"
import routes from "../routes.js"
import jwt_decode from "jwt-decode"
import { logout } from "../redux/actions/auth.actions.js"
import { REMOVE_AUTHENTICATION_STATUS } from "../redux/constants/index.js"
import moment from "moment"
import { setCurrDateTypeValue } from "../redux/actions/user.actions.js"
import { ContactSupportOutlined } from "@material-ui/icons"

function Admin(props) {
  const [state, setState] = useState({ sidenavOpen: false })

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const isSubscribe = useSelector((state) => state.user?.user?.isSubscribe)
  const isNewUser = useSelector((state) => state.auth.newUser)
  const isDoctor = useSelector((state) => state.auth.isDoctor)

  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const userToken = useSelector((state) => state.auth.access_token)
  if (userToken != null) {
    const { expires } = jwt_decode(userToken)

    const expirationTime = expires * 1000 - 60000
    if (Date.now() >= expirationTime) {

      dispatch({ type: REMOVE_AUTHENTICATION_STATUS })
      dispatch(logout())
      window.location = "	/auth/login"
    }
  } else {

    dispatch({ type: REMOVE_AUTHENTICATION_STATUS })
    dispatch(logout())
    window.location = "/auth/login"
  }

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      const { component: Component } = prop
      if (prop.collapse) {
        return getRoutes(prop.views)
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => {
              if (isDoctor) {
                // if (!isSubscribe || isSubscribe === "notOnboard") {
                //   navigateOnboarding();
                // }
                if (isSubscribe === "pending" || isSubscribe === "inactive")
                  navigateToVerification()
                else {
                  return <Component {...props} />
                }
              } else {
                if (props.onlyDoctor)
                  return <Redirect to="/admin/broadcast"></Redirect>
                else return <Component {...props} />
              }
            }}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }
  const navigateToVerification = () => {
    history.push("/verificationPending")
  }
  const navigateToLogin = () => {
    history.push("/auth/login")
  }
  const navigateOnboarding = () => {
    history.push("/onboarding/welcome-doctor")
  }
  const currentDateType = useSelector((state) => state?.user?.currDateTypeValue)

  const orgId = useSelector((state) => state?.user?.user?.organisation?.createdByOrg ?? state?.user?.user?.organisation?.orgId )

  useEffect(() => {
    if (
      currentDateType.label === "Today" &&
      moment.utc(currentDateType.value).format("DD-MM-YYYY") !==
        moment.utc().format("DD-MM-YYYY")
    ) {
      const final = {
        label: "Today",
        value: moment.utc(new Date()).toISOString(),
      }
      dispatch(setCurrDateTypeValue(final))
    }
  }, [])
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name
      }
    }
    return "Brand"
  }
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned")
      document.body.classList.add("g-sidenav-hidden")
    } else {
      document.body.classList.add("g-sidenav-pinned")
      document.body.classList.remove("g-sidenav-hidden")
    }
    setState({
      sidenavOpen: !state.sidenavOpen,
    })
  }
  const getNavbarTheme = () => {
    return props.location.pathname.indexOf("admin/alternative-dashboard") === -1
      ? "dark"
      : "light"
  }

  const closeSidenav = () => {
    setState({ sidenavOpen: false })
  }
  return (
    <>
      {isAuthenticated ? (
        <>
          {orgId !== "650d3d526001fd08680985a6" ? <Sidebar
            {...props}
            routes={routes}
            isDoctor={isDoctor}
            // toggleSidenav={toggleSidenav}
            sidenavOpen={state.sidenavOpen}
            logo={{
              innerLink: "/",
              imgSrc: require("../assets/logos/Logo.svg"),
              imgAlt: "...",
            }}
          /> : null}

          <div className="main-content" onClick={() => closeSidenav()} >
            {location.pathname.includes("consultation") || (orgId === "650d3d526001fd08680985a6" || orgId === "66047e6fe9d3f0c47f5c5fee") ? null : (
              <AdminNavbar
                {...props}
                theme={getNavbarTheme()}
                toggleSidenav={toggleSidenav}
                sidenavOpen={state.sidenavOpen}
                brandText={getBrandText(props.location.pathname)}
              />
            )}
            <Switch>{getRoutes(routes)}</Switch>
          </div>
        </>
      ) : (
        navigateToLogin()
      )}
    </>
  )
}

export default Admin
