import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core"
import { Create, GetApp, Print, Receipt } from "@material-ui/icons"
import React, { useState } from "react"
import { toast } from "react-toastify"
import { createLogicalAnd } from "typescript"
import prescriptionSocket from "../../../socket"
import Invoice from "../Invoice"
import AWS from "aws-sdk"
import GenericDialogForm from "./../../CreatePrescription/GenericDialog/index"
import customAxios from "../../../axios"
import whatsappLogo from "./../../../assets/img/icons/common/whatsapp.png"
function EndConsultation({
  appointmentData,
  setActiveStep,
  state,
  setState,
  setLoading,
  requiredKeys = [],
  handleEndConsultation,
}) {
  const [invoiceOpen, setInvoiceOpen] = useState(false)
  const [sendToPatient, setSendToPatient] = useState(true)
  const [phoneNumber, setPhoneNumber] = useState("")
  const [dialogOpen, setDialogOpen] = useState(false)
  const [modalData, setModalData] = useState({})
  const [invoiceNo, setInvoiceNo] = useState(null)
  var reg = new RegExp("^[0-9]*$")
  const handleDownloadInvoice = (data, download = false, print = false) => {
    var a = document.createElement("a")
    document.body.appendChild(a)
    a.style = "display: none"
    AWS.config.update({
      accessKeyId: "AKIAUSXZZ7HZALZ7XCS2",
      secretAccessKey: "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",
    })
    var s3 = new AWS.S3()
    let params = {
      Bucket: "paperplane-s3",
      Key: `${data.location + data._id}`,
    }
    customAxios
      .put(`consultation/send-invoice/${appointmentData._id}`)
      .then((res) => {
        if (res.data.success) {
          toast.success(`Invoice sent successfully .`)
        } else {
          toast.error("Some error occured while sending the invoice.")
        }
      })
      .catch((err) => {
        console.log(err)
        toast.error("Some error occured")
      })

    s3.getObject(params, (err, res) => {
      let blob = new Blob([res.Body], { type: res.ContentType })
      if (download) {
        a.href = window.URL.createObjectURL(blob)
        a.download = `${appointmentData?.patient?.name}_(#${appointmentData?.patient?.code})_Invoice`
        a.click()
        window.URL.revokeObjectURL(data?.url)
      }
      if (print) {
        let pdfw = window.open(window.URL.createObjectURL(blob), "_blank")
        // you need to call print after window loads like this
        if (pdfw) {
          pdfw.onload = function () {
            pdfw.print()
          }
        } else {
          window.alert("Please allow popups")
        }
      }
      setLoading(false)
    })
  }

  const checkDisableDownload = (state) => {
    let error = {}
    requiredKeys.map((v) => {
      if (!state[v.key] || !state[v.key].length) {
        error = { check: true, message: `${v.label} is required` }
      }
    })
    if (error?.check) {
      return true
    }
    return false
  }
  return (
    <Grid container>
      <Grid item xs={12} md={6} style={{ display: "flex", flex: "flex-wrap" }}>
        <Typography variant="h5" style={{ fontWeight: "600" }}>
          End
        </Typography>
        <Typography
          variant="h5"
          color="primary"
          style={{ marginLeft: 5, fontWeight: "600" }}
        >
          Consultation{" "}
        </Typography>
      </Grid>
      <Grid
        container
        spacing={1}
        style={{
          padding: "2rem 1rem",
          marginTop: "1rem",
          backgroundColor: "#fff",
          borderRadius: "0px 40px 0px 40px",
        }}
      >
        <Grid item xs={12}>
          <Typography>Prescription</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            startIcon={<Create color="primary" />}
            onClick={() => {
              document.getElementById("prescriptionContainer").scrollIntoView()
            }}
          >
            Edit Prescription
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            startIcon={<GetApp color="primary" />}
            disabled={checkDisableDownload(state)}
            onClick={() =>
              handleEndConsultation({
                onlyPrescription: true,
                download: true,
                sendToPatient: false,
              })
            }
          >
            Download Prescription
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            startIcon={<Print color="primary" />}
            disabled={checkDisableDownload(state)}
            onClick={() =>
              handleEndConsultation({
                onlyPrescription: true,
                print: true,
                sendToPatient: false,
              })
            }
          >
            Print Prescription
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="tel"
            value={phoneNumber}
            InputProps={{
              endAdornment: (
                <img
                  src={whatsappLogo}
                  style={{
                    width: "22px",
                    objectFit: "contain",
                  }}
                />
              ),
            }}
            variant="outlined"
            placeholder="Additional Phone Number"
            helperText={
              "Add any other phone number you want to send prescription to on WhatsApp"
            }
            inputProps={{ maxLength: "10" }}
            onChange={(e) => {
              if (reg.test(e.target.value)) setPhoneNumber(e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            color="primary"
            onClick={() => setDialogOpen(true)}
            startIcon={<Receipt />}
          >
            Send Invoice
          </Button>
        </Grid>
        <Grid item xs={12} style={{ margin: "1rem 0rem" }}>
          <Divider />
        </Grid>
        <Grid item xs={12} container direction="column" spacing={2}>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={sendToPatient}
                  onChange={(e) => setSendToPatient(e.target.checked)}
                />
              }
              label="Send Prescription to Patient"
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              style={{ color: "#fff", backgroundColor: "#00A87B" }}
              startIcon={<i class="fab fa-whatsapp"></i>}
              onClick={() =>
                handleEndConsultation({
                  sendToPatient: sendToPatient,
                  onlyPrescription: false,
                  additionalPhoneNumber: phoneNumber,
                })
              }
            >
              End Consultation
            </Button>
            <Typography
              variant="body1"
              color="secondary"
              style={{ marginTop: "0.5rem" }}
            >
              Click here to end consultation and send prescription on patient's
              WhatsApp
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* <Invoice
        open={invoiceOpen}
        setOpen={setInvoiceOpen}
        state={appointmentData}
        billFor={modalData.billFor}
        invoiceNo={invoiceNo}
        amount={modalData?.amount}
        id={appointmentData?._id}
      ></Invoice> */}
      {dialogOpen ? (
        <GenericDialogForm
          style={{ overflowY: "visible" }}
          open={dialogOpen}
          handleClose={() => setDialogOpen(false)}
          dialogTitle="Add Info"
          formData={{ invoiceFor: "Consultation" }}
          dialogContent={[
            {
              label: "Bill for",
              key: "invoiceFor",
              type: "autocomplete",
              md: 6,
              options: ["Vaccination", "X-Ray", "Service", "Consultation"],
              required: true,
            },
            {
              label: "Invoice amount (Rs.)",
              key: "amount",
              type: "text",
              md: 6,
              required: true,
            },
            {
              label: "Details",
              key: "details",
              type: "text",
              md: 12,
              placeholder: "Eg: 15 sessions of Miniscus tear physiotherapy",
              required: false,
            }
          ]}
          handleSubmit={(formData) => {
            setModalData({ ...formData })
            prescriptionSocket.emit("invoice_no", {
              appointmentId: appointmentData?._id,
            })
            setLoading(true)
            console.log("hello", formData)
            customAxios
              .put(`/consultation/generate-invoice/${appointmentData?._id}`, {
                ...formData,
              })
              .then((res) => {
                if (res.data.success) {
                  handleDownloadInvoice(res.data.data, true, false)
                } else {
                  toast.error("Some error occured")
                  setLoading(false)
                }
              })
              .catch((err) => {
                setLoading(false)

                toast.error("Some error occured")
              })
          }}
        />
      ) : null}
    </Grid>
  )
}

export default EndConsultation
