import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import customAxios from "../../../axios"
import GenericCardTable from "../GenericCardTable"
import { diseasesValueForSearch } from "./../Constants/index"
function Diagnosis({ state, setState, docId }) {
  const [suggestedDiagnosis, setSuggestedDiagnosis] = useState([])
  const user = useSelector((state) => state.user)
  useEffect(() => {
    const diagnosisLength = state?.diagnosis?.length
    if (diagnosisLength) {
      let suggestedState = []
      let querydiagnosis = state.diagnosis.map((ele) => ele.name)
      console.log("resd", user.user)
      customAxios
        .put(`/ai/co-diagnosis`, {
          names: querydiagnosis.reverse(),
          doc_id: docId,
          spec: user?.user?.additionalDetail?.qualification
            ? user?.user?.additionalDetail?.qualification
            : "",
        })
        .then((res) => {
          if (res?.data?.success) {
            console.log("resd", res.data.data)
            suggestedState = [...res.data.data, ...suggestedState]
            suggestedState = [...new Set(suggestedState)]
            suggestedState = suggestedState.filter(
              (symp) =>
                !state?.diagnosis?.find(
                  (f) => f?.name?.toLowerCase() === symp.toLowerCase()
                )
            )
            setSuggestedDiagnosis([...suggestedState])
          }
        })
    }
  }, [state?.diagnosis])
  useEffect(() => {
    const symptomsLength = state?.symptoms?.length
    if (symptomsLength) {
      let suggestedState = []
      let querydiagnosis = state?.symptoms?.map((ele) => ele?.name)
      console.log("resd", user.user)
      customAxios
        .put(`/ai/diagnosis`, {
          names: querydiagnosis.reverse(),
          doc_id: docId,
          spec: user?.user?.additionalDetail?.qualification
            ? user?.user?.additionalDetail?.qualification
            : "",
        })
        .then((res) => {
          if (res?.data?.success) {
            console.log("resd", res.data.data)
            suggestedState = [...res.data.data, ...suggestedState]
            suggestedState = [...new Set(suggestedState)]
            suggestedState = suggestedState.filter(
              (symp) =>
                !state?.diagnosis?.find(
                  (f) => f?.name?.toLowerCase() === symp.toLowerCase()
                )
            )
            setSuggestedDiagnosis([...suggestedState])
          }
        })
    }
  }, [state?.symptoms])
  const cardTableHeaders = [
    {
      label: "Diagnosis",
      key: "name",
      placeholder: "eg. Influenza",
      type: "autocomplete",
      options: diseasesValueForSearch,
      required: true,
      width: "25%",
    },
    {
      label: "State",
      key: "status",
      // helperText: "Use  ⬅️  ➡️ to select value",
      placeholder: "None",
      type: "radio",
      width: "30%",
      dependencyKey: "name",
      options: ["Suspected", "Confirmed"],
    },
  ]

  return (
    <React.Fragment>
      <GenericCardTable
        cardTitle={"Diagnosis Details"}
        suggestedTitle={"Suggested Diagnosis"}
        tableHeaders={cardTableHeaders}
        tableData={state}
        tabSwitching={true}
        suggestedTab={suggestedDiagnosis.length}
        suggestedData={suggestedDiagnosis}
        cardKey="diagnosis"
        setTableData={setState}
      />
    </React.Fragment>
  )
}

export default Diagnosis
