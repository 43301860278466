/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react"
import ReactGA from "react-ga4"
// react library for routing
import { Route, Switch, Redirect, useLocation, useHistory } from "react-router-dom"
// bootstrap rtl for rtl support page
import "./assets/vendor/bootstrap-rtl/bootstrap-rtl.scss"
// plugins styles from node_modules
import "react-notification-alert/dist/animate.css"
import "react-perfect-scrollbar/dist/css/styles.css"
// plugins styles downloaded
import "./assets/vendor/fullcalendar/dist/fullcalendar.min.css"
import "./assets/vendor/sweetalert2/dist/sweetalert2.min.css"
import "./assets/vendor/select2/dist/css/select2.min.css"
import "./assets/vendor/quill/dist/quill.core.css"
import "react-toastify/dist/ReactToastify.css"

import "./assets/vendor/nucleo/css/nucleo.css"
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css"
// core styles
import "./assets/scss/argon-dashboard-pro-react.scss"
import mixpanel from "mixpanel-browser"
import AdminLayout from "./layouts/Admin.js"
import AuthLayout from "./layouts/Auth.js"

import MeetLayout from "./layouts/Meet"
import { toast, ToastContainer } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import PendingVerification from "./components/PendingVerification"
import dashboardSocket from "./socket/dashboardSocket"
// import ProductTour from "./components/ProductTour/index";
import { Button } from "reactstrap"
import { logout } from "./redux/actions/auth.actions"
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core"
import GenericDialogForm from "./components/CreatePrescription/GenericDialog"
import RouteChat from "./views/pages/ChatDashboard/route-chat"
// import IndexView from "views/Index.js";
//import User from "views/pages/user/User";

function usePageViews(prevLocation, setprevLocation) {
  let location = useLocation()

  useEffect(() => {
    ReactGA.initialize("G-CDV3NDWRN4")
    ReactGA.pageview(window.location.pathName)
    // ReactGA.set({page:location.pathname})
    // ReactGA.event({
    //   category: location.pathname,
    //   action: location.pathname,
    //   label: location.pathname+"HELLO"
    //   });
    // ReactGA.send({ hitType: location.pathname, page: location.pathname });
    //eslint-disable-next-line
  }, [location])
}

function App() {
  const [run, setRun] = useState(false)
  const history = useHistory();
  mixpanel.init("fffa94ab913677a6f8412f0eb5d3fddf", {
    ignore_dnt: true,
  })
  const dispatch = useDispatch()
  const userId = useSelector((state) => state.user.user._id)
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  if (userId) {
    mixpanel.identify(userId)
  }
  //Hello this is a change

  window.addEventListener("history_push", (e) => {
    history.push(e.detail);
  })

  const online = window.navigator.onLine
  if (!online) {
    toast.error(
      "Offline. Please check your internet connection and reload the application"
    )
  }
  const [prevLocation, setprevLocation] = useState(null)
  usePageViews(prevLocation, setprevLocation)

  return (
    <>
      <Switch>
        <Route exact path="/admin/route-chat/:id" render={(props) => <RouteChat {...props}/>} />
        <Route
          path="/admin"
          render={(props) => <AdminLayout {...props} setRun={setRun} />}
        />
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Route
          path="/verificationPending"
          render={(props) => <PendingVerification />}
        />
        <Route path="/meet" render={(props) => <MeetLayout {...props} />} />

        {/* <Route path='/' render={(props) => <IndexView {...props} />} /> */}
        <Redirect from="/" to="/admin/broadcast" />
        <Redirect from="*" to="/admin/broadcast" />
      </Switch>

      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        limit={1}
        pauseOnFocusLoss={false}
        hideProgressBar={true}
      />
    </>
  )
}

export default App
