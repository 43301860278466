import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  Divider,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core"
import { Add, Delete, DeleteForever } from "@material-ui/icons"
import customAxios from "../../../axios"
import { useSelector } from "react-redux"
import GenericDialogForm from "../GenericDialog"
import EditTextField from "./EditTextField"

function Vitals({ state, setState, docId }) {
  const re = new RegExp("^[0-9-/.]*$")
  const suggestedVitals1 = [
    {
      metricName: "BP",
      metricUnit: "mmHG",
    },
    {
      metricName: "Temperature",
      metricUnit: "K",
    },
  ]
  const dialogContent = [
    {
      label: "Label",
      key: "metricName",
      placeholder: "eg. Respiration",
      type: "text",
      required: true,
    },
    {
      label: "Unit",
      key: "metricUnit",
      placeholder: "eg. BPM",
      type: "text",
      required: true,
    },
    {
      label: "Value",
      key: "metricQuantity",
      placeholder: "eg. 78",
      type: "text",
      required: true,
      fullWidth: true,
      md: 12,
    },
  ]

  const [editMode, setEditMode] = useState(false)
  const [openDialog, setOpenDialog] = useState({})
  const [suggestedVitals, setSuggestedVitals] = useState([])

  const theme = useTheme()
  useEffect(() => {
    if (docId)
      customAxios.get(`/doctor/get-custom-vitals/${docId}`).then((res) => {
        setSuggestedVitals([...res.data.data])
      })
  }, [docId])

  const addVital = (data) => {
    const temp = [...state.vitals]
    temp.push({ ...data, focus: true })
    setState({ ...state, vitals: [...temp] })
  }

  const removeVital = () => {
    const temp = [...state.vitals]
    temp.splice(openDialog?.index, 1)
    setState({ ...state, vitals: [...temp] })
    setOpenDialog({})
  }

  const updateVital = (data) => {
    const temp = [...state.vitals]
    temp[openDialog?.index] = { ...data }
    setState({ ...state, vitals: [...temp] })
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6">Vitals</Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          style={{ marginTop: "0.5rem" }}
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography style={{ opacity: 0.7 }}>Suggested Vitals</Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            // style={{ marginTop: "0.5rem" }}
            spacing={1}
            alignItems="center"
          >
            {suggestedVitals
              .filter(
                (ele) =>
                  !state?.vitals?.find(
                    (res) => res.metricName === ele.metricName
                  )
              )
              .map((v) => (
                <Grid item>
                  <Chip label={v?.metricName} onClick={() => addVital(v)} />
                </Grid>
              ))}
            <Grid item xs={"auto"}>
              <IconButton
                color="primary"
                style={{
                  // backgroundColor: theme.palette.primary.main,
                  // color: "white",
                  fontSize: "30px",
                }}
                onClick={() => setOpenDialog({ dialog: "addVital" })}
              >
                <i class="fa-solid fa-circle-plus"></i>{" "}
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          {state.vitals?.map((v, i) => (
            <Grid item container xs={6} md={3} alignItems="center">
              <Grid item xs={12}>
                <Typography>{v?.metricName}</Typography>
              </Grid>
              <Grid item xs={12}>
                {!editMode ? (
                  <TextField
                    variant="outlined"
                    fullWidth
                    autoFocus={v?.focus || i === 0}
                    InputProps={{
                      endAdornment: (
                        <>
                          <Typography color="secondary">
                            {v?.metricUnit}
                          </Typography>
                          <DeleteForever
                            color="error"
                            style={{ cursor: "pointer", fontSize: "18px" }}
                            onClick={() => {
                              const temp = [...state.vitals]
                              temp.splice(i, 1)
                              setState({ ...state, vitals: [...temp] })
                            }}
                          />
                        </>
                      ),
                    }}
                    //   onKeyDown={(e) => {
                    //   if (e.key === "Enter" &&(e.target.value === "" || re.test(e.target.value)) ){

                    //   }
                    // }}
                    value={v?.["metricQuantity"]}
                    placeholder="0.0"
                    onChange={(e) => {
                      if (e.target.value === "" || re.test(e.target.value)) {
                        const temp = [...state.vitals]
                        temp[i]["metricQuantity"] = e.target.value
                        setState({ ...state, vitals: [...temp] })
                      }
                    }}
                  />
                ) : (
                  <EditTextField
                    value={v?.["metricQuantity"]}
                    placeholder={"0.0"}
                    onClick={() =>
                      setOpenDialog({
                        dialog: "editVital",
                        data: { ...v },
                        index: i,
                      })
                    }
                  />
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            flex: "flex-wrap",
            alignItems: "center",
            // marginTop: "0.5rem",
          }}
        >
          <Typography variant="body2">
            {!editMode
              ? "* In order to Edit existing vitals"
              : "* In order to stop editing vitals"}
          </Typography>
          <Button
            variant="text"
            size="small"
            color="primary"
            onClick={() => setEditMode(!editMode)}
          >
            <Typography variant="body2">Click Here</Typography>
          </Button>
        </Grid>
      </Grid>
      {openDialog?.dialog === "addVital" ? (
        <GenericDialogForm
          open={true}
          handleClose={() => setOpenDialog({})}
          dialogTitle={"Add New Vital"}
          dialogContent={dialogContent}
          handleCancel={() => setOpenDialog({})}
          handleSubmit={addVital}
          submitButtonText={"Add"}
        />
      ) : null}
      {openDialog?.dialog === "editVital" ? (
        <GenericDialogForm
          open={true}
          handleClose={() => setOpenDialog({})}
          dialogTitle={"Edit Vital"}
          dialogContent={dialogContent}
          handleCancel={removeVital}
          handleSubmit={updateVital}
          cancelButtonText={"Remove"}
          submitButtonText={"Save Changes"}
          formData={openDialog?.data}
        />
      ) : null}
    </>
  )
}

export default Vitals
