import {
  Grid,
  Typography,
  Button,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Chip,
  TextField,
} from "@material-ui/core"
import { Send } from "@material-ui/icons"
import { format } from "date-fns"
import Linkify from "react-linkify"
import { useSelector } from "react-redux"

import moment from "moment"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { toast } from "react-toastify"
import customAxios from "../../../axios"
import noRecords from "./../../../assets/img/consultation/NoMedicalHistory.png"
function AppointmentHistory({
  patientId,
  setPaymentHistoryDialogOpen,
  setInvoiceData,
  setLoading,
  setHeaderMessage,
}) {
  const history = useHistory()
  const [appointmentData, setAppointmentData] = useState([])
  const sendPrescriptionToNumber = (appointmentData) => {
    setLoading(true)
    customAxios
      .put(`consultation/send-prescription/${appointmentData?._id}`, {
        phoneNo: appointmentData?.patient?.phoneNo,
      })
      .then((res) => {
        if (res.data.success) {
          toast.success(`Prescription successfully sent.`)
        } else {
          toast.error("Prescription does not exist")
        }
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        toast.error("Some error occured")
        setLoading(false)
      })
  }
  const generatePdf = (appointmentId) => {
    customAxios
      .get(`/consultation/get-prescription-pdf/${appointmentId}`)
      .then((res) => {
        if (res?.data?.data?.url) window.open(res.data.data.url)
        else toast.error("PDF does not exist")
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const getLastPrescription = () => {
    customAxios
      .get(`/appointments/get-last-appointment/${patientId}`)
      .then((res) => {
        if (res.data.success) {
          history.push(`/admin/consultation/${res.data.data?.appointmentId}`, {
            1: 2,
          })
        }
      })
  }

  useEffect(() => {
    getAppointmentHistory()
  }, [patientId])
  const getAppointmentHistory = () => {
    setLoading(true)

    customAxios
      .get(
        `/appointments/day/all?patient_id=${patientId}&type=all&status=completed`
      )
      .then((res) => {
        const temp = res?.data?.data
        temp.reverse()
        setAppointmentData(temp)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  const isDoctor = useSelector((state) => state.auth.isDoctor)


  return (
    <Grid container style={{ padding: "1rem" }}>
      {appointmentData?.length && isDoctor? <Grid item xs={12} style={{ margin: "1rem 0", textAlign: "right" }}>
        <Button
          color="primary"
          variant="contained"
          startIcon={<i className="fa fa-plus" />}
          onClick={() => getLastPrescription()}
        >
          Create New Prescription
        </Button>
      </Grid>: null}
      {appointmentData.length ? (
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow
                  style={{
                    background: "aliceblue",
                    fontWeight: "600",
                    fontSize: "16px",
                  }}
                >
                  <TableCell>Date</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {appointmentData.map((row) => {
                  return (
                    <TableRow>
                      <TableCell style={{ maxWidth: "23%" }}>
                        {moment.utc(row?.start).format("DD MMM YYYY hh:mm A ")}
                      </TableCell>
                      <TableCell style={{ maxWidth: "23%" }}>
                        {row.treatmentId ? (
                          <Typography>
                            <i
                              class="fas fa-procedures"
                              title={"Procedure"}
                              style={{
                                fontSize: "24px",
                                color: "goldenrod",
                                marginRight: "0.3rem",
                              }}
                            ></i>
                            {row.treatment[0].name}
                          </Typography>
                        ) : row?.isOnline ? (
                          <i
                            className="fas fa-wallet"
                            title="Online Appointment"
                            style={{ fontSize: "24px", color: "green" }}
                          ></i>
                        ) : row.vaccination ? (
                          <i
                            className="fas fa-syringe"
                            title="Vaccination Appointment"
                            style={{ fontSize: "24px", color: "green" }}
                          ></i>
                        ) : (
                          <>
                            <i
                              class="fas fa-walking"
                              title="Offline Appointment"
                              style={{
                                fontSize: "24px",
                                color: "grey",
                              }}
                            ></i>{" "}
                            {row.emergency ? (
                              <Chip
                                label="Emergency Appointment"
                                style={{
                                  backgroundColor: "#FF3E43",
                                  color: "white",
                                  borderRadius: "0.25em",
                                  marginLeft: "1rem",
                                }}
                              ></Chip>
                            ) : null}
                          </>
                        )}{" "}
                      </TableCell>
                      <TableCell style={{ maxWidth: "33%" }}>
                        {" "}
                        <Grid container spacing={2}>
                          <Grid item>
                            <Button
                              variant="outlined"
                              startIcon={
                                <i class="fas fa-file-prescription"></i>
                              }
                              color="primary"
                              onClick={() => generatePdf(row?._id)}
                            >
                              View Prescription
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              startIcon={<Send />}
                              color="primary"
                              variant="outlined"
                              onClick={() => {
                                sendPrescriptionToNumber(row)
                              }}
                            >
                              Resend Prescription
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="outlined"
                              color="primary"
                              startIcon={<i class="fas fa-money-bill-wave"></i>}
                              onClick={() => {
                                if (row.invoiceData.length) {
                                  setInvoiceData(row.invoiceData)
                                  setPaymentHistoryDialogOpen(true)
                                } else {
                                  toast.error(
                                    "No payment history found for this appointment"
                                  )
                                }
                              }}
                            >
                              {" "}
                              View payments
                            </Button>
                          </Grid>
                          {row.patientNotes ? (
                            <Grid item>
                              <Button
                                variant="outlined"
                                color="primary"
                                startIcon={<i class="fas fa-notes-medical"></i>}
                                onClick={() => {
                                  setHeaderMessage(
                                    <Grid
                                      item
                                      xs={12}
                                      container
                                      style={{ width: "inherit" }}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        style={{ marginBottom: "1rem" }}
                                      >
                                        <Typography color="secondary">
                                          These notes are only visible to the
                                          doctor for their own reference
                                        </Typography>
                                      </Grid>

                                      <Typography
                                        style={{ overflowWrap: "anywhere" }}
                                      >
                                        <Linkify
                                          componentDecorator={(
                                            decoratedHref,
                                            decoratedText,
                                            key
                                          ) => (
                                            <a
                                              target="blank"
                                              href={decoratedHref}
                                              key={key}
                                            >
                                              {decoratedText}
                                            </a>
                                          )}
                                          textDecorator={(text) => (
                                            <div
                                              style={{
                                                color: "#53bdeb",
                                                display: "flex",
                                              }}
                                            >
                                              {text}
                                            </div>
                                          )}
                                        >
                                          {" "}
                                          {row.patientNotes}{" "}
                                        </Linkify>
                                      </Typography>
                                    </Grid>
                                  )
                                  setPaymentHistoryDialogOpen(true)
                                }}
                              >
                                {" "}
                                View Notes
                              </Button>
                            </Grid>
                          ) : null}
                        </Grid>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ) : null}
      {appointmentData.length === 0 ? (
        <Grid
          item
          xs={12}
          style={{
            height: "60vh",
            justifyContent: "center",
            alignItems: "center",
          }}
          container
          direction="column"
        >
          <img
            src={noRecords}
            style={{ width: "400px", objectFit: "contain" }}
            alt="empty"
          />
          <Typography variant="h6">No records found.</Typography>
        </Grid>
      ) : null}
    </Grid>
  )
}

export default AppointmentHistory
