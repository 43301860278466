import { TextField } from "@material-ui/core"
import React, { useState } from "react"
import Autosuggest from "react-autosuggest"

function CustomAutocomplete({ field, inputProps }) {
  const [suggestions, setSuggestions] = useState([])
  const getSuggestions = (value) => {
    if (value) {
      const inputValue = value.trim().toLowerCase()
      const inputLength = inputValue.length

      return inputLength === 0
        ? []
        : field?.options?.filter(
            (ele) => ele.toLowerCase().slice(0, inputLength) === inputValue
          )
    } else {
      return field.options
    }
  }

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  function renderSuggestionsContainer({ containerProps, children, query }) {
    return (
      <div
        {...containerProps}
        style={{
          borderRadius: "4px",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
          zIndex: "2",
          width: "100%",
          display: children ? "block" : "none",
          maxHeight: "280px",
          overflowX: "hidden",
          overflowY: "auto",
          backgroundColor: "#fff",
          position: "absolute",
          top: "3.8rem",
          padding: "0.5rem 0 ",
        }}
      >
        {children}
        <div></div>
      </div>
    )
  }
  const getSuggestionValue = (suggestion) =>
    field.optionLabel ? suggestion[field?.optionLabel] : suggestion

  // Use your imagination to render suggestions.
  const renderSuggestion = (suggestion, { query, isHighlighted }) => (
    <div
      style={{
        fontSize: "1rem",
        backgroundColor: isHighlighted ? "rgba(0, 0, 0, 0.04)" : "#fff",
        padding: "1rem",
        maxWidth: "inherit",
      }}
    >
      {field.optionLabel ? suggestion[field?.optionLabel] : suggestion}
    </div>
  )

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value))
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  return (
    <Autosuggest
      containerProps={{ style: { position: "relative" } }}
      suggestions={suggestions}
      inputProps={inputProps}
      // shouldRenderSuggestions={true}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      renderSuggestionsContainer={renderSuggestionsContainer}
      renderInputComponent={(params) => {
        return (
          <TextField
            {...params}
            fullWidth
            style={{ position: "relative" }}
            variant="outlined"
            // onKeyDown={(e) => {
            //   if (
            //     e.key === "Enter" &&
            //     addState?.add &&
            //     e?.target?.value
            //   )
            //     updateTableData();
            //   else {
            //     params.onKeyDown(e);
            //   }
            // }}
          ></TextField>
        )
      }}
    ></Autosuggest>
  )
}

export default CustomAutocomplete
