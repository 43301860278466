import React, { useState } from "react"
import ReactGA from "react-ga4"
import { FacebookShareButton, FacebookIcon, WhatsappIcon } from "react-share"
import AWS from "aws-sdk"
import mixpanel from "mixpanel-browser"
import { useSelector } from "react-redux"
import customAxios from "../../../axios"
import { toast } from "react-toastify"
import ConfirmationDialog from "../../ConfirmationDialog"
import newTag from "./../../../assets/animationData/newTag.json"
import {
  Grid,
  IconButton,
  makeStyles,
  Typography,
  Button,
} from "@material-ui/core"
import moment from "moment"
import GenericDialogForm from "../../CreatePrescription/GenericDialog"
import { colors } from "../../../assets/css/colors"
import CaptionGeneratorDialog from "../../CaptionGeneratorDialog"
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@material-ui/lab"
import GenericAnimation from "../../animation"

const useStyles = makeStyles((theme) => ({
  root: {
    transform: "translateZ(0px)",
    flexGrow: 1,
  },
  MuiFabPrimary: {
    backgroundColor: "white",
    color: colors.primary,
  },
  exampleWrapper: {
    position: "relative",
    marginTop: theme.spacing(3),
    height: 380,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: "absolute",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
}))
const MarketingImageCard = ({ data, onBroadcastSuccess }) => {
  const classes = useStyles()
  const [shareMenuOpen, setShareMenuOpen] = useState(false)
  const { url: img, subtitle } = data
  const [captionDialogOpen, setCaptionDialogOpen] = useState(false)

  const [isOpen, setIsOpen] = useState(false)
  const docId = useSelector((state) => state.user.user._id)
  let downloadImage = (url, type) => {
    var a = document.createElement("a")
    document.body.appendChild(a)
    a.style = "display: none"
    AWS.config.update({
      accessKeyId: "AKIAUSXZZ7HZALZ7XCS2",
      secretAccessKey: "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",
    })
    var s3 = new AWS.S3()
    let params = {
      Bucket: "paperplane-s3",
      Key:
        type === "marketing "
          ? `marketing/${data.docId}/${data._id}`
          : `pushed_posters/${data._id}`,
    }

    s3.getObject(params, (err, res) => {
      let blob = new Blob([res.Body], { type: res.ContentType })

      a.href = window.URL.createObjectURL(blob)
      a.download = "Marketing"
      a.click()
      window.URL.revokeObjectURL(url)
    })
  }
  //   getBase64FromUrl(img).then((res) => saveAs(res, "res", "image.png"));
  //   getBase64FromUrl(img).then((res) => saveAs(res, "res", "image.png"));
  return (
    <Grid item xs={12} container style={{ position: "relative" }}>
      <Grid item xs={12} style={{ position: "relative", textAlign: "center" }}>
        <img
          src={img}
          alt={"marketing poster"}
          style={{ width: "90%", borderRadius: "1rem" }}
          title={
            "Uploaded on   " + moment(data.date).format("ddd, DD MMMM YYYY")
          }
        ></img>
      </Grid>
      {/* <Grid item xs={12} style={{ margin: "0.3rem 0" }}>
        <Typography variant="body1" align="center">
          Uploaded on:{" "}
          <strong>{moment.utc(data?.date).format("DD MMM YYYY")}</strong>
        </Typography>
      </Grid>{" "} */}
      <Grid
        container
        xs={12}
        justifyContent="center"
        alignItems="center"
        spacing={2}
        style={{ marginTop: "0.5rem" }}
      >
        {/* <Grid item>
          <Typography color="secondary">
            Uploaded on {moment(data.date).format("ddd, DD MMMM YYYY")}
          </Typography>
        </Grid> */}
        <Grid item>
          <Button
            variant="outlined"
            style={{ position: "relative" }}
            startIcon={<i class="fa-solid fa-share-nodes"></i>}
            color="primary"
            onClick={() => {
              mixpanel.track("Share button clicked in Marketing", { ...data })
              setCaptionDialogOpen(true)
            }}
          >
            <GenericAnimation
              style={{
                position: "absolute",
                top: "-1.1rem",
                right: "-0.7rem",
              }}
              animationData={newTag}
              width={28}
              height={28}
            />
            Share
          </Button>
        </Grid>
        {/* <FacebookShareButton
          quote={"hellojee"}
          hashtag={"#WhatsAppClinic #Paperplane"}
          url={data.url}
          onClick={() => {
            mixpanel.track("Marketing Poster Facebook Share", {
              data: data,
            })
          }}
        >
          <Button
            color="primary"
            title="Share this poster on your facebook page "
            startIcon={<i class="fa-brands fa-facebook"></i>}
          >
            Share on Facebook
          </Button>
        </FacebookShareButton>
        <Button
          title="Share with all your patients on WhatsApp"
          style={{ color: "green" }}
          // variant="outlined"
          color="primary"
          onClick={() => setCaptionDialogOpen(true)}
          startIcon={<i class="fa-brands fa-whatsapp"></i>}
          // startIcon={<i class="fa-solid fa-bullhorn"></i>}
        >
          Whatsapp Broadcast
        </Button> */}
      </Grid>
      {captionDialogOpen ? (
        <CaptionGeneratorDialog
          imgData={data}
          onBroadcastSuccess={onBroadcastSuccess}
          open={captionDialogOpen}
          setOpen={setCaptionDialogOpen}
        />
      ) : null}
    </Grid>
  )
}
export default MarketingImageCard
