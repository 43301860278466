// import customAxios from "../../axios";
import customAxios from "../../axios/index.js"
import {
  REMOVE_REMINDER_APPOINTMENT,
  SET_ALL_CLINICS,
  SET_APPOINTMENT_STATUS,
  SET_CURRENT_DATE_TYPE,
  SET_CURRENT_DATE_TYPE_VALUE,
  SET_CURRENT_ORGANISATION,
  SET_FILTER_OPEN,
  SET_REMINDER_APPOINTMENT,
  SET_SORT_ORDER,
  SET_USER_ORGANISATIONS,
} from "../constants"
import mixpanel from "mixpanel-browser"
export const setFilterOpen = (payload) => {
  return {
    type: SET_FILTER_OPEN,
    payload: payload,
  }
}
export const setAllClinics = (payload) => {
  return {
    type: SET_ALL_CLINICS,
    payload: payload,
  }
}
export const setAppointmentStatus = (payload) => {
  return {
    type: SET_APPOINTMENT_STATUS,
    payload: payload,
  }
}
export const loadDoctor = (payload) => {
  return async (dispatch) => {
    dispatch({ type: "GET_USER_LOADING" })
    customAxios
      .get("/auth/")
      .then((res) => {
        if (res.data.success) {
          mixpanel.identify(res.data.data._id)
          mixpanel.register({
            name: res?.data?.data?.name,

            phone: res?.data?.data?.phoneNo,
            id: res?.data?.data?._id,
          })
          mixpanel.people.set({
            name: res?.data?.data?.name,

            phone: res?.data?.data?.phoneNo,
            id: res?.data?.data?._id,
          })

          dispatch({ type: "SET_USER", payload: res.data.data })
          mixpanel.track("Login")

          console.log(res.data.data, "SETUSER")
        }
      })
      .catch((err) => console.log("err", err))
  }
}

export const setCurrDateType = (payload) => {
  return { type: SET_CURRENT_DATE_TYPE, payload: payload }
}
export const setCurrDateTypeValue = (payload) => {
  return { type: SET_CURRENT_DATE_TYPE_VALUE, payload: payload }
}
export const setSortOrder = (payload) => {
  return { type: SET_SORT_ORDER, payload: payload }
}
export const loadOrganisations = (payload) => {
  console.log("payload", payload)
  return async (dispatch) => {
    customAxios
      .get(`/organisation/all`)
      .then((res) => {
        if (res.data.success) {
          if (res.data.data.length === 1)
            dispatch({
              type: SET_CURRENT_ORGANISATION,
              payload: res.data.data[0],
            })
          dispatch({ type: SET_USER_ORGANISATIONS, payload: res.data.data })
        }
      })
      .catch((err) => console.log(err))
  }
}

export const loadCurrentOrganisations = (payload) => {
  console.log("payload", payload)
  return async (dispatch) => {
    customAxios
      .get(`/organisation/all`)
      .then((res) => {})
      .catch((err) => console.log(err))
  }
}

export const setAppointmentReminder = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_REMINDER_APPOINTMENT, payload: payload })
  }
}

export const removeAppointmentReminder = () => {
  return async (dispatch) => {
    dispatch({ type: REMOVE_REMINDER_APPOINTMENT })
  }
}
