import React, { useState } from "react"
import { toast } from "react-toastify"
import ReactGA from "react-ga4"
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Modal,
  Row,
  Col,
  Button,
  Input,
} from "reactstrap"
import GenericModal from "./../../AllPatients/GenericModal"
import customAxios from "../../../axios"

import mixpanel from "mixpanel-browser"
import { Chip } from "@material-ui/core"
import "./index.css"

import moment from "moment"
import QuickEndModal from "../../Consultation(new)/QuickEndModal"
import GenericAnimation from "../../animation"
import userNudgeAnimation from "./../../../assets/animationData/pulse.json"
import GenericDialogForm from "../../CreatePrescription/GenericDialog"

const ActionableDropdown = ({
  appointment,
  disable,
  setUpdater,
  onReschedule = () => {},
}) => {
  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectTime, setSelectTime] = useState("5")

  const toggle = () => setModal(!modal)
  const toggle1 = () => setModal1(!modal1)

  const [quickEndModalOpen, setQuickEndModalOpen] = useState(false)

  const rescheduleModalHeaders = [
    {
      label: "Reschedule Date",
      key: "nextDate",
      type: "date",
      fullWidth: true,
    },
  ]

  const [modalData, setModalData] = useState({})
  const sendWhatsappMessage = () => {
    const url = `/doctor/send-late-bot/${appointment.patient._id}?time=%20${selectTime}%20minutes`
    customAxios
      .get(url)
      .then((res) => {
        if (res.data.success) {
          toast.success("Patient notified on WhatsApp")
          toggle()
        } else {
          toast.error("Some error occured.")
        }
        mixpanel.track("Late Message", { "Late By": selectTime })
        mixpanel.people.increment("Late Message")
        ReactGA.event({
          category: "Send Message",
          action: "Send Late message",
        })
      })
      .catch((err) => {
        mixpanel.track("Late Message Error", { ...err })
        toast.error("Some error occured.")
        console.log(err)
      })
  }

  const cancelAppointment = () => {
    customAxios
      .post(`/appointments/cancel?appointment_id=${appointment._id}`)
      .then((res) => {
        if (res.data.success) {
          mixpanel.track("Cancel Appointment")
          mixpanel.people.increment("Cancelled Appointment")

          toast.success("Appointment cancelled. Patient notified on WhatsApp.")
          toggle1()
          setUpdater((prev) => !prev)
        } else {
          toast.error("Some error occured.")
        }
        ReactGA.event({
          category: "Cancel Appointment",
          action: "Cancel Appointment",
        })
      })
      .catch((err) => {
        mixpanel.track("Cancel Appointment Error", { ...err })

        toast.error("Some error occured.")
        console.log(err)
      })
  }
  const quickEndAppointment = (modalData) => {
    if (!modalData?.date) {
      toast.error("Please select a date.")
    } else {
      setLoading(true)
      customAxios
        .post(`/consultation/quick-end`, {
          appointmentId: appointment._id,
          followUp: {
            expDate: moment.utc(modalData?.date).format(),
          },
        })
        .then((res) => {
          if (res.data.success) {
            setLoading(false)

            mixpanel.track("Quick End Consultation with Follow Up")
            mixpanel.people.increment("Quick End Consultation with Follow Up")

            toast.success(
              "Follow up details submitted. Patient notified on WhatsApp."
            )
            setUpdater((prev) => !prev)
          } else {
            setLoading(false)

            toast.error("Some error occured.")
          }
        })
        .catch((err) => {
          mixpanel.track("Quick End Appointment Error", { ...err })
          setLoading(false)

          toast.error("Some error occured.")
          console.log(err)
        })
    }
  }

  return (
    <div style={{ position: "relative", zIndex: "211" }}>
      {appointment.freeze ? (
        <div
          style={{
            position: "absolute",
            top: "-0.5rem",
            right: "0.1rem",
          }}
        >
          <GenericAnimation
            animationData={userNudgeAnimation}
            height={50}
            width={50}
            style={{ margin: "0rem" }}
          ></GenericAnimation>
        </div>
      ) : null}
      <UncontrolledDropdown style={{ display: "flex", zIndex: "209" }}>
        <DropdownToggle
          className="btn-icon-only text-orange  "
          href=""
          role="button"
          size="sm"
          color=""
          onClick={(e) => e.preventDefault()}
        >
          <i className="fas fa-ellipsis-v" />
        </DropdownToggle>
        {disable ? (
          <div>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem onClick={(e) => e.preventDefault()}>
                Reschedule
              </DropdownItem>
            </DropdownMenu>
          </div>
        ) : (
          <div style={{ zIndex: "211" }}>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem onClick={toggle}>Notify Patient</DropdownItem>
              <DropdownItem onClick={toggle1}>Cancel</DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  setModalData({ appointmentId: appointment._id })
                  setQuickEndModalOpen(true)
                }}
              >
                Send Follow-up Date
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  onReschedule()
                }}
              >
                Reschedule
              </DropdownItem>
            </DropdownMenu>
          </div>
        )}
      </UncontrolledDropdown>
      <Modal
        className="modal-dialog-centered modal-lg"
        isOpen={modal}
        toggle={toggle}
      >
        <div className="modal-body">
          <Row>
            <Col md="3">
              <p align="center">I will be running late by</p>
            </Col>
            <Col md="2">
              <Input
                type="number"
                value={selectTime}
                size="sm"
                onChange={(e) => {
                  e.target.value > 60
                    ? toast.error("Value cannot be greater than 60")
                    : setSelectTime(e.target.value)
                }}
              ></Input>
            </Col>
            <Col md="6">
              <p>minutes.</p>
            </Col>
            <Col md="3"></Col>
            <Col md={2}>
              <Chip
                color="primary"
                style={{
                  borderRadius: "10px",
                  marginRight: "1rem",
                  cursor: "pointer",
                }}
                onClick={() => setSelectTime(5)}
                label={"After 5 mins"}
              >
                After 5 mins
              </Chip>
            </Col>{" "}
            <Col md={2}>
              <Chip
                color="primary"
                style={{
                  borderRadius: "10px",
                  marginRight: "1rem",
                  cursor: "pointer",
                }}
                onClick={() => setSelectTime(10)}
                label={"After 10 mins"}
              >
                After 10 mins{" "}
              </Chip>
            </Col>{" "}
            <Col md={2}>
              <Chip
                color="primary"
                style={{
                  borderRadius: "10px",
                  marginRight: "1rem",
                  cursor: "pointer",
                }}
                onClick={() => setSelectTime(15)}
                label="After 15 mins"
              >
                After 15 mins
              </Chip>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={toggle}
          >
            Cancel
          </Button>
          <Button
            className="ml-auto"
            color="primary"
            type="button"
            onClick={sendWhatsappMessage}
          >
            Send Message
          </Button>
        </div>
      </Modal>
      {quickEndModalOpen ? (
        <QuickEndModal
          open={quickEndModalOpen}
          onSubmit={quickEndAppointment}
          setOpen={setQuickEndModalOpen}
          modalData={modalData}
          loading={loading}
          message={
            <div>
              Are you sure you want to mark the appointment as{" "}
              <span className="text-danger">completed</span>?
            </div>
          }
          setModalData={setModalData}
          dialogTitle="Quick End Consultation"
          submitButtonText="Send Follow-up"
        />
      ) : null}{" "}
      <GenericDialogForm
        open={modal1}
        dialogTitle={"Cancel Appointment  "}
        handleClose={toggle1}
        submitButtonText={"Yes, cancel"}
        cancelButtonText={"No"}
        message={
          <div>
            Cancel the appointment and notify {appointment.patient.name} on
            <span style={{ color: "green" }}>
              {" "}
              WhatsApp{" "}
              <i
                class="fab fa-whatsapp"
                style={{
                  marginLeft: "0.1rem",
                  marginRight: "0.3rem",
                  fontSize: "16px",
                }}
              ></i>
            </span>
            ?
          </div>
        }
        handleSubmit={cancelAppointment}
      />
    </div>
  )
}

export default ActionableDropdown
