import io from "socket.io-client"

const dashboardSocket = io("https://pplane-pcs-wsocket.paperplane.health/doctor")
// const dashboardSocket = io("https://wmapi.paperplane.health:8000/doctor")
dashboardSocket.on("connect", () => {
  console.log(dashboardSocket.id)
})
dashboardSocket.on("disconnect", () => {
  console.log(dashboardSocket.id)
})

export default dashboardSocket
