import React, { useEffect, useState } from "react"
import {
  Typography,
  Grid,
  Avatar,
  Button,
  TextField,
  Divider,
  TablePagination,
  IconButton,
  useTheme,
  CircularProgress
} from "@material-ui/core"
import profileIcon from "./../../../assets/icon.jpg"
import { colors } from "../../../assets/css/colors"
import emptySearchAnimation from "./../../../assets/animationData/emptySearch.json"
import moment from "moment"
import dashboardSocket from "../../../socket/dashboardSocket"
import AddPatientModal from "../../../views/pages/dashboards/AddPatientModal"
import { useHistory, useLocation } from "react-router"
import GenericAnimation from "../../animation"
import GenericDialogForm from "../../CreatePrescription/GenericDialog"
import prescriptionSocket from "../../../socket"
import customAxios from "../../../axios"
import { toast } from "react-toastify"
import warningAnimation from "./../../../assets/animationData/warning.json"
import mixpanel from "mixpanel-browser"
import { useSelector } from "react-redux"
import { FilterListTwoTone, FilterAlt, HelpOutline, GetApp } from "@material-ui/icons"
import FilterDialogForm from "./filter-dialog"
import LoaderFullpage from "../../Common/LoaderFullpage"
import { filter } from "lodash"

import animationData from '../../../assets/animationData/86147-whatsapp-button-call-attention-and-pause.json';

import aqarchainLogo from "../../../assets/logos/aqarchain-logo.svg"

import { csvExporter } from "../../Dashboard/Home/ExportToCsv"

function ChatSidebar({
  setPatient,
  userId,
  isDoctor,
  patient,
  isConsultation = false,
  setNewChatModalOpen,
  newChatModalOpen,
  appointmentData = null,
  orgId,
  brokerList = [],
  isAdmin,
  setMobileScreen,
  allChats,
  setAllChats,
  isMobile
}) {
  const theme = useTheme()

  const [filteredChats, setFilteredChats] = useState([])
  const [searchQuery, setSearchQuery] = useState("")

  const [openFilter, setOpenFilter] = useState(false);
  const [loading, setLoading] = useState(false);

  const [endChatLoaded, setEndChatLoaded] = useState(false);

  const defaultFilterState = {
    tags: [],
    attribueValues: [],
    assignedTo: [],
    broker: [],
    filterApplied: false,
  }

  const [tagOptions, setTagOptions] = useState([])
    useEffect(() => {
        customAxios
        .get("/contacts/all-tags")
        .then((res) => {
            if(res.data?.data)
            setTagOptions(res.data.data)
        })
        .catch((err) => {
            console.log(err)
        })
    }, [openFilter])

  const [filterState, setFilterState] = useState({...defaultFilterState});

  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [page, setPage] = useState(0)

  const allOrganisations = useSelector((state) =>
    state?.user?.organisations.map((ele) => ({
      label: ele?.locationName,
      value: ele?._id,
      ...ele,
    }))
  )

  const history = useHistory()
  const location = useLocation()
  useEffect(() => {
    if(!allChats?.length)
      setLoading(true)

    if(userId){
      dashboardSocket.emit("get_patients", {
        userId: userId,
        userType: "user",
      })

      dashboardSocket.on("update_patient", (args) => {
        dashboardSocket.emit("get_patients", {
          userId: userId,
          userType: "user",
          onlyForUpdate: true
        })
      })
    }
  }, [userId])


  dashboardSocket.once("get_patients", (args) => {
    setAllChats(args)
    setEndChatLoaded(false)
    setLoading(false)
  })

  dashboardSocket.once("get_end_patients", (args) => {
    if(args && !endChatLoaded){
      setAllChats([...allChats, ...args])
      setEndChatLoaded(true)
    }

  })
  const getChatTime = (date) => {
    const yesterday = moment.utc().subtract(1, "days")
    if (moment.utc(date).diff(yesterday, "days") === 0) return "Yesterday"
    else if (moment.utc(date).diff(yesterday, "days") < 0)
      return moment.utc(date).format("DD/MM/YYYY")
    else return moment.utc(date).format("hh:mm A")
  }
  const style = {
    name: {
      fontWeight: "500",
      fontSize: "16px",
      color: "black",
    },
    msg: {
      fontWeight: "400",
      fontSize: "14px",
    },
    time: {
      fontSize: "12px",
    },
  }
  const handleNewChat = (query, setState) => {
    prescriptionSocket.emit("search_patient", { phoneNo: query })
    prescriptionSocket.once("search_patient", (args) => {
      console.log("args", args)

      if (args.isExists) {
        setState({
          ...args?.patient,
          gender: args?.patient?.gender?.toLowerCase(),
        })
      }
    })
  }
  useEffect(() => {
    dashboardSocket.emit("search_patient")
  }, [])
  const getFilterChats = (searchQuery) => {

    let filterData = [...allChats];

    if (searchQuery) {
      filterData = allChats.filter(
        (ele) =>
          ele?.contact?.name
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          ele?.contact?.phoneNo?.toString().includes(searchQuery.toLowerCase())
      )
    } 

    if (filterState?.filterApplied) {
      filterData = filterData.filter((ele) => {
        let flag = true;
        if (filterState?.tags?.length) {
          flag = flag && filterState?.tags?.some((tag) => (ele?.tags?.includes(tag) || ele?.conversationTags?.includes(tag) || ele?.buckets?.some((bData) => bData?.tags?.includes(tag))));
        }
        if (filterState?.assignedTo?.length) {
          flag = flag && filterState?.assignedTo?.some((assignedTo) => ele?.assignedUsers?.some((aData) => aData?.name?.toLowerCase() === assignedTo.toLowerCase()));
        }

        if (filterState?.broker?.length) {
          flag = flag && filterState?.broker?.some((broker) => ele?.userIds?.some((uId) => uId === broker?._id));
        }

        if (filterState?.attributeValues?.length) {
          flag = flag && filterState?.attributeValues?.some((attributeValue) => ele?.attributes?.some((attr) => attr?.value === attributeValue));
        }

        if (filterState?.filterStartDate) {
          let filterEndDate = filterState?.filterEndDate ? filterState.filterEndDate : new Date()
          let currentDate = new Date(ele?.date)

          flag = flag && (filterState?.filterStartDate <= currentDate && filterEndDate >= currentDate)
        }
        return flag;
      })
    }

    setFilteredChats(filterData.slice());
  }

  const [paginateChat, setPaginateChat] = useState([]);

  const [userList, setUserList] = useState([]);
    useEffect(() => {
        customAxios.get("/user/list").then((res) => {
            if(res?.data?.success) {
                setUserList(res.data.data ? res.data.data : [])
            }
        })
    }, [])

  useEffect(() => {
    const tempPaginate = [...filteredChats]?.slice(Number(rowsPerPage)*Number(page), Number(rowsPerPage)*(Number(page)+1));
    setPaginateChat([...tempPaginate]);
  }, [filteredChats])

  useEffect(() => {
    getFilterChats(searchQuery)
  }, [searchQuery, allChats, filterState])

  const getLatestChatMessage = (chat) => {
    const contentType = chat?.newContact ? "newChat" : chat?.["contentType"]
    switch (contentType) {
      case "patient_interact":
        return <>Patient has interacted with your WhatsApp Clinic.</>
      case "text":
        if (typeof chat?.content === "object")
          return (
            <>
              Unsupported Document{" "}
              <i
                class="fas fa-exclamation-circle"
                style={{ color: "red", marginLeft: "0.2rem", fontSize: "16px" }}
              ></i>{" "}
            </>
          )
        return (
          <>
            {chat?.content?.toString().length > 30
              ? chat?.content.substring(0, 30) + "..."
              : chat["content"]}
          </>
        )

      case "wa_link":
        return (
          <>
            <i class="fas fa-link" style={{ marginRight: "0.4rem" }}></i>{" "}
            Booking Link Sent
          </>
        )

      default:
        const mimeType = contentType?.split("/")[0]
        switch (mimeType) {
          case "image":
            return (
              <>
                <i class="fas fa-image" style={{ marginRight: "0.4rem" }}></i>{" "}
                Photo
              </>
            )
          case "video":
            return (
              <>
                <i class="fas fa-video" style={{ marginRight: "0.4rem" }}></i>{" "}
                Video
              </>
            )
          case "invoice":
            return (
              <>
                <i
                  class={
                    chat.content.status !== "paid"
                      ? "fas fa-receipt"
                      : "fas fa-check-circle"
                  }
                  style={{ marginRight: "0.4rem" }}
                ></i>{" "}
                Payment{" "}
                {chat.content.status !== "paid" ? "Requested" : "Received"}
              </>
            )

          case "audio":
            return (
              <>
                <i
                  class="fas fa-microphone"
                  style={{ marginRight: "0.4rem" }}
                ></i>{" "}
                Audio
              </>
            )
          
          case "newChat":
            return (
              <>
                {/* <i
                  class="fas fa-user-plus"
                  style={{ marginRight: "0.4rem" }}
                ></i>{" "} */}
                Start a Chat
              </>
            )
          default:
            return (
              <>
                <i
                  class="fas fa-file-alt"
                  style={{ marginRight: "0.4rem" }}
                ></i>{" "}
                Document
              </>
            )
        }
    }
  }

  return (
    <Grid container item xs={12} style={{ position: "relative" }}>
      <LoaderFullpage loading={loading} animationData={animationData} /> 
      <Grid
        item
        container
        xs={12}
        spacing={2}
        style={{
          position: "fixed",
          top: "0",
          width: isMobile ? "100%" : "25.5%",
          //   boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
          background: "#fff",
          padding: "1rem",
          height: "18%",
          zIndex: "99",
        }}
        alignItems="center"
      >
        <Grid
          container
          item
          alignItems="center"
          diretion="row"
          spacing={1}
          xs={12}
          style={
            {
              // flexWrap: "nowrap",
              // position: "static",
            }
          }
          justifyContent="flex-start"
        >
          <Grid
            container
            item
            style={{ marginBottom: "0.5rem", position: "relative", height: "60px" }}
            xs={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item container xs={6} alignItems="center" style={{display: "flex"}} >
              {" "}
              <div style={{
                position: (orgId === "650d3d526001fd08680985a6" || orgId === "66047e6fe9d3f0c47f5c5fee") ? "absolute" : null, top: -20
              }}>
                <div style={{display: "flex"}}>
                  <img
                    alt="profile"
                    src={(orgId === "650d3d526001fd08680985a6" || orgId === "66047e6fe9d3f0c47f5c5fee") ? aqarchainLogo : profileIcon}
                    style={{
                      width: (orgId === "650d3d526001fd08680985a6" || orgId === "66047e6fe9d3f0c47f5c5fee") ? "80px" : "40px",
                      height: (orgId === "650d3d526001fd08680985a6" || orgId === "66047e6fe9d3f0c47f5c5fee") ? "80px" : "40px",
                      borderRadius: (orgId === "650d3d526001fd08680985a6" || orgId === "66047e6fe9d3f0c47f5c5fee") ? null: "50%",
                      border: (orgId === "650d3d526001fd08680985a6" || orgId === "66047e6fe9d3f0c47f5c5fee") ? null : "1px solid blue",
                      padding: "0.1rem",
                      marginRight: "0.5rem",
                    }}
                  />

                  {(orgId === "650d3d526001fd08680985a6" || orgId === "66047e6fe9d3f0c47f5c5fee") ? null : <Typography variant="h5">
                    <span style={{ color: colors.primary, fontWeight: "600" }}>
                      Chats
                    </span>
                  </Typography>}
                </div>
                {(orgId === "650d3d526001fd08680985a6" || orgId === "66047e6fe9d3f0c47f5c5fee") ? <Typography style={{fontSize: "9px", marginTop: "-10px"}}>
                  Powered By 
                  <span style={{
                    // color: "#4D63DD",
                    background: "linear-gradient(128.8deg, #4D63DD 28.61%, #22A1F5 99.07%)",
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent', 
                    fontWeight: "bold",
                    marginLeft: 3,
                    fontSize: "10px"
                    }}>
                    <i>Paperplane</i>
                  </span>
                </Typography> : null}
              </div>
                
            </Grid>
            
            {/* <Grid item>
            </Grid> */}
            
            <Grid item xs={5}> 
              <Button
                startIcon={<i class="fas fa-user-plus"></i>}
                color="primary"
                size="small"
                style={{ float: "right" }}
                // variant="outlined"
                onClick={() => {
                  setNewChatModalOpen(true)
                  // history.push("/admin/allPatients");
                }}
              >
                {" "}
                New Chat
              </Button>
            </Grid>
            {isDoctor ? <Grid item xs={1}>
              <IconButton
                  color="primary"
                  fullWidth
                  startIcon={<i class="fas fa-file-export"></i>}
                  title="Export the current chat rows in a .csv format"
                  block
                  onClick={() => {
                    if (paginateChat?.length) csvExporter(paginateChat)
                  }}
                >
                  <GetApp />
                </IconButton>
            </Grid> : null}
          </Grid>
          <Grid item xs={10}>
            <TextField
              variant="outlined"
              fullWidth
              value={searchQuery}
              onChange={(e) => {
                mixpanel.track("Searched for contact in Chats")
                setSearchQuery(e.target.value)
              }}
              InputProps={{
                startAdornment: (
                  <i
                    className="fas fa-search"
                    style={{ color: "grey", marginRight: "0.4rem" }}
                  ></i>
                ),
              }}
              style={{ backgroundColor: "#fff" }}
              color="primary"
              placeholder="Search by name or Phone Number"
            ></TextField>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={() => setOpenFilter(true)} style={{backgroundColor: filterState?.filterApplied ? theme.palette.primary.main : null}}>
              <FilterListTwoTone style={{color: filterState?.filterApplied ? "white" : "black"}}/>
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        style={{ margin: "5px 0", top: "130px", position: "absolute" }}
      >
        {paginateChat?.length ? (
          paginateChat?.map((ele, idx) => {
            return (
              <>
              <Grid
                item
                key={idx}
                xs={12}
                container
                direction="row"
                alignItems="center"
                spacing={2}
                style={{
                  position: "relative",
                  background:
                    ele?.contact?._id === patient?._id
                      ? "#f3f3f3"
                      : ( (ele?.status === "delivered" && ele?.userType === "patient") ? "bisque" : "#fff"),
                  margin: "0",
                  marginTop: idx === 0 ? "1rem" : "0.3rem",
                  padding: "0.5rem",
                  // borderRadius: "18px",
                }}
              >
                <Grid item xs={2}>
                  <Avatar
                    style={{
                      backgroundColor: ele?.contact?.waChatColor
                        ? ele?.contact?.waChatColor
                        : colors.primary,
                      width: "2.5rem",
                      height: "2.5rem",
                      fontSize: "20px",
                    }}
                    sizes="small"
                  >
                    {ele?.contact?.name && !ele?.contact?.name?.includes("+91") ? ele?.contact?.name?.split("")[0] : null}
                  </Avatar>
                </Grid>
                <Grid
                  container
                  item
                  direction="column"
                  xs={10}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    console.log("from here1")
                    mixpanel.track("Clicked on patient in chat")
                    setPatient({ ...ele?.contact, totalMessages: ele?.totalMessages })
                    if(ele?.status === "delivered" && ele?.userType === "patient"){
                      const tempPaginateChat = [...paginateChat]
                      tempPaginateChat.splice(idx, 1, {...ele, status: "read"})
                      setPaginateChat([...tempPaginateChat])
                    }
                    setMobileScreen("window")
                  }}
                >
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography variant="body2" style={style.name}>
                    {ele?.contact?.name && !ele?.contact?.name?.includes("+91") ? ele?.contact?.name : "No name"}
                      {/* {ele?.contact?.name ? ele?.contact?.name : `+91${ele?.contact?.phoneNo}`} */}
                      {/* {location?.state?.appointmentId &&
                      appointmentData?.patient?._id === ele?.contact._id ? (
                        <Typography
                          style={{
                            fontWeight: "700",
                            color: "gray",
                            display: "flex",
                            columnGap: "10px",
                            alignItems: "center",
                          }}
                        >
                          Ongoing Consultation
                          <GenericAnimation
                            animationData={warningAnimation}
                            height={30}
                            width={30}
                          ></GenericAnimation>
                        </Typography>
                      ) : null} */}
                    </Typography>
                    <Typography
                      style={style.time}
                      color={ele?.unseenCount ? "primary" : "textSecondary"}
                    >
                      {getChatTime(ele?.date)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    direction="row"
                    justifyContent="space-between"
                    container
                  >
                    <Typography
                      variant="body1"
                      style={style.msg}
                      color={ele?.unseenCount ? "textPrimary" : "textSecondary"}
                    >
                      <span>
                        {ele?.userType !== "patient" &&
                        ele?.contentType === "text" ? (
                          <i
                            class="fa-solid fa-check-double"
                            style={{
                              fontSize: "12px",
                              color: ele?.templateStatus
                                ? ele?.templateStatus === "read"
                                  ? "#53BDEB"
                                  : "#f3f3f3"
                                : ele.status === "read"
                                ? "#53BDEB"
                                : "grey",
                            }}
                          ></i>
                        ) : null}{" "}
                      </span>

                      {getLatestChatMessage(ele)}
                    </Typography>
                    {(ele?.status === "delivered" && ele?.userType === "patient") ? (
                      <Avatar
                        style={{
                          width: "25px",
                          height: "25px",
                          backgroundColor: "#463ddd",
                          verticalAlign: "center",
                          padding: "0.2rem",
                          fontSize: "14px",
                        }}
                      >
                        {ele?.unseenCount ? ele?.unseenCount : "!"}
                      </Avatar>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
              </>
            )
          })
        ) : (
          <Grid
            container
            xs={12}
            item
            justifyContent="center"
            style={{ padding: "1rem" }}
          >
            <Grid item xs={12}>
              <GenericAnimation
                animationData={emptySearchAnimation}
                height={300}
                width={300}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" align="center">
                Didn't find what you're looking for!
                <br /> Start a{" "}
                <span
                  style={{
                    color: colors.primary,
                    fontWeight: 600,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setNewChatModalOpen(true)
                    // history.push("/admin/allPatients")
                  }}
                >
                  New Chat
                </span>
              </Typography>
            </Grid>
          </Grid>
        )}
        {filteredChats?.length ? <>
          <Grid item xs={12} style={{backgroundColor: "#D7DBDD", justifyContent: "center"}}>
            <TablePagination
              align="center"
              style={{backgroundColor: "white", maxWidth: "100%"}}
              rowsPerPageOptions={[
                5,
                10,
                20,
                50,
                100,
                { label: "All", value: filteredChats?.length },
              ]}
              component="div"
              count={filteredChats?.length}
              
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(e, page) => {
                setPage(page)
                const tempPaginate = [...filteredChats]?.slice(Number(rowsPerPage)*Number(page), Number(rowsPerPage)*(Number(page)+1));
                setPaginateChat([...tempPaginate]);
              }}
              onRowsPerPageChange={(e) => {
                setRowsPerPage(e.target.value)
                setPage(0);
                const tempPaginate = [...filteredChats]?.slice(0, Number(e.target.value));
                setPaginateChat([...tempPaginate]);
              }}
            />
          </Grid>
        </> : null}
        {newChatModalOpen ? (
          <GenericDialogForm
            dialogTitle={"Add new chat"}
            dialogContent={[
              {
                key: "phoneNo",
                required: true,
                maxLength: "10",
                label: "Phone number",
                onChange: (value, state, setState) => {
                  setState({ ...state, phoneNo: value })
                  handleNewChat(value, setState)
                  console.log("value", value)
                },
              },
              {
                key: "name",
                label: "Name",
                required: true,
              },
              {
                key: "age",
                label: "Age",
              },
              {
                label: "Gender",
                key: "gender",
                type: "select",
                options: [
                  { label: "Male", value: "male" },
                  { label: "Female", value: "female" },
                  { label: "Other", value: "other" },
                ],
              }
            ]}
            showRequired={true}
            open={newChatModalOpen}
            handleClose={() => setNewChatModalOpen(false)}
            handleSubmit={(data) => {
              
                customAxios
                  .post("/contacts/create", { ...data })
                  .then((res) => {
                    if (res.data.success) {
                      // console.log("from here3")
                      setMobileScreen("window")
                      setPatient({ ...res.data.data })
                    } else {
                      toast.error(res?.data?.message ?? "Some error occured")
                    }
                  })
                  .catch((err) => {
                    toast.error("Some error occured")
                  })
              
            }}
          />
        ) : null}
        {openFilter ? (
          <FilterDialogForm
            dialogTitle={(<><FilterListTwoTone /> Filter Options</>)}
            formData={filterState}
            dialogContent={
              (orgId === "650d3d526001fd08680985a6" || orgId === "66047e6fe9d3f0c47f5c5fee") && isAdmin ? [
                {
                  key: "broker",
                  label: "Filter By Broker",
                  type: "multi-autocomplete",
                  options: [...brokerList].map(v => ({...v, title: v?.name})),
                  md: 12,
                  variant: "standard",
                  fieldKey: "title"
  
                },
                {
                  key: "filterStartDate",
                  label: "Filter By Date",
                  type: "date",
                  // options: [...userList].map(v => v?.name),
                  md: 6,
                  variant: "standard",
                  otherProps: {
                    label: "Start Date",
                    size: "small"
                  }
  
                },
                {
                  key: "filterEndDate",
                  // label: "Filter By Date",
                  type: "date",
                  // options: [...userList].map(v => v?.name),
                  md: 6,
                  variant: "standard",
                  otherProps: {
                    label: "End Date",
                    size: "small",
                    style: {"marginTop": "8%"}
                  }
  
                },
              ] : [
              {
                key: "tags",
                label: "Tags",
                type: "multi-autocomplete",
                options: tagOptions,
                md: 12,
                variant: "standard",
                addOption: true
              },
              {
                key: "attributeValues",
                label: "Attribute Values",
                type: "multi-autocomplete",
                options: [],
                addOption: true,
                md: 12,
                variant: "standard",
                helperText: "Type and add new attribute values"
              },
              {
                key: "assignedTo",
                label: "Assigned To",
                type: "multi-autocomplete",
                options: [...userList].map(v => v?.name),
                md: 12,
                variant: "standard"

              },
              {
                key: "filterStartDate",
                label: "Filter By Date",
                type: "date",
                // options: [...userList].map(v => v?.name),
                md: 6,
                variant: "standard",
                otherProps: {
                  label: "Start Date",
                  size: "small"
                }

              },
              {
                key: "filterEndDate",
                // label: "Filter By Date",
                type: "date",
                // options: [...userList].map(v => v?.name),
                md: 6,
                variant: "standard",
                otherProps: {
                  label: "End Date",
                  size: "small",
                  style: {"marginTop": "8%"}
                }

              },
            ]}
            showRequired={true}
            open={openFilter}
            handleClose={(fromButton=false) => {
              setOpenFilter(false)
              if(filterState?.filterApplied && fromButton) {
                setFilterState({...defaultFilterState, filterApplied: false})
              }
            }}
            handleSubmit={(data) => {
              setFilterState({...defaultFilterState, ...data, filterApplied: true})
            }}
            submitButtonText={"Apply"}
            cancelButtonText={filterState?.filterApplied ? "Remove" : "Cancel"}
          />
        ) : null}
      </Grid>
    </Grid>
  )
}

export default ChatSidebar
