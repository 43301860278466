import React, { useEffect, useState } from "react";
import { Button } from "reactstrap/lib";
import Input from "reactstrap/lib/Input";
import InputGroup from "reactstrap/lib/InputGroup";
import InputGroupAddon from "reactstrap/lib/InputGroupAddon";
import InputGroupText from "reactstrap/lib/InputGroupText";
import Label from "reactstrap/lib/Label";
import Row from "reactstrap/lib/Row";

import customAxios from "../../axios";
import moment from "moment";
import GenericModal from "./GenericModal";
import PreviousVitals from "./PreviousVitals";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

function AddVitals({ id }) {
  const [dummyCounter, setDummyCounter] = useState(0);
  const handleSubmit = () => {
    const temp = [...state];
    temp.push(modalData);
    setState([...temp]);
    setModalData({});
    setIsOpen(false);
  };
  const doctorId = useSelector((state) => state?.user?.user?._id);

  useEffect(() => {
    customAxios.get(`/doctor/get-custom-vitals/${doctorId}`).then((res) => {
      if (res.data.success) setState([...res.data.data]);
    });
    //eslint-disable-next-line
  }, []);
  const [state, setState] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const modalHeaders = [
    {
      label: "Label",
      key: "metricName",
      placeholder: "eg. Pulse Rate",
      limit: 30,
    },
    { label: "Value", key: "metricQuantity", placeholder: "eg. 72" },
    { label: "Unit", key: "metricUnit", placeholder: "eg. /min", limit: 10 },
  ];

  const saveVitals = (state) => {
    const temp = [...state];
    const finalArr = temp.filter((ele) => ele?.metricQuantity);

    if (finalArr.length) {
      const temp = [...state];
      const finalArr = temp.filter((ele) => ele?.metricQuantity);
      const objectToSend = {};
      const objInViatls = {
        date: moment.now(),
        data: [...finalArr],
      };
      objectToSend.vitals = [];
      objectToSend.vitals.push(objInViatls);
      customAxios
        .put(`/vital/${id}`, objectToSend)
        .then((res) => {
          if (res.data.success) {
            toast.success("Vitals added successfully");
            setDummyCounter(Math.random());
          }
        })
        .catch((err) => console.log(err));
    } else {
      toast.error("Please add vitals before saving");
    }
  };
  return (
    <Row className="p-3 " style={{ marginRight: "0px" }}>
      <div className="row col-md-12 m-1">
        <h2 className="p-2">Add Vitals</h2>
      </div>
      <div className="row col-md-12 m-1">
        {state?.map((ele, index) => {
          return (
            <div
              className=" row col-md-6 p-1"
              style={{ alignItems: "center" }}
              key={index}
            >
              <Label className="col-md-3">{ele.metricName}</Label>

              <InputGroup className="mb-4 col-md-6">
                <Input
                  type="number"
                  value={ele.metricQuantity}
                  onChange={(e) => {
                    const newState = [...state];
                    newState[index]["metricQuantity"] = e.target.value;
                    setState([...newState]);
                  }}
                ></Input>
                {
                  <InputGroupAddon addonType="append">
                    {ele.metricUnit && (
                      <InputGroupText>{ele?.metricUnit}</InputGroupText>
                    )}

                    {!ele.constant && (
                      <InputGroupText
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          const temp = [...state];
                          temp.splice(index, 1);
                          setState([...temp]);
                        }}
                      >
                        <i className="fas fa-trash"></i>
                      </InputGroupText>
                    )}
                  </InputGroupAddon>
                }
              </InputGroup>
            </div>
          );
        })}
        <div className="row col-md-12 mt-4">
          <button
            class="btn btn-icon btn-primary  "
            type="button"
            onClick={() => setIsOpen(true)}
          >
            <span class="btn-inner--icon">
              <i class="fa fa-plus"></i>
            </span>
            <span class="btn-inner--text">Add new</span>
          </button>
        </div>
        <hr style={{ borderTop: "2px solid black" }}></hr>
        <div
          style={{
            justifyContent: "flex-end",
            alignItems: "center",
          }}
          className="row col-md-12 m-1"
        >
          <Button color="primary" onClick={() => saveVitals(state)}>
            Save Vital
          </Button>
        </div>
      </div>
      <PreviousVitals id={id} dummyCounter={dummyCounter} />
      <GenericModal
        modalData={modalData}
        setModalData={setModalData}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        headers={modalHeaders}
        title="Add New Vital"
        handleSubmit={handleSubmit}
      ></GenericModal>
    </Row>
  );
}
export default AddVitals;
