import {
  Grid,
  MenuItem,
  TextField,
  Button,
  Chip,
  Typography,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  IconButton,
  Checkbox,
  Switch,
  FormControlLabel,
} from "@material-ui/core"
import { Close, DeleteForeverOutlined } from "@material-ui/icons"
import { uniqueId } from "lodash"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Table } from "reactstrap"
import customAxios from "../../../axios"
import GenericAnimation from "../../animation"
import CustomAutocomplete from "../../Common/AutoComplete"
import GenericDialogForm from "../../CreatePrescription/GenericDialog"
import treatmentAnimation from "./../../../assets/animationData/treatment.json"
const paymentOptions = ["Cash", "UPI", "Card", "Online(Paperplane)"]

const defaultPaymentData = {
  invoiceFor: "Consultation",
  amount: 0,
  mode: "Cash",
}

function ManagePaymentDialog({
  initialTreatment = null,
  linkTreatmentProp = false,
  managePaymentDialogOpen,
  setManagePaymentDialogOpen,
  modalData,
  setUpdater,
  setModalData,
}) {
  const { invoiceData } = modalData
  const [previousPaymentsOpen, setPreviousPaymentsOpen] = useState(false)
  const [treatmentList, setTreatmentList] = useState(false)
  const [paymentsData, setPaymentsData] = useState(() => [
    {
      ...defaultPaymentData,
      key: uniqueId(),
    },
  ])
  const [treatmentDialogOpen, setTreatmentDialogOpen] = useState(false)
  const [linkTreatment, setLinkTreatment] = useState(false)
  const [selectedTreatment, setSelectedTreatment] = useState(null)
  const managePayments = (data) => {
    let error = 1
    if (selectedTreatment) {
      customAxios
        .put(
          `/treatment/link/${selectedTreatment}?appointment_id=${data.appointmentId}`
        )
        .then((res) => {
          if (res.data.success) {
            if (paymentsData.length === 0) {
              setManagePaymentDialogOpen(false)
              setUpdater((prev) => !prev)

              toast.success("Procedure added successfully")
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
    if (!selectedTreatment && data.paymentsData.length === 0) {
      toast.error("Please add at least one invoice item")
      error = 0
    }
    if (paymentsData.length) {
      for (const i of data.paymentsData) {
        if (!i.mode || !i.invoiceFor) {
          error = 0
          toast.error("Please fill all details properly")
          break
        } else if (!i.amount) {
          error = 0
          toast.error("Amount for all invoice items must be greater than 0")
          break
        }
      }
    }
    if (error && paymentsData.length) {
      customAxios
        .put(
          `/consultation/new-generate-invoice/${data.appointmentId}`,
          data.paymentsData
        )
        .then((res) => {
          if (res.data.success) {
            toast.success("Invoice sent to patient on WhatsApp")
            setManagePaymentDialogOpen(false)
            setUpdater((prev) => !prev)
          } else {
            toast.error("Some error occured")
          }
        })
        .catch((err) => toast.error("Some error occured"))
    }
  }
  useEffect(() => {
    if (modalData.treatmentId) {
      setLinkTreatment(true)
      setSelectedTreatment(modalData.treatmentId)
      const curr = paymentsData.slice()
      curr.push({
        ...defaultPaymentData,
        treatmentId: modalData.treatmentId,
        invoiceFor: "Service",
      })
      setPaymentsData(curr.slice())
    }
  }, [modalData])
  const getOngoingTreatments = (patientId) => {
    customAxios
      .get(`/patients/treatments/${patientId}`)
      .then((res) => {
        if (res.data.success) {
          setTreatmentList((prev) => [
            ...res.data.data.map((ele) => ({
              label: ele.treatment.name,
              value: ele._id,
              ...ele,
            })),
          ])
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  useEffect(() => {
    getOngoingTreatments(modalData.patientId)
  }, [modalData.patientId])
  console.log("891", treatmentList)
  return (
    <div>
      {managePaymentDialogOpen ? (
        <GenericDialogForm
          setIsOpen={setManagePaymentDialogOpen}
          fullWidth
          maxWidth="sm"
          style={{ overflowY: "visible" }}
          open={managePaymentDialogOpen}
          handleClose={() => {
            setManagePaymentDialogOpen(false)
            setModalData(null)
          }}
          dialogTitle="Manage Payments"
          footerMessage={() => {
            return (
              <Grid container item xs={12}>
                {invoiceData.length ? (
                  <Grid item xs={12} style={{ margin: "0.5rem 0" }}>
                    <Button
                      color="primary"
                      variant="outlined"
                      fullWidth
                      onClick={() => {
                        setPreviousPaymentsOpen(true)
                      }}
                    >
                      View payment history for this appointment
                    </Button>
                  </Grid>
                ) : null}
                <Grid
                  container
                  item
                  xs={12}
                  spacing={2}
                  style={{ margin: "0 0" }}
                >
                  <Grid item container xs={12} style={{ marginTop: "0.4rem" }}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={linkTreatment}
                            onChange={(e) => {
                              setLinkTreatment(e.target.checked)
                              if (!e.target.checked) {
                                const curr = paymentsData.slice()
                                const newData = curr.filter(
                                  (ele) => !ele.treatmentId
                                )
                                setPaymentsData(newData.slice())
                                setSelectedTreatment(null)
                              }
                            }}
                          />
                        }
                        label="Add a procedure to this appointment"
                      ></FormControlLabel>
                    </Grid>

                    {linkTreatment && treatmentList.length ? (
                      <Grid item xs={12}>
                        <TextField
                          select
                          // helperText={selectedTreatment?""}
                          variant="outlined"
                          fullWidth
                          options={treatmentList}
                          value={selectedTreatment}
                          InputLabelProps={{ shrink: true }}
                          disabled={!treatmentList.length}
                          label="Ongoing Procedures"
                          helperText={
                            selectedTreatment &&
                            treatmentList.find(
                              (ele) => ele._id === selectedTreatment
                            )?.treatment?.currentPaid ? (
                              <Typography variant="subtitle1">
                                Remaining Amt.{" "}
                                <span
                                  style={{ color: "red", fontWeight: "700" }}
                                >
                                  {" "}
                                  {treatmentList.find(
                                    (ele) => ele._id === selectedTreatment
                                  )?.treatment?.totalAmount -
                                    treatmentList
                                      .find(
                                        (ele) => ele._id === selectedTreatment
                                      )
                                      ?.treatment?.currentPaid?.reduce(
                                        (initial, ele) => Number(ele) + initial,
                                        0
                                      )}
                                </span>
                              </Typography>
                            ) : null
                          }
                          onChange={(e) => {
                            setSelectedTreatment(e.target.value)
                          }}
                        >
                          {treatmentList.map((ele) => {
                            return (
                              <MenuItem value={ele.value}>{ele.label}</MenuItem>
                            )
                          })}
                        </TextField>
                      </Grid>
                    ) : null}
                    {linkTreatment ? (
                      <div>
                        {treatmentList.length === 0 ? (
                          <Typography
                            color="secondary"
                            style={{ marginBottom: "0.5rem" }}
                          >
                            No ongoing procedures found. <br />
                            Please add new with the button below👇
                          </Typography>
                        ) : null}
                        <Button
                          color="primary"
                          size="small"
                          onClick={() => {
                            setTreatmentDialogOpen(true)
                          }}
                          startIcon={<i className="fa fa-plus"></i>}
                        >
                          Add procedure
                        </Button>
                      </div>
                    ) : null}
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ marginTop: "0.6rem" }}
                ></Grid>

                {selectedTreatment ? (
                  <Grid item xs={12} container>
                    <Grid
                      item
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="baseline"
                    >
                      <Grid item xs={8}>
                        <Typography variant="h6"> Procedure:</Typography>
                        <Typography variant="subtitle1" color="secondary">
                          The amount of the following invoice items will be
                          reduced from the total amt. of selected procedure
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={() => {
                            const curr = paymentsData.slice()
                            curr.splice(0, 0, {
                              treatmentId: selectedTreatment,
                              amount: 0,
                              key: uniqueId(),
                              mode: "Cash",
                              invoiceFor: "Service",
                            })
                            setPaymentsData(curr.slice())
                          }}
                          startIcon={<i className="fa fa-plus" />}
                          color="primary"
                        >
                          Add item
                        </Button>
                      </Grid>
                    </Grid>
                    {paymentsData
                      .filter((ele) => ele.treatmentId)
                      .map((ele, idx) => (
                        <Grid
                          container
                          item
                          key={idx}
                          xs={12}
                          spacing={2}
                          style={{
                            position: "relative",
                            marginBottom: "0.4rem",
                            marginTop: idx === 0 ? "1rem" : 0,
                          }}
                        >
                          <IconButton
                            color="primary"
                            onClick={() => {
                              const curr = paymentsData.slice()
                              const final = curr.filter(
                                (row) => row.key !== ele.key
                              )
                              setPaymentsData(final.slice())
                            }}
                            style={{
                              position: "absolute",
                              right: "-2rem",
                              top: "0.5rem",
                            }}
                            title="Delete invoice item"
                          >
                            <Close />
                          </IconButton>
                          {/* <Grid item xs={1}>
                      <Checkbox
                        color="primary"
                        checked={paymentsData.find(row=>row.key===ele.key).treatmentId}
                        onChange={(e) => {
                          const curr = paymentsData.slice()
                          curr[idx].treatmentId = e.target.checked
                            ? selectedTreatment
                            : null

                          setPaymentsData(curr.slice())
                        }}
                      />
                    </Grid> */}
                          <Grid item xs={4}>
                            <CustomAutocomplete
                              field={{
                                key: "invoiceFor",
                                label: "Bill For2",
                                options: ["Consultation", "Service", "X-Ray"],
                              }}
                              inputProps={{
                                label: "Bill For",
                                value: paymentsData.find(
                                  (row) => row.key === ele.key
                                ).invoiceFor
                                  ? paymentsData.find(
                                      (row) => row.key === ele.key
                                    ).invoiceFor
                                  : " ",
                                variant: "outlined",
                                fullWidth: true,

                                onChange: (e, { newValue: value }) => {
                                  const currData = paymentsData.slice()
                                  currData.find(
                                    (row) => row.key === ele.key
                                  ).invoiceFor = value
                                  setPaymentsData(currData.slice())
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              select
                              label="Mode of Payment"
                              onChange={(e) => {
                                const currData = paymentsData.slice()
                                currData.find(
                                  (row) => row.key === ele.key
                                ).mode = e.target.value
                                setPaymentsData(currData.slice())
                              }}
                              value={
                                paymentsData.find((row) => row.key === ele.key)
                                  .mode
                              }
                              variant="outlined"
                              fullWidth
                            >
                              {paymentOptions.map((ele) => (
                                <MenuItem value={ele}>{ele}</MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={3}>
                            <TextField
                              onChange={(e) => {
                                const currData = paymentsData.slice()
                                currData.find(
                                  (row) => row.key === ele.key
                                ).amount = e.target.value
                                setPaymentsData(currData.slice())
                              }}
                              label="Amount"
                              value={
                                paymentsData.find((row) => row.key === ele.key)
                                  .amount
                              }
                              variant="outlined"
                              fullWidth
                            ></TextField>
                          </Grid>
                        </Grid>
                      ))}
                  </Grid>
                ) : null}
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="space-between"
                  alignItems="baseline"
                  style={{ marginTop: "1rem" }}
                >
                  <Grid item xs={8}>
                    {selectedTreatment ? (
                      <Typography variant="h6"> Other:</Typography>
                    ) : null}
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => {
                        const curr = paymentsData.slice()
                        curr.splice(0, 0, {
                          amount: 0,
                          mode: "Cash",
                          key: uniqueId(),
                          invoiceFor: "Service",
                        })
                        setPaymentsData(curr.slice())
                      }}
                      startIcon={<i className="fa fa-plus" />}
                      color="primary"
                    >
                      Add item
                    </Button>
                  </Grid>
                </Grid>
                {paymentsData
                  .filter((ele) => !ele.treatmentId)
                  .map((ele, idx) => (
                    <Grid
                      container
                      item
                      key={idx}
                      xs={12}
                      spacing={2}
                      style={{
                        position: "relative",
                        marginBottom: "0.4rem",
                        marginTop: idx === 0 ? "1rem" : 0,
                      }}
                    >
                      {/* <Grid item xs={1}>
                      <Checkbox
                        color="primary"
                        checked={paymentsData.find(row=>row.key===ele.key).treatmentId}
                        onChange={(e) => {
                          const curr = paymentsData.slice()
                          curr[idx].treatmentId = e.target.checked
                            ? selectedTreatment
                            : null

                          setPaymentsData(curr.slice())
                        }}
                      />
                    </Grid> */}
                      <Grid item xs={4}>
                        <CustomAutocomplete
                          field={{
                            key: "invoiceFor",
                            label: "Bill For2",
                            options: ["Consultation", "Service", "X-Ray"],
                          }}
                          inputProps={{
                            label: "Bill For",
                            value: paymentsData.find(
                              (row) => row.key === ele.key
                            ).invoiceFor
                              ? paymentsData.find((row) => row.key === ele.key)
                                  .invoiceFor
                              : " ",
                            variant: "outlined",
                            fullWidth: true,

                            onChange: (e, { newValue: value }) => {
                              const currData = paymentsData.slice()
                              currData.find(
                                (row) => row.key === ele.key
                              ).invoiceFor = value
                              setPaymentsData(currData.slice())
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          select
                          label="Mode of Payment"
                          onChange={(e) => {
                            const currData = paymentsData.slice()
                            currData.find((row) => row.key === ele.key).mode =
                              e.target.value
                            setPaymentsData(currData.slice())
                          }}
                          value={
                            paymentsData.find((row) => row.key === ele.key).mode
                          }
                          variant="outlined"
                          fullWidth
                        >
                          {paymentOptions.map((ele) => (
                            <MenuItem value={ele}>{ele}</MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          onChange={(e) => {
                            const currData = paymentsData.slice()
                            currData.find((row) => row.key === ele.key).amount =
                              e.target.value
                            setPaymentsData(currData.slice())
                          }}
                          label="Amount"
                          value={
                            paymentsData.find((row) => row.key === ele.key)
                              .amount
                          }
                          variant="outlined"
                          fullWidth
                        ></TextField>
                      </Grid>
                      <IconButton
                        color="primary"
                        onClick={() => {
                          const curr = paymentsData.slice()
                          const final = curr.filter(
                            (row) => row.key !== ele.key
                          )
                          setPaymentsData(final.slice())
                        }}
                        style={{
                          position: "absolute",
                          right: "-2rem",
                          top: "0.5rem",
                        }}
                        title="Delete invoice item"
                      >
                        <Close />
                      </IconButton>
                    </Grid>
                  ))}

                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    align="right"
                    style={{ marginRight: "1rem" }}
                  >
                    Total Amt.
                    <strong>
                      {" "}
                      ₹
                      {paymentsData.reduce(
                        (initial, ele) => initial + Number(ele.amount),
                        0
                      )}
                    </strong>
                  </Typography>
                </Grid>
              </Grid>
            )
          }}
          handleCancel={() => {
            setManagePaymentDialogOpen(false)
          }}
          autoClose={false}
          formData={{ invoiceFor: "Consultation", modeOfPayment: "Cash" }}
          handleSubmit={(formData) =>
            managePayments({ ...modalData, paymentsData: paymentsData })
          }
        />
      ) : null}
      <GenericDialogForm
        style={{ overflowY: "auto" }}
        footerMessage={() => {
          return (
            <Grid container item xs={12} style={{ marginTop: "1rem" }}>
              {invoiceData.length ? (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Bill For</TableCell>
                        <TableCell>Mode</TableCell>
                        <TableCell>Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invoiceData.map((ele) => (
                        <TableRow>
                          <TableCell>
                            {moment
                              .utc(ele.date)
                              .format("DD MMM YYYY, hh:MM A")}
                          </TableCell>
                          <TableCell>{ele.invoiceFor}</TableCell>
                          <TableCell>
                            {ele.modeOfPayment}
                            {ele.modeOfPayment === "Online(Paperplane)" ? (
                              ele.paid ? (
                                <Chip
                                  label="Paid"
                                  style={{
                                    backgroundColor: "green",
                                    color: "white",
                                    borderRadius: "0.25em",
                                    marginLeft: "0.3rem",
                                  }}
                                ></Chip>
                              ) : (
                                <Chip
                                  label="Pending"
                                  style={{
                                    backgroundColor: "red",
                                    color: "white",
                                    borderRadius: "0.25em",
                                    marginLeft: "0.3rem",
                                  }}
                                ></Chip>
                              )
                            ) : null}
                          </TableCell>
                          <TableCell>₹{ele.amount}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : null}
            </Grid>
          )
        }}
        fullWidth
        maxWidth="md"
        showSubmitButton={false}
        cancelButtonText="Close"
        dialogTitle="Payment history"
        open={previousPaymentsOpen}
        setOpen={setPreviousPaymentsOpen}
      />
      {treatmentDialogOpen ? (
        <GenericDialogForm
          dialogTitle="Add New Treatment"
          open={treatmentDialogOpen}
          setOpen={setTreatmentDialogOpen}
          handleSubmit={(modalData) => {
            customAxios
              .put("/treatment/", modalData)
              .then((res) => {
                if (res.data.success) {
                  const curr = paymentsData.slice()
                  setTreatmentList((prev) => [
                    ...prev,
                    {
                      label: modalData.name,
                      value: res.data.data._id,
                      treatment: res.data.data,
                      _id: res.data.data._id,
                    },
                  ])
                  setSelectedTreatment(res.data.data._id)
                  curr.splice(curr.length, 0, {
                    treatmentId: res.data.data._id,
                    key: uniqueId(),
                    amount: 0,
                    mode: "Cash",
                    invoiceFor: "Service",
                  })
                  setPaymentsData(curr.slice())
                  toast.success("Procedure added succesfully")
                } else toast.error("Some error occured")
              })
              .catch((err) => {
                console.log(err)
                toast.error("Some error occured")
              })
          }}
          message={
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <GenericAnimation
                animationData={treatmentAnimation}
                height={200}
                width={200}
              ></GenericAnimation>
            </Grid>
          }
          dialogContent={[
            {
              key: "name",
              label: "Name",
              required: true,
              otherProps: { inputProps: { maxLength: 30 } },
            },
            { key: "totalAmount", required: true, label: "Total Amount" },
          ]}
        />
      ) : null}
    </div>
  )
}

export default ManagePaymentDialog
