import React from "react"
import Lottie from "react-lottie"
function GenericAnimation({
  height = 200,
  width = 200,
  animationData,
  loop = true,
  style = {},
}) {
  const defaultOptions = {
    loop: loop,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
    renderer: "svg",
  }

  return (
    <Lottie
    // style={{}}
      options={defaultOptions}
      height={height}
      width={width}
      isStopped={false}
      isPaused={false}
      style={style}
      isClickToPauseDisabled={true}
    />
  )
}

export default GenericAnimation
