import { Grid, TextField } from '@material-ui/core';
import React from 'react';
import Col from 'reactstrap/lib/Col';
import Label from 'reactstrap/lib/Label';
import Modal from 'reactstrap/lib/Modal';
import Row from 'reactstrap/lib/Row';
import DoctorSlotCreator from '../../../components/onboarding/DoctorSlotCreator';

function AddOrganisationModal({ isOpen, state, setState, setIsOpen }) {
  const handleOnChange = () => {
    setState({ ...state, isOnline: !state.isOnline });
  };

  return (
    <Modal
      className='modal-dialog-centered modal-xl '
      isOpen={isOpen}
      toggle={() => setIsOpen(false)}>
      <div className='modal-header'>
        <h5 className='modal-title' id='exampleModalLabel'>
          Add New Organisation
        </h5>
        <button
          aria-label='Close'
          className='close'
          data-dismiss='modal'
          type='button'
          onClick={() => setIsOpen(false)}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className='modal-body'>
        <Row className='p-3'>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                value={state.locationName}
                variant='outlined'
                fullWidth
                label='Clinic Name'
                onChange={(e) =>
                  setState({
                    ...state,
                    locationName: e.target.value,
                    organisationName: e.target.value,
                  })
                }></TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={state.address}
                variant='outlined'
                fullWidth
                label='Address'
                onChange={(e) =>
                  setState({ ...state, address: e.target.value })
                }></TextField>
            </Grid>

            <Grid item xs={6}>
              <TextField
                type='number'
                value={state.fees}
                variant='outlined'
                fullWidth
                label='	Fees'
                onChange={(e) =>
                  setState({ ...state, fees: e.target.value })
                }></TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                type='number'
                value={state.followUp}
                variant='outlined'
                fullWidth
                label='Follow Up '
                onChange={(e) =>
                  setState({ ...state, followUp: e.target.value })
                }></TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={state.interval}
                variant='outlined'
                fullWidth
                InputProps={{ endAdornment: 'min' }}
                label='Appointment Duration'
                disabled={!state.isOnline}
                onChange={(e) =>
                  setState({ ...state, interval: e.target.value })
                }></TextField>
            </Grid>
            <Col className='col-md-12 pt-3'>
              <Label className='label pr-5'>This is an Online Clinic:</Label>
              <div className='pt-1'>
                <label className='custom-toggle'>
                  <input
                    type='checkbox'
                    checked={state.isOnline}
                    onChange={handleOnChange}
                  />
                  <span
                    className='custom-toggle-slider rounded-circle'
                    data-label-off='No'
                    data-label-on='Yes'
                  />
                </label>
              </div>
            </Col>

            <Col className='col-md-12 mt-5'>
              <DoctorSlotCreator
                state={state}
                setState={setState}
                setIsOpen={setIsOpen}
              />
            </Col>
          </Grid>
        </Row>
      </div>
    </Modal>
  );
}

export default AddOrganisationModal;
