import React, { useState } from "react"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import customAxios from "../../../axios"
import GenericCardTable from "../GenericCardTable"
import { symptomsValuesForSearch } from "./../Constants/index"

function Symptoms({ state, setState, docId }) {
  const [suggestedSymptoms, setSuggestedSymptoms] = useState([])
  const user = useSelector((state) => state.user)
  useEffect(() => {
    const symptomsLength = state?.symptoms?.length
    if (symptomsLength) {
      let suggestedState = []
      let querySymptoms = state.symptoms.map((ele) => ele.name)
      console.log("resd", user.user)
      customAxios
        .put(`/ai/symptoms`, {
          names: querySymptoms.reverse(),
          doc_id: docId,
          spec: user?.user?.additionalDetail?.qualification
            ? user?.user?.additionalDetail?.qualification
            : "",
        })
        .then((res) => {
          if (res?.data?.success) {
            console.log("resd", res.data.data)
            suggestedState = suggestedState.concat(res?.data?.data)
            suggestedState = [...new Set(suggestedState)]
            suggestedState = suggestedState.filter(
              (symp) =>
                !state?.symptoms?.find(
                  (f) => f?.name?.toLowerCase() === symp.toLowerCase()
                )
            )
            setSuggestedSymptoms([...suggestedState])
          }
        })
    }
  }, [state?.symptoms])

  const cardTableHeaders = [
    {
      label: "Symptom",
      key: "name",
      placeholder: "eg. Fever",
      type: "autocomplete",
      options: symptomsValuesForSearch,
      required: true,
      width: "25%",
    },
    {
      label: "Severity",
      key: "severity",
      placeholder: "None",
      type: "select",
      width: "20%",
      dependencyKey: "name",
      options: ["Mild", "Moderate", "Severe"],
    },
    {
      label: "Onset Mode",
      key: "onset",
      placeholder: "None",
      type: "select",
      width: "20%",
      dependencyKey: "name",
      options: ["Sudden", "Gradual", "Contiuous", "Constant"],
    },
    {
      label: "Duration",
      key: "duration",
      placeholder: "None",
      endAdornment: "days",

      type: "text",
      width: "20%",
      dependencyKey: "name",
    },
  ]

  return (
    <React.Fragment>
      <GenericCardTable
        cardTitle={"Symptom Details:"}
        tabSwitching={true}
        suggestedTitle={"Suggested Symptoms"}
        suggestedData={suggestedSymptoms}
        tableHeaders={cardTableHeaders}
        suggestedTab={suggestedSymptoms.length}
        tableData={state}
        cardKey={"symptoms"}
        setTableData={setState}
      />
    </React.Fragment>
  )
}

export default Symptoms
