import React, { useEffect, useState } from "react"
import {
  Button,
  Grid,
  Typography,
  makeStyles,
  Menu,
  MenuItem,
  IconButton,
  FormControlLabel,
  Switch,
  TextField,
  Divider,
} from "@material-ui/core"
import "./index.css"
import GenericDialogForm from "./GenericDialog/index"
import { ArrowDropDown, DeleteForeverOutlined, Save } from "@material-ui/icons"
import Vitals from "./Vitals"
import Symptoms from "./Symptoms"
import Diagnosis from "./Diagnosis"
import Drugs from "./Drugs"
import Tests from "./Tests"
import FollowUp from "./followUp"
import customAxios from "../../axios"
import { toast } from "react-toastify"
import Exercises from "./Exercises"
import AdditionalDocs from "./AdditionalDocs"
import PreviousHistory from "./PreviousHistory"
import SystemExamination from "./SystemExamination"
import EndConsultation from "../Consultation(new)/EndConsultation"
import moment from "moment"

const useStyles = makeStyles({
  card: {
    background: "#FFFFFF",
    padding: "1rem !important",
    boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.03)",
    borderRadius: "12px",
    marginTop: "1rem",
  },
})

function CreatePrescription({
  docId,
  previousState,
  historyColumnFreeze,
  setHistoryColumnFreeze,
  state,
  setState,
  setActiveStep,
  setLoading,
  requiredKeys = [],
  preferences,
  patientData = null,
  handleEndConsultation,
  loading,
  appointmentData,
}) {
  const classes = useStyles()
  console.log("docId", docId)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [templateList, setTemplateList] = useState([])
  const [openTemplate, setOpenTemplate] = useState(false)
  const [clearCounter, setClearCounter] = useState(0)
  const [deleteTemplate, setDeleteTemplate] = useState(false)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  console.log("previousState", previousState)
  const handleSave = (state) => {
    let error = {}

    requiredKeys.map((v) => {
      if (!state[v.key] || !state[v.key].length) {
        error = { check: true, message: `${v.label} is required` }
      }
    })
    if (error?.check) {
      toast.error(error.message)
    } else {
      return true
    }
    return false
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  useEffect(() => {
    customAxios
      .get(`consultation/filter?doc_id=${docId}&is_template=true`)
      .then((res) => {
        if (res.data.success) setTemplateList(res?.data?.data)
      })
  }, [])
  console.log(state, "state")
  const dialogContent = [
    {
      label: "Template Name",
      key: "templateName",
      placeholder: "eg. Fever",
      type: "text",
      md: 12,
      maxLength: "15",
      required: true,
    },
  ]

  return (
    <Grid
      container
      spacing={1}
      id="prescriptionContainer"
      className="createPrescription"
    >
      <Grid item xs={12} md={6} style={{ display: "flex", flex: "flex-wrap" }}>
        <Typography variant="h5" style={{ fontWeight: "600" }}>
          Create
        </Typography>
        <Typography
          variant="h5"
          color="primary"
          style={{ marginLeft: 5, fontWeight: "600" }}
        >
          Prescription
        </Typography>
      </Grid>
      <Grid item container xs={12} md={6} spacing={1} justifyContent="flex-end">
        <Grid item xs={6} md={"auto"}>
          <Button
            fullWidth
            variant={"outlined"}
            color="primary"
            onClick={() =>
              setState((prev) => ({
                ...prev,
                saveTemplate: false,
                medicines: [],
                diagnosis: [],
                symptoms: [],
                tests: [],
                vitals: [],
                exercises: [],
              }))
            }
          >
            Clear Prescription
          </Button>
        </Grid>
        <Grid item xs={6} md={"auto"}>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            fullWidth
            disabled={!templateList.length}
            endIcon={<ArrowDropDown />}
            variant={"contained"}
            color="primary"
            onClick={handleClick}
          >
            Use Template
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {templateList?.map((template) => (
              <MenuItem
                onClick={() => {
                  toast.success("Template applied successfully!")
                  setState((prev) => ({
                    ...prev,
                    medicines: template.prescription.medicines
                      ? template.prescription.medicines
                      : [],
                    diagnosis: template.prescription.diagnosis
                      ? template.prescription.diagnosis
                      : [],
                    symptoms: template.prescription.symptoms
                      ? template.prescription.symptoms
                      : [],
                    exercises: template.prescription.exercises
                      ? template.prescription.exercises
                      : [],
                    tests: template.prescription.tests
                      ? template?.prescription?.tests?.map((ele) => ({
                          name: ele,
                        }))
                      : [],
                  }))
                  handleClose()
                }}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography>{template?.templateName?.slice(0, 15)} </Typography>
                {/* <IconButton>
                  <DeleteForeverOutlined color="error" />
                </IconButton> */}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      </Grid>
      {previousState ? (
        <Grid
          item
          container
          xs={12}
          style={{
            background: "#fff",
            padding: "1rem",
            marginTop: "1rem",
            minHeight: "50px",
            opacity: "1",
            zIndex: "1",
            position: historyColumnFreeze ? "sticky" : "inherit",
            top: historyColumnFreeze ? "0" : "inherit",

            borderRadius: historyColumnFreeze ? "12px 12px 0px 0px" : "12px",
          }}
        >
          <Grid
            item
            container
            xs={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h6">Previous Diagnosis Details:</Typography>
            </Grid>
            <Grid item>
              <FormControlLabel
                label="Freeze this section"
                control={
                  <Switch
                    color="primary"
                    checked={historyColumnFreeze}
                    onChange={(e) => {
                      setHistoryColumnFreeze(e.target.checked)
                    }}
                  />
                }
              ></FormControlLabel>
            </Grid>
          </Grid>
          <div
            style={{
              marginTop: "24px",
            }}
          ></div>
          <Grid
            container
            item
            xs={12}
            style={{
              background: "#f3f3f3",
              padding: "0.5rem",
              borderRadius: "12px",
            }}
          >
            <Grid item xs={12}>
              <Typography>
                Consulted at{" "}
                {moment.utc(previousState?.date).format("hh:MM A, DD MMM YYYY")}
              </Typography>
            </Grid>
            {previousState?.vitals ? (
              <Grid item xs={12} container style={{ marginTop: "0.5rem" }}>
                <Grid item xs={12}>
                  <Typography style={{ textDecoration: "underline" }}>
                    Vitals:
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  spacing={2}
                  style={{ marginLeft: "1rem" }}
                >
                  {previousState?.vitals?.map((ele) => (
                    <Grid item style={{ fontSize: "13px", color: "#000D85" }}>
                      <Typography>
                        <span
                          style={{
                            fontWeight: "600",
                            textTransform: "capitalize",
                          }}
                        >
                          {" "}
                          {ele.metricName}
                        </span>
                        : <span>{ele.metricQuantity}</span> {ele.metricUnit}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ) : null}

            {previousState?.symptoms ? (
              <Grid item xs={12} container style={{ marginTop: "0.5rem" }}>
                <Grid item xs={12}>
                  <Typography style={{ textDecoration: "underline" }}>
                    Symptoms:
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  spacing={2}
                  style={{ marginLeft: "1rem" }}
                >
                  {previousState?.symptoms?.map((ele) => (
                    <Grid item style={{ fontSize: "13px", color: "#000D85" }}>
                      <Typography>
                        <span
                          style={{
                            fontWeight: "600",
                            textTransform: "capitalize",
                          }}
                        >
                          {" "}
                          {ele.name}
                        </span>
                        :{" "}
                        <span>
                          {ele.severity ? ele.severity : null}{" "}
                          {ele.onset ? "-" + ele.onset : null}{" "}
                        </span>
                        {ele.duration ? "-" + ele.duration + " days" : null}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ) : null}

            {previousState?.diagnosis ? (
              <Grid item xs={12} container style={{ marginTop: "0.5rem" }}>
                <Grid item xs={12}>
                  <Typography style={{ textDecoration: "underline" }}>
                    Diagnosis:
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  spacing={2}
                  style={{ marginLeft: "1rem" }}
                >
                  {previousState?.symptoms?.map((ele) => (
                    <Grid item style={{ fontSize: "13px", color: "#000D85" }}>
                      <Typography>
                        <span
                          style={{
                            fontWeight: "600",
                            textTransform: "capitalize",
                          }}
                        >
                          {" "}
                          {ele.name}
                        </span>
                        <span>{ele.status ? ele.status : null} </span>{" "}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ) : null}

            {previousState?.patientNotes ? (
              <Grid item xs={12} container style={{ marginTop: "0.5rem" }}>
                <Grid item xs={12}>
                  <Typography style={{ textDecoration: "underline" }}>
                    Self-notes :
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  spacing={2}
                  style={{ marginTop: "0.5rem", marginLeft: "0.5rem" }}
                >
                  <Typography>{previousState.patientNotes}</Typography>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      ) : null}

      <Grid container item xs={12}>
        {historyColumnFreeze ? (
          <Grid item xs={12}>
            <Divider />
          </Grid>
        ) : null}

        {preferences.includes("Vitals") ? (
          <Grid item xs={12} className={classes.card}>
            <Vitals
              docId={docId}
              clearCounter={clearCounter}
              state={state}
              setState={setState}
            />
          </Grid>
        ) : null}
        <Grid item xs={12} className={classes.card}>
          <SystemExamination state={state} setState={setState} docId={docId} />
        </Grid>
        {preferences.includes("Symptoms") ? (
          <Grid item xs={12} className={classes.card}>
            <Symptoms state={state} setState={setState} docId={docId} />
          </Grid>
        ) : null}
        {preferences.includes("Diagnosis") ? (
          <Grid item xs={12} className={classes.card}>
            {preferences.includes("GynacVitals") ? (
              <Grid
                container
                spacing={2}
                item
                xs={12}
                style={{ marginTop: "1rem" }}
              >
                <Grid item container xs={6}>
                  <Grid item xs={12}>
                    <Typography color="textPrimary">LMP</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={state?.otherData?.LMP}
                      fullWidth
                      variant="outlined"
                      placeholder="None"
                      onChange={(e) => {
                        setState({
                          ...state,
                          otherData: {
                            ...state?.otherData,
                            LMP: e.target.value,
                          },
                        })
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item container xs={6}>
                  <Grid item xs={12}>
                    <Typography color="textPrimary">EDD</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={state?.otherData?.EDD}
                      fullWidth
                      placeholder="None"
                      variant="outlined"
                      onChange={(e) => {
                        setState({
                          ...state,
                          otherData: {
                            ...state?.otherData,
                            EDD: e.target.value,
                          },
                        })
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            <Diagnosis state={state} setState={setState} docId={docId} />
          </Grid>
        ) : null}
        <Grid item xs={12} className={classes.card}>
          <PreviousHistory
            state={state}
            setState={setState}
            docId={docId}
            preferences={preferences}
          />
        </Grid>
        {preferences.includes("Drugs") ? (
          <Grid item xs={12} className={classes.card}>
            <Drugs
              state={state}
              setState={setState}
              docId={docId}
              previousState={previousState}
            />
          </Grid>
        ) : null}

        {preferences.includes("Exercise") ? (
          <Grid item xs={12} className={classes.card}>
            <Exercises state={state} setState={setState} docId={docId} />
          </Grid>
        ) : null}
        <Grid item xs={12} className={classes.card}>
          <AdditionalDocs state={state} setState={setState} docId={docId} />
        </Grid>
        {preferences.includes("Tests") ? (
          <Grid item xs={12} className={classes.card}>
            <Tests state={state} setState={setState} docId={docId} />
          </Grid>
        ) : null}
        {/* {preferences.includes("VaccinationChart") ? <div>Helo</div> : null}
      <Grid item xs={12} className={classes.card}>
        <VaccinationChart
          state={state}
          patientData={patientData}
          setState={setState}
        />
      </Grid> */}
        <Grid item xs={12} container className={classes.card}>
          <Grid item xs={12}>
            <Typography variant="h6">Advice:</Typography>
          </Grid>
          <TextField
            value={state?.otherData?.advice}
            fullWidth
            placeholder="None"
            multiline
            variant="outlined"
            onChange={(e) => {
              setState({
                ...state,
                otherData: { ...state?.otherData, advice: e.target.value },
              })
            }}
          />
        </Grid>
        <Grid item xs={12} container className={classes.card}>
          <Grid item xs={12} style={{ marginBottom: "0.5rem" }}>
            <Typography variant="h6">Self-notes:</Typography>
            <Typography variant="body1" color="secondary">
              These are additional notes that are only visible to the doctor for
              their own reference.
            </Typography>
          </Grid>
          <TextField
            value={state.patientNotes}
            fullWidth
            variant="outlined"
            multiline
            rows={3}
            inputProps={{ maxLength: 800 }}
            maxRows={5}
            onChange={(e) => {
              setState({
                ...state,
                patientNotes: e.target.value,
              })
            }}
            placeholder="None"
          ></TextField>
        </Grid>
        <Grid item xs={12} className={classes.card}>
          <FollowUp state={state} setState={setState} docId={docId} />
        </Grid>
        <Grid item xs={6} style={{ marginTop: "2rem" }}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={state.saveTemplate ? state?.saveTemplate : false}
                onChange={(e) => {
                  if (e.target.checked) setOpenTemplate(true)
                  else
                    setState({
                      ...state,
                      saveTemplate: e.target.checked,
                      templateName: null,
                    })
                }}
                name="checkedA"
              />
            }
            label={
              state?.templateName
                ? `Saving template as ${state?.templateName}`
                : `Save Template`
            }
          />
        </Grid>
        <EndConsultation
          appointmentData={appointmentData}
          setLoading={setLoading}
          handleEndConsultation={handleEndConsultation}
          loading={loading}
          patientData={patientData}
          requiredKeys={requiredKeys}
          state={state}
          setState={setState}
          setActiveStep={setActiveStep}
        />
      </Grid>
      {/* <Grid item container justifyContent="flex-start">
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSave(state)}
        >
          Save & Continue
        </Button>
      </Grid>{" "} */}
      <GenericDialogForm
        open={openTemplate}
        handleClose={() => {
          setOpenTemplate(false)

          setState({ ...state, saveTemplate: false })
        }}
        dialogTitle={"Save Template"}
        dialogContent={dialogContent}
        handleCancel={() => {
          setOpenTemplate(false)
          setState({ ...state, saveTemplate: false })
        }}
        handleSubmit={(data) => {
          setState({
            ...state,
            templateName: data.templateName,
            saveTemplate: true,
          })
        }}
        submitButtonText={"Save"}
      ></GenericDialogForm>
    </Grid>
  )
}

export default CreatePrescription
