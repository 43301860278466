/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or subfntial portions of the Software.

*/
import React, { useEffect, useState } from "react"
// react plugin used to create charts
// reactstrap components
import { Container, Row, Col } from "reactstrap"

// core components

import Header from "../../../components/Dashboard/Home/Header"
import DashboardTable from "../../../components/Dashboard/Home/DashboardTable"
import DailyMetics from "../../../components/Dashboard/Home/DailyMetics"
import CurrentAction from "../../../components/Dashboard/Home/CurrentAction"
import { useDispatch, useSelector } from "react-redux"

import AddPatientModal from "./AddPatientModal"
import {
  clearAppointmentDetails,
  clearMedicalHistory,
  clearPrescriptionDetails,
  clearVitals,
} from "../../../redux/actions/consultation.actions"
import AddPatientWithScheduleModal from "./AddPatientsWithScheduleModal"
import dashboardSocket from "../../../socket/dashboardSocket"
import prescriptionSocket from "../../../socket"
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core"
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}))

function Dashboard(props) {
  const currOrganisation = useSelector((state) => state?.user?.currOrganisation)

  const dispatch = useDispatch()
  const [updater, setUpdater] = useState(false)
  const docId = useSelector((state) => state.user.user._id)
  const isDoctor = useSelector((state) => state.auth.isDoctor)
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenWithSchedule, setIsOpenWithSchedule] = useState(false)
  const [modalData, setModalData] = useState({
    gender: "male",
  })
  const [loading, setLoading] = useState(false)
  const startLoading = () => {
    setLoading(true)
  }
  const endLoading = () => {
    setLoading(false)
  }
  const classes = useStyles()
  prescriptionSocket.on("appointment_update", (args) => {
    console.log("args", args)
    if (args) setUpdater((prev) => !prev)
  })

  const [dummyCounter, setDummyCounter] = useState(0)
  useEffect(() => {
    console.log(dummyCounter)
  }, [dummyCounter])
  useEffect(() => {
    dispatch(clearPrescriptionDetails())
    dispatch(clearMedicalHistory())
    dispatch(clearVitals())
    dispatch(clearAppointmentDetails())
    // eslint-disable-next-line
  }, [])
  return (
    <>
      {/* <Header parentName="Dashboard" /> */}
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Container className="mt-3" fluid>
        {/* {isDoctor && (
          <Row>
            <Col md="6">
              <CurrentAction />
            </Col>
            <Col md="6">
              <DailyMetics />
            </Col>
          </Row>
        )} */}
        <Row>
          <Col md="12">
            <DashboardTable
              modalData={modalData}
              updater={updater}
              startLoading={startLoading}
              endLoading={endLoading}
              setUpdater={setUpdater}
              setIsOpen={setIsOpen}
              dummyCounter={dummyCounter}
              isOpenWithSchedule={isOpenWithSchedule}
              setIsOpenWithSchedule={setIsOpenWithSchedule}
              setModalData={setModalData}
            />
          </Col>
        </Row>
      </Container>
      {isOpen ? (
        <AddPatientModal
          setUpdater={setUpdater}
          dummyCounter={dummyCounter}
          setDummyCounter={setDummyCounter}
          isOpen={isOpen}
          modalData={modalData}
          setModalData={setModalData}
          setIsOpen={setIsOpen}
        ></AddPatientModal>
      ) : null}
      <AddPatientWithScheduleModal
        dummyCounter={dummyCounter}
        setDummyCounter={setDummyCounter}
        isOpen={isOpenWithSchedule}
        modalData={modalData}
        setModalData={setModalData}
        setIsOpen={setIsOpenWithSchedule}
      />{" "}
    </>
  )
}
export default Dashboard
