import moment from "moment"
function convertToCSV(objArray) {
  var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray
  var str = ""

  for (var i = 0; i < array.length; i++) {
    var line = ""
    for (var index in array[i]) {
      if (line != "") line += ","

      line += array[i][index]
    }

    str += line + "\r\n"
  }

  return str
}

export function exportCSVFile(headers, items, fileTitle) {
  if (headers) {
    items.unshift(headers)
  }

  // Convert Object to JSON
  var jsonObject = JSON.stringify(items)

  var csv = convertToCSV(jsonObject)

  var exportedFilenmae = fileTitle + ".csv" || "export.csv"

  var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae)
  } else {
    var link = document.createElement("a")
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob)
      link.setAttribute("href", url)
      link.setAttribute("download", exportedFilenmae)
      link.style.visibility = "hidden"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

// or 'my-unique-title'

export const csvExporter = (itemsNotFormatted, type="chat_format") => {
  let headers = {}

  switch(type){
    case "chat_format": {
      headers = {
        name: "Name",
        phoneNo: "PhoneNo",
        tags: "Contact Tags"
      }
    }
  }
  
  const itemsFormatted = itemsNotFormatted.map((ele, idx) => ({
    name: ele?.contact?.name,
    phoneNo: ele?.contact?.phoneNo,
    tags: ele?.tags?.length ? ele?.tags?.toString() : "-"
  }))
  const fileTitle = `ChatDATA-${moment
    .utc(new Date())
    .format("DD/MM/YYYY")}`
  exportCSVFile(headers, itemsFormatted, fileTitle)
}
