import { Chip, Grid, IconButton, Typography } from "@material-ui/core";
import { AddBox } from "@material-ui/icons";
import React, { useRef } from "react";

function AdditionalDocs({ state, setState }) {
  const additionalFilesRef = useRef(null);
  const uploadFiles = async (files) => {
    const tempArr = state?.additionalDocs?.length
      ? [...state.additionalDocs]
      : [];
    if (files) {
      for (const file of files) {
        let reader = new FileReader();

        reader.onload = function (e) {
          tempArr.push({
            file: e.target.result,
            name: file.name,

            type: "Addtional Docs",
            extension: file.type,
          });

          setState({ ...state, additionalDocs: [...tempArr] });
        };

        reader.readAsDataURL(file);
      }
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} container alignItems="center">
        <Typography variant="h6">Supporting Documents:</Typography>
        <IconButton>
          <AddBox
            color="primary"
            onClick={() => additionalFilesRef.current.click()}
          ></AddBox>
        </IconButton>
      </Grid>
      <input
        onChange={(e) => {
          uploadFiles(e.target.files);
        }}
        hidden
        label="additionalFiles"
        ref={additionalFilesRef}
        title="FilesUploader"
        type="file"
        multiple
        accept="image/*,application/pdf"
      ></input>
      <Grid item xs={12} container>
        {state?.additionalDocs?.map((file, idx) => {
          console.log("files", file.name);
          return (
            <div className="m-1">
              <Chip
                variant="outlined"
                color="primary"
                onDelete={() => {
                  const temp = [...state.additionalDocs];

                  temp.splice(idx, 1);
                  setState({ ...state, additionalDocs: [...temp] });
                }}
                label={file.name}
              ></Chip>
            </div>
          );
        })}
      </Grid>
    </Grid>
  );
}

export default AdditionalDocs;
