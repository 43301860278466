import {
  USER_LOADING,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REMOVE_USER,
  REGISTER_SUCCESS,
  ONBOARDING_SUCCESS,
  START_LOADING,
  END_LOADING,
} from "../constants/index.js"
import mixpanel from "mixpanel-browser"
import {
  loadCurrentOrganisations,
  loadDoctor,
  loadOrganisations,
} from "./user.actions"
import customAxios from "../../axios/index.js"
import { toast } from "react-toastify"
export const startLoading = () => {
  return { type: START_LOADING }
}
export const endLoading = () => {
  return { type: END_LOADING }
}
export const loginUserSuccess = (userData) => ({
  type: LOGIN_SUCCESS,
  payload: { ...userData, version: "3.0.0." },
})
const registerUserSuccess = (userData) => ({
  type: REGISTER_SUCCESS,
  payload: userData,
})
export const onboardingSuccess = () => ({
  type: ONBOARDING_SUCCESS,
  payload: {},
})
export const loginUser = (payload) => {
  console.log(payload)
  return async (dispatch) => {
    dispatch({ type: USER_LOADING })

    customAxios
      .post(`/auth/login`, {
        ...payload,
      })
      .then((res) => {
        if (!res.data.success) {
          dispatch({ type: LOGIN_FAIL, payload: {} })
          toast.error("Please enter the correct authentication details")
        } else {
          localStorage.setItem("token", res.data.data.access_token)
          
          // FOR LOADING DATA ON LOGIN
          dispatch(loadDoctor())
          dispatch(loginUserSuccess(res.data.data))
        }
      })
      .catch((err) => {
        //setAlertState({ show: true, type: "danger", msg: err });
        console.log(err)
        dispatch({ type: LOGIN_FAIL, payload: err })
        //message.error({ content: error.message, key: messageKey });
      })
  }
}
export const logout = () => {
  mixpanel.track("Logout")
  mixpanel.reset()
  localStorage.removeItem("token")
  localStorage.removeItem("persist:root")
  return async (dispatch) => {
    dispatch({ type: LOGOUT_SUCCESS })
    dispatch({ type: REMOVE_USER })
  }
}

export const registerUser = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_LOADING })

    customAxios
      .post(`/auth/`, {
        ...payload,
      })
      .then((res) => {
        console.log(res.data, "register")
        if (!res.data.success) {
          dispatch({ type: LOGIN_FAIL, payload: {} })
        } else {
          localStorage.setItem(
            "token",
            res.data.data.token_data.data.access_token
          )
          dispatch(loadDoctor())
          dispatch(registerUserSuccess(res.data.data.token_data.data))
        }
      })
      .catch((err) => {
        //setAlertState({ show: true, type: "danger", msg: err });
        console.log(err)
        dispatch({ type: LOGIN_FAIL, payload: err })
        //message.error({ content: error.message, key: messageKey });
      })
  }
}
