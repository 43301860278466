import { Chip, Grid, Typography, TextField } from "@material-ui/core"
import React, { useState } from "react"

function PreviousHistory({ state, setState, preferences }) {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        container
        // style={{ marginTop: "1rem" }}
        alignItems="center"
      >
        <Typography variant="h6">Previous History</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          placeholder="eg:Diabetic, problems of asthma"
          value={state?.otherData?.previousHistory}
          onChange={(e) => {
            setState({
              ...state,
              otherData: {
                ...state?.otherData,
                previousHistory: e.target.value,
              },
            })
          }}
          fullWidth
          variant="outlined"
          color="primary"
          //   onKeyDown={(e) => {
          //     if (e.key === "Enter") {
          //       setVal("");
          //       const temp = state?.otherData?.previousHistory
          //         ? [...state?.otherData?.previousHistory]
          //         : [];

          //       temp.push(val);
          //       setState({
          //         ...state,
          //         otherData: {
          //           ...state?.otherData,
          //           previousHistory: [...temp],
          //         },
          //       });
          //     }
          //   }}
        />
      </Grid>
      {preferences?.includes("ObsHistory") ? (
        <>
          <Grid
            item
            xs={12}
            container
            style={{ marginTop: "1rem" }}
            alignItems="center"
          >
            <Typography color="textPrimary">OBS History</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              placeholder="eg:Diabetic, problems of asthma"
              value={state?.otherData?.obsHistory}
              onChange={(e) => {
                setState({
                  ...state,
                  otherData: {
                    ...state?.otherData,
                    obsHistory: e.target.value,
                  },
                })
              }}
              fullWidth
              variant="outlined"
              color="primary"
              //   onKeyDown={(e) => {
              //     if (e.key === "Enter") {
              //       setVal("");
              //       const temp = state?.otherData?.previousHistory
              //         ? [...state?.otherData?.previousHistory]
              //         : [];

              //       temp.push(val);
              //       setState({
              //         ...state,
              //         otherData: {
              //           ...state?.otherData,
              //           previousHistory: [...temp],
              //         },
              //       });
              //     }
              //   }}
            />
          </Grid>
        </>
      ) : null}
      {/* <Grid item xs={12} container style={{ marginTop: "1rem" }}>
        {state?.otherData?.previousHistory?.map((file, idx) => {
          console.log("files", file.name);
          return (
            <div className="m-1">
              <Chip
                color="primary"
                onDelete={() => {
                  const temp = [...state?.otherData?.previousHistory];

                  temp.splice(idx, 1);
                  setState({
                    ...state,
                    otherData: {
                      ...state?.otherData,
                      previousHistory: [...temp],
                    },
                  });
                }}
                label={file}
              ></Chip>
            </div>
          );
        })}
      </Grid> */}
    </Grid>
  )
}
export default PreviousHistory
