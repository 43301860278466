import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import customAxios from "../../../axios"
import GenericCardTable from "../GenericCardTable"
import { medicalTestsForSearch } from "./../Constants/index"
function Tests({ state, setState, docId }) {
  const [suggestedTests, setSuggestedTests] = useState([])
  const user = useSelector((state) => state.user)
  useEffect(() => {
    const testsLength = state?.diagnosis?.length
    if (testsLength) {
      let suggestedState = []
      let querytests = state.diagnosis.map((ele) => ele.name)
      console.log("resd", user.user)
      customAxios
        .put(`/ai/tests`, {
          names: querytests.reverse(),
          doc_id: docId,
          spec: user?.user?.additionalDetail?.qualification
            ? user?.user?.additionalDetail?.qualification
            : "",
        })
        .then((res) => {
          if (res?.data?.success) {
            console.log("resd", res.data.data)
            suggestedState = suggestedState.concat(res?.data?.data)
            suggestedState = [...new Set(suggestedState)]
            suggestedState = suggestedState.filter(
              (symp) =>
                !state?.tests?.find(
                  (f) => f?.name?.toLowerCase() === symp.toLowerCase()
                )
            )
            setSuggestedTests([...suggestedState])
          }
        })
    }
  }, [state?.diagnosis])
  const cardTableHeaders = [
    {
      label: "Test",
      key: "name",
      placeholder: "eg. Full Body Checkup",
      type: "autocomplete",
      options: medicalTestsForSearch,
      required: true,
      width: "35%",
    },
  ]

  return (
    <React.Fragment>
      <GenericCardTable
        cardTitle={"Test Details:"}
        suggestedTitle={"Suggested Tests"}
        suggestedData={suggestedTests}
        tableHeaders={cardTableHeaders}
        tableData={state}
        suggestedTab={suggestedTests.length}
        cardKey="tests"
        setTableData={setState}
      />
    </React.Fragment>
  )
}

export default Tests
