import {
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  Button,
  DialogActions,
  FormControlLabel,
  Switch,
  Checkbox,
  makeStyles,
} from "@material-ui/core"
import { FacebookShareButton, FacebookIcon, WhatsappIcon } from "react-share"
import whatsappLogo from "./../../assets/img/icons/common/whatsapp.png"
import axios from "axios"
import mixpanel from "mixpanel-browser"
import React, { useEffect, useState, useRef } from "react"
import { toast } from "react-toastify"
import LoaderFullpage from "../Common/LoaderFullpage"
import GenericDialogForm from "../CreatePrescription/GenericDialog"
import generationAnimation from "./../../assets/animationData/ai.json"
import customAxios from "../../axios"
import { useSelector } from "react-redux"
import dashboardSocket from "../Dashboard/Home/AddAppointmentDialog/doctorSocket.jsx"
import Slider from "@material-ui/core/Slider"

const useStyles = makeStyles((theme) => ({
  root: {
    transform: "translateZ(0px)",
    flexGrow: 1,
  },
  exampleWrapper: {
    position: "relative",
    marginTop: theme.spacing(3),
    height: 380,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: "absolute",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
}))

function CaptionGeneratorDialog({ ...props }) {
  const { imgData, open, setOpen, onBroadcastSuccess = () => {} } = props
  const [selectedOption, setSelectedOption] = useState("Introduce yourself")
  const [caption, setCaption] = useState({ suggested: "", value: "" })
  const [suggestedCaptionList, setSuggestedCaptionList] = useState([])
  const [loading, setLoading] = useState(false)
  const [customTopic, setCustomTopic] = useState("")
  const [shareWithCaption, setShareWithCaption] = useState(true)
  const [showOtherButtons, setShowOtherButtons] = useState(false)

  const spec = useSelector(
    (state) => state?.user?.user?.additionalDetail?.qualification
  )
  const name = useSelector((state) => state?.user?.user?.name)
  const captionIndex = useRef(0)
  const shareData = (data) => {
    if (data.caption && !name.includes("test"))
      customAxios
        .post("/ai/poster-share-data", {
          submittedCaption: data.caption,
          imageURL: data.url,
          keywordsEntered: customTopic,
          lastGeneratedCaption: caption.suggested,
          doc_id: data.docId,
          spec: spec ? spec : "",
        })
        .then((res) => {})
        .catch((err) => console.log(err))
    // toast.success(
    //   "Marketing poster shared with all your patients on WhatsApp."
    // )
  }
  const handleBroadcastImage = (data) => {
    customAxios
      .put(`/doctor/broadcast-message`, data)
      .then((res) => {
        if (res.data.success) {
          setOpen(false)

          onBroadcastSuccess()
          toast.success(
            "Marketing poster shared with all your patients on WhatsApp."
          )
          shareData(data)
          mixpanel.track("Whatsapp broadcast", { data: data })
        } else toast.error("Some error occured")
      })
      .catch((err) => {
        console.log("err34", err)
        toast.error("Some error occured")
      })
  }
  function copyToClipboard(copyText, showToast = true) {
    navigator.clipboard.writeText(copyText).then(() => {
      // Alert the user that the action took place.
      // Nobody likes hidden stuff being done under the hood!

      if (showToast) toast.success("Copied to clipboard")
    })
  }

  // useEffect(() => {
  //   function update() {
  //     let cIndex = captionIndex.current
  //     captionIndex.current += 3

  //     setCaption((prevState) => ({
  //       ...prevState,
  //       value:
  //         prevState?.value +
  //         prevState?.fullValue.substring(
  //           cIndex ? cIndex : cIndex + 1,
  //           captionIndex.current
  //         ),
  //     }))
  //   }
  //   if (caption?.fullValue)
  //     if (captionIndex.current < caption?.fullValue?.length + 3) {
  //       let addChar = setInterval(update, 3)
  //       return () => clearInterval(addChar)
  //     }
  // }, [caption.value])

  const customMarks = [
    {
      value: 0,
      label: "Standard",
    },
    {
      value: 50,
      label: "Creative (preferred)",
    },
    {
      value: 100,
      label: "Surprising",
    },
  ]

  const [temperatureValue, setValue] = useState(40)
  const changeValue = (onChange, temperatureValue) => {
    setValue(temperatureValue)
  }

  if (temperatureValue < 10) {
    setValue(10)
  }
  var temperature = (temperatureValue / 100) * 5 + 0.0000001
  const docName = useSelector((state) => state?.user?.user?.name)
  const getSuggestedCaptions = (selectedOption, keywords) => {
    if (selectedOption !== "freehand") {
      const option = options.find((ele) => ele.title === selectedOption)
      const keyWord =
        option.data[Math.floor(Math.random() * option.data.length)]
      setCustomTopic(keyWord)
      setLoading(true)

      axios
        .post(
          "https://api-inference.huggingface.co/models/metamyth/jenny_prod",
          {
            inputs: keyWord,
            parameters: {
              min_length: 40,
              max_length: 65,
              repetition_penalty: 3.000000000000001,

              // creative control
              do_sample: true,
              temperature: 1.2,

              early_stopping: false,
              num_beams: 10,
              // num_return_sequences: 3,
              no_repeat_ngram_size: 3,
            },
          },
          {
            headers: {
              Authorization: "Bearer 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",
            },
          }
        )
        .then((res) => {
          captionIndex.current = 0
          const finalArr = res?.data?.map((ele) => ({
            suggested: ele.generated_text,
            value: ele.generated_text.replace("AI ", `Artificial Intelligence `),
            value: ele.generated_text.replace("I ", `I, ${docName} `),
          }))
          setShowOtherButtons(false)

          setLoading(false)
          setSuggestedCaptionList([...finalArr])
          setCaption({
            ...finalArr[0],
          })
          setTimeout(() => {
            setShowOtherButtons(true)
          }, 1000)
        })
        .catch((err) => {
          toast.warn(
            "We are facing a lot of requests right now. Please try again after 30 seconds"
          )
          setLoading(false)
        })
    } else if (keywords) {
      setLoading(true)
      axios
        .post(
          "https://api-inference.huggingface.co/models/metamyth/jenny_prod",
          {
            inputs: keywords,
            parameters: {
              min_length: 40,
              max_length: 65,
              repetition_penalty: 3.000000000000001,

              // creative control
              do_sample: true,
              temperature: temperature,

              early_stopping: false,
              num_beams: 10,
              // num_return_sequences: 3,
              no_repeat_ngram_size: 3,
            },
          },
          {
            headers: {
              Authorization: "Bearer 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",
            },
          }
        )
        .then((res) => {
          captionIndex.current = 0
          const finalArr = res?.data?.map((ele) => ({
            suggested: ele.generated_text,
            value: ele.generated_text.replace("I ", `I, ${docName} `),
          }))
          setShowOtherButtons(false)
          setLoading(false)
          setSuggestedCaptionList([...finalArr])
          setTimeout(() => {
            setShowOtherButtons(true)
          }, 1000)

          setCaption({
            ...finalArr[0],
          })
        })
        .catch((err) => {
          setLoading(false)
          toast.warn(
            "We are facing a lot of requests right now. Please try again in a 30 seconds"
          )
        })
    }
  }
  useEffect(() => {
    if (selectedOption) getSuggestedCaptions(selectedOption)
  }, [selectedOption])
  const [editMode, setEditMode] = useState(false)
  const extractKeywords = (customTopic) => {
    let keywords = customTopic?.trim().split(" ")
    if (!customTopic.length || keywords.length < 2)
      toast.error("Please enter at-least two words")
    else {
      getSuggestedCaptions("freehand", customTopic)
    }
  }

  const options = [
    {
      title: "Introduce yourself",
      data: [
        "welcome",
        "ready for you",
        "available anytime",
        "available",
        "promote: availablity",
        "whats up",
        "promote: how are you doing?",
        "paperplane is superpower",
        "promote: available fast",
        "hello",
        "introduce to",
      ],
    },
    {
      title: "Interact with my WhatsApp Assistant",
      data: [
        "conversation",
        "reply",
        "AI - receptionist - chat",
        "treatment",
        "AI - chat",
        "connect - whatsapp",
        "virtual receptionist",
        "receptionist - AI",
        "reports - prescriptions",
        "never miss",
      ],
    },
    {
      title: "Book appointment",
      data: [
        "promote: appointment",
        "manage your appointments",
        "disease - treat",
        "no email",
        "time",
        "reach",
        "consultation - experience",
        "appointment",
        "techology - payments",
        "stay private",
        "follow-up",
      ],
    },
    {
      title: "Get Digital Prescriptions and Reminders",
      data: [
        "medicines directly",
        "remind",
        "reminders",
        "never forget medicines",
        "promote: whatsapp clinic",
        "promote: prescriptions",
        "promote: receive prescriptions",
        "receive prescription",
        "patient interaction",
        "experience",
        "follow-up - remind",
      ],
    },
    {
      title: "Modernize your consultation",
      data: [
        "breakthrough",
        "new era",
        "privacy create ABHA",
        "digital",
        "tech",
        "promote: modern",
        "promote: tech",
        "your private date",
        "AI",
        "Artificial Intelligence",
        "tech enabled",
        "data private",
        "superpowers",
        "modern approach",
        "promote: whatsapp clinic",
        "promote: clinic",
        "no papers",
        "remote",
      ],
    },
    {
      title: "Stay aware",
      data: [
        "hindsight",
        "my wisdom",
        "knowledge",
        "post",
        "facts",
        "get facts",
        "busting myths",
        "avoiding carelessness",
        "avoid carelessness",
        "avoid",
        "avoiding",
        "state-of-the-art",
      ],
    },
    {
      title: "Elevate your Brand with patients",
      data: [
        "new era tech",
        "new era",
        "effortless appointment",
        "portfolio website",
        "share",
        "new digital era",
        "new era technology",
        "professional reports",
        "revolution",
      ],
    },
  ]
  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xl">
      <DialogTitle onClose={() => setOpen(false)}>
        <Grid item container xs={12} justifyContent="space-between">
          <Grid item>
            <Typography style={{ fontSize: "25px" }}>
              Sit back and let{" "}
              <span
                style={{
                  // background: colors.gradient,
                  color: "white",
                  padding: "0.3rem",
                  borderRadius: "4px",

                  background:
                    "linear-gradient(128.8deg, #4D63DD 28.61%, #22A1F5 99.07%)",
                }}
              >
                AI promote your Digital Clinic
              </span>{" "}
            </Typography>
          </Grid>
          <Grid item>
            {" "}
            <IconButton
              color="secondary"
              onClick={() => {
                setOpen(false)
              }}
            >
              <i class="far fa-times-circle"></i>
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <LoaderFullpage
            loading={loading}
            message="Generating new captions using AI"
            animationData={{
              animationData: generationAnimation,
              height: 400,
              width: 400,
            }}
          />
        ) : null}

        <Grid container xs={12} alignItems="center">
          <Grid item xs={12} md={4} style={{ padding: "1rem" }}>
            <img
              alt=""
              src={imgData.url}
              style={{
                objectFit: "contain",
                width: "90%",
                borderRadius: "1rem",
              }}
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={8}
            style={{
              padding: "1rem",
              borderRadius: "1rem",
            }}
            spacing={1}
          >
            <Grid item xs={12}>
              <Typography variant="h6">
                Tell us what you're thinking? 🤔
              </Typography>
            </Grid>
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={12} style={{ marginBottom: "0.5rem" }}>
                <Typography variant="body1" color="secondary">
                  {" "}
                  Click on a word to get new suggestions.
                </Typography>
              </Grid>

              {options.map((ele) => {
                return (
                  <Grid item>
                    <Chip
                      label={ele.title}
                      icon={
                        selectedOption === ele.title ? (
                          <i
                            className="fas fa-random"
                            style={{ color: "aliceblue" }}
                          ></i>
                        ) : null
                      }
                      title={
                        selectedOption === ele.title
                          ? " Click on a topic to get new suggestions"
                          : ""
                      }
                      color={
                        selectedOption === ele.title ? "primary" : "secondary"
                      }
                      onClick={() => {
                        if (selectedOption === ele.title) {
                          getSuggestedCaptions(ele.title)
                        } else setSelectedOption(ele.title)
                      }}
                      variant={
                        selectedOption === ele.title ? "default" : "outlined"
                      }
                    ></Chip>
                  </Grid>
                )
              })}
              {editMode ? (
                <GenericDialogForm
                  open={editMode}
                  dialogTitle="Edit caption"
                  maxWidth="sm"
                  fullWidth={true}
                  handleSubmit={(data) => {
                    console.log("err34", caption, data)
                    setCaption({
                      value: data.caption,
                      suggested: caption.suggested,
                    })
                  }}
                  formData={{ caption: caption.value }}
                  dialogContent={[
                    {
                      label: "Caption",
                      maxLength: "320",
                      key: "caption",
                      md: 12,
                      required: true,
                      otherProps: {
                        multiline: true,
                        rows: 3,
                        maxRows: 4,
                        inputProps: { maxLength: 320 },
                      },
                    },
                  ]}
                  handleClose={() => {
                    setEditMode(false)
                  }}
                ></GenericDialogForm>
              ) : null}
              <Grid container xs={12} item spacing={2}>
                <Grid item>
                  <Chip
                    label="Give a custom topic"
                    title={
                      selectedOption === "freehand"
                        ? " Click on a topic to get new suggestions"
                        : ""
                    }
                    color={
                      selectedOption === "freehand" ? "primary" : "secondary"
                    }
                    onClick={() => {
                      setSelectedOption("freehand")
                    }}
                    variant={
                      selectedOption === "freehand" ? "default" : "outlined"
                    }
                    icon={<i className="fa-solid fa-pencil"></i>}
                  ></Chip>
                </Grid>
                {selectedOption === "freehand" ? (
                  <Grid item xs={6}>
                    <TextField
                      // variant="outline"
                      placeholder="example: I'm opening a new clinic"
                      value={customTopic}
                      fullWidth
                      autoFocus
                      inputProps={{ maxLength: "40" }}
                      helperText={
                        "Write at least 2 words and leave spaces between them."
                      }
                      variant="outlined"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          extractKeywords(customTopic)
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            color="primary"
                            onClick={() => extractKeywords(customTopic)}
                          >
                            {" "}
                            <i class="fas fa-paper-plane"></i>
                          </IconButton>
                        ),
                      }}
                      onChange={(e) => {
                        // var regex = new RegExp("[a-zA-Z][a-zA-Z ]+")
                        // if (regex.test(e.target.value))
                        setCustomTopic(e.target.value)
                      }}
                    ></TextField>
                    <Slider
                      value={temperatureValue}
                      onChange={changeValue}
                      size="small"
                      defaultValue={40}
                      aria-label="Small"
                      valueLabelDisplay="auto"
                      marks={customMarks}
                      onChangeCommitted={(e, value) => {
                        extractKeywords(customTopic)
                      }}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            {suggestedCaptionList.length ? (
              <Grid
                item
                container
                xs={12}
                style={{
                  // border: "1px solid rgba(0, 0, 0,0.2)",
                  borderRadius: "4px",
                  padding: "4px",
                  minHeight: "200px",
                  color: "dimgray",
                }}
                justifyContent={!showOtherButtons ? "center" : "space-between"}
                alignItems="center"
              >
                {showOtherButtons ? (
                  <Grid item style={{ textAlign: "center" }}>
                    <IconButton
                      disabled={suggestedCaptionList?.length < 2}
                      color="secondary"
                      onClick={() => {
                        const idx = suggestedCaptionList.findIndex(
                          (ele) => ele.suggested === caption.suggested
                        )
                        if (idx === 0) {
                          if (selectedOption === "freehand") {
                            toast.error(
                              "Please give a different topic to generate new captions"
                            )
                          } else getSuggestedCaptions(selectedOption)
                        } else setCaption({ ...suggestedCaptionList[idx - 1] })
                      }}
                    >
                      <i class="fas fa-caret-left"></i>
                    </IconButton>

                    <Typography
                      color="secondary"
                      style={{ fontSize: "10px", marginTop: "-0.7rem" }}
                    >
                      {" "}
                      Previous
                    </Typography>
                  </Grid>
                ) : null}
                <Grid
                  item
                  xs={10}
                  style={{
                    position: "relative",
                    padding: "1rem",
                    // minHeight: "150px",
                  }}
                >
                  <Typography
                    // onClick={() => setOpenDatePicker(true)}
                    style={{
                      // cursor: "pointer",
                      fontSize: "16px",
                      fontWeight: "700",
                      position: "relative",
                    }}
                  >
                    {caption.value}
                  </Typography>

                  {showOtherButtons ? (
                    <IconButton
                      style={{
                        fontSize: "16px",
                        position: "absolute",
                        top: 0,
                        right: "-1rem",
                      }}
                      color="secondary"
                      title="Edit Caption"
                      onClick={() => setEditMode((prev) => !prev)}
                    >
                      <i class="fa-solid fa-pencil"></i>
                    </IconButton>
                  ) : null}
                  {showOtherButtons ? (
                    <IconButton
                      style={{
                        fontSize: "16px",
                        position: "absolute",
                        bottom: 0,
                        right: "-1rem",
                      }}
                      color="secondary"
                      title="Copy"
                      onClick={() => {
                        copyToClipboard(caption.value)
                      }}
                    >
                      <i class="fa-solid fa-copy"></i>
                    </IconButton>
                  ) : null}
                  {showOtherButtons ? (
                    <Grid item xs={6}>
                      <Button
                        size="small"
                        color="primary"
                        style={{
                          marginLeft: "0.2rem",
                          position: "absolute",
                          bottom: "-1rem",
                          left: "0.1rem",
                        }}
                        onClick={() => {
                          if (!name.includes("test")) {
                            customAxios
                              .post("/ai/demanding-faster", {
                                keyWordsEntered: customTopic,
                                lastGeneratedCaption: caption.suggested,
                                doc_id: imgData.docId,
                                spec: spec ? spec : null,
                              })
                              .then((res) => {
                                // setLiked(true)
                                toast.success(
                                  "Your request has been submitted."
                                )
                              })
                              .catch((err) => {})
                          }
                        }}
                      >
                        Generate captions faster ⚡
                      </Button>
                      {/* <br />
                        Copy and paste this text as your caption before sharing
                        this poster on facebook. */}
                    </Grid>
                  ) : null}
                </Grid>
                {showOtherButtons ? (
                  <Grid item style={{ textAlign: "center" }}>
                    <IconButton
                      color="secondary"
                      disabled={suggestedCaptionList.length < 2}
                      onClick={() => {
                        const idx = suggestedCaptionList.findIndex(
                          (ele) => ele.suggested === caption.suggested
                        )
                        console.log("inde56", idx)
                        if (idx === suggestedCaptionList.length - 1) {
                          if (selectedOption === "freehand") {
                            toast.error(
                              "Please click on another bubble or give a different topic."
                            )
                          } else getSuggestedCaptions(selectedOption)
                        } else setCaption({ ...suggestedCaptionList[idx + 1] })
                      }}
                    >
                      <i class="fas fa-caret-right"></i>
                    </IconButton>

                    <Typography
                      color="secondary"
                      style={{ fontSize: "10px", marginTop: "-0.7rem" }}
                    >
                      Next
                    </Typography>
                  </Grid>
                ) : null}
              </Grid>
            ) : null}
            {/* <Grid item xs={12}>
              <TextField
                minRows={3}
                multiline
                maxRows={5}
                InputProps={{
                  style: {
                    color: "dimgray",
                    // fontFamily: "Forum",
                    fontSize: "16px",
                  },

                  startAdornment:
                    suggestedCaptionList?.length > 1 ? (
                      <IconButton
                        onClick={() => {
                          const idx = suggestedCaptionList.findIndex(
                            (ele) => ele.suggested === caption.suggested
                          )
                          console.log("hellp9", idx)
                          if (idx === 0) {
                            if (selectedOption === "freehand") {
                                toast.error(
                                "Please give a different topic to generate new captions"
                              )
                            } else getSuggestedCaptions(selectedOption)
                          } else
                            setCaption({ ...suggestedCaptionList[idx - 1] })
                        }}
                      >
                        <i class="fas fa-caret-left" s></i>
                      </IconButton>
                    ) : null,
                  endAdornment:
                    suggestedCaptionList.length > 1 ? (
                      <IconButton
                        onClick={() => {
                          const idx = suggestedCaptionList.findIndex(
                            (ele) => ele.suggested === caption.suggested
                          )

                          if (idx === suggestedCaptionList.length - 1) {
                            if (selectedOption === "freehand") {
                              toast.error(
                                "Please look at all generated captions using the arrow keys "
                              )
                            } else getSuggestedCaptions(selectedOption)
                          } else
                            setCaption({ ...suggestedCaptionList[idx + 1] })
                        }}
                      >
                        <i class="fas fa-caret-right"></i>
                      </IconButton>
                      ) : null,
                    }}
                    value={caption.value}
                    onChange={(e) => {
                      setCaption({ ...caption, value: e.target.value })
                    }}
                    variant="standard"
                    fullWidth
                    ></TextField>
                  </Grid> */}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          item
          xs={12}
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          {/* <Grid item>
            <Typography variant="h6" color="secondary">
              Looking good? 🙌
            </Typography>
          </Grid> */}
          <Grid item>
            <FormControlLabel
              label="Share with caption"
              style={{ marginBottom: "0" }}
              control={
                <Checkbox
                  color="primary"
                  checked={shareWithCaption}
                  onChange={(e) => setShareWithCaption(e.target.checked)}
                />
              }
            ></FormControlLabel>
          </Grid>
          <Grid item></Grid>

          {/* <Grid item>
            <Button
              size="large"
              variant="outlined"
              style={{ border: "1px solid #00A87B", color: "#00A87B" }}
            >
              Share
            </Button>
          </Grid> */}
          <Grid item>
            <FacebookShareButton
              quote={caption.value}
              id="facebookShare"
              windowHeight={300}
              style={{ display: "none" }}
              windowWidth={400}
              windowPosition={"top"}
              hashtag={
                "#WhatsAppClinic #DigitalClinic  #WrittenWithAI #Medicos #India"
              }
              url={imgData.url}
            ></FacebookShareButton>
            <Button
              color="primary"
              title="Share this poster on your facebook page "
              onClick={() => {
                copyToClipboard(caption.value, false)
                const element = document.getElementById("facebookShare")
                toast.info(
                  "Text Copied. You can paste it as your facebook caption  "
                )
                setTimeout(() => {
                  element.click()
                }, 2000)
                shareData({
                  url: imgData?.url,
                  docId: imgData?.docId,
                  caption: caption.value,
                })
                mixpanel.track("Marketing Poster Facebook Share", {
                  data: imgData,
                })
              }}
              startIcon={<i class="fa-brands fa-facebook"></i>}
            >
              Share on Facebook
            </Button>
          </Grid>
          <Grid item>
            <Button
              title="Share with all your patients on WhatsApp"
              // variant="outlined"
              style={{ color: "green" }}
              color="primary"
              onClick={() => {
                if (shareWithCaption && !caption.value) {
                  toast.error("Please click on a new word to generate captions")
                } else if (!shareWithCaption) {
                  handleBroadcastImage({
                    url: imgData?.url,
                    docId: imgData?.docId,
                  })
                } else {
                  handleBroadcastImage({
                    url: imgData?.url,
                    docId: imgData?.docId,
                    caption: caption.value,
                  })
                }
              }}
              startIcon={
                <img
                  src={whatsappLogo}
                  style={{
                    width: "22px",
                    objectFit: "contain",
                  }}
                />
              }
              // startIcon={<i class="fa-solid fa-bullhorn"></i>}
            >
              Whatsapp Broadcast
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default CaptionGeneratorDialog
