/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useRef, useState } from "react"
// react plugin used to create charts
// reactstrap components
import { Col, Row } from "reactstrap"

// core components
import Cardimg from "../../../components/Dashboard/Marketing/Cardimg"
// import customAxios from "../../../axios"
import customAxios from "../../../axios"
import comingSoon from "./../../../assets/animationData/comingSoon.json"
import { useDispatch, useSelector } from "react-redux"
import {
  clearAppointmentDetails,
  clearMedicalHistory,
  clearPrescriptionDetails,
  clearVitals,
} from "../../../redux/actions/consultation.actions"
import GenericAnimation from "../../../components/animation"
// import Img3 from 'assets/noimg.png'
import mixpanel from "mixpanel-browser"
import { Typography, Grid, Chip, Button } from "@material-ui/core"
import Card from "reactstrap/lib/Card"

import FileUploadModal from "../../../components/Common/FileUploadModal"
import { toast } from "react-toastify"
import MarketingImageCard from "../../../components/Dashboard/Marketing/Cardimg"
import CaptionGeneratorDialog from "../../../components/CaptionGeneratorDialog"
import newTag from "./../../../assets/animationData/newTag.json"
import LoaderFullpage from "../../../components/Common/LoaderFullpage"
import celebrationAnimation from "./../../../assets/animationData/celebration.json"
export function Marketing() {
  const docId = useSelector((state) =>
    state?.auth?.isDoctor ? state.user.user._id : state.user.user.admin
  )

  const [state, setState] = useState([])
  const [open, setOpen] = useState(false)
  const [successAnimationLoading, setSuccessAnimationLoading] = useState(false)

  const [loading, setLoading] = useState(false)
  const [marketingArray, setmarketingArray] = useState([])
  const dispatch = useDispatch()
  const handleSubmit = () => {
    if (state.length === 0) toast.error("Please upload at least 1 image")
    else {
      setLoading(true)
      customAxios
        .put(`/marketing/add-poster?doc_id=${docId}`, { documents: [...state] })
        .then((res) => {
          if (res.data.success) {
            setLoading(false)
            setOpen(false)
            mixpanel.track("Poster Upload", { data: res?.data?.data })
            toast.success("Images uploaded successfully.")
          } else {
            setLoading(false)
            toast.error("Some error occured")
          }
        })
        .catch((err) => {
          setLoading(false)

          toast.error("Some error occured")
        })
    }
  }
  const onBroadcastSuccess = () => {
    setSuccessAnimationLoading(true)
    setTimeout(() => {
      setSuccessAnimationLoading(false)
    }, 3000)
  }
  useEffect(() => {
    mixpanel.track("Marketing Page Visited")
    dispatch(clearPrescriptionDetails())
    dispatch(clearMedicalHistory())
    dispatch(clearVitals())
    dispatch(clearAppointmentDetails())
    customAxios
      .get("marketing/fetch")
      .then((res) => {
        console.log(res.data.data)
        const reverse = res.data.data.reverse()
        setmarketingArray([...reverse])
        console.log(res.data.data)
      })
      .catch((error) => console.log(error))
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {successAnimationLoading ? (
        <LoaderFullpage
          enableSound={true}
          loading={successAnimationLoading}
          message="Yayie! One step closer to your digital clinic brand"
          animationData={{
            animationData: celebrationAnimation,
            height: 600,
            width: 600,
            onComplete: () => {
              setSuccessAnimationLoading(false)
            },
          }}
        />
      ) : null}
      <Grid
        container
        item
        xs={12}
        style={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          borderRadius: "4px",
          padding: "1rem",
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          spacing={2}
          alignItems="center"
          style={{ padding: "1rem 3rem" }}
        >
          <Grid item xs={12} md={6}>
            <Typography variant="h2">Marketing Posters</Typography>
            <Typography variant="body1" color="secondary">
              Personally curated marketing content for your medical practice.
              Share these posters on your facebook page or broadcast them to
              your entire patient database with the click of a button.
            </Typography>
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              direction="row"
              spacing={1}
              style={{
                border: "1px solid lightblue",
                padding: "0.5rem",
                borderRadius: "12px",
                marginTop: "1rem",
                background: "lightblue",
              }}
            >
              <Grid item xs={3} md={1}>
                <GenericAnimation
                  style={{
                    // position: "absolute",
                    top: "-1.1rem",
                    right: "-0.2rem",
                  }}
                  animationData={newTag}
                  width={28}
                  height={28}
                />{" "}
              </Grid>
              <Grid item md={10} xs={9}>
                <Typography style={{ fontWeight: "600", color: "darkblue" }}>
                  Create content for Facebook, without spending time and money.
                  <br />
                </Typography>
                <Typography style={{ fontWeight: "600", color: "grey" }}>
                  Powered by AI, built with ❤️ from Team Paperplane{" "}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item>
            <Button
              color="primary"
              fullWidth
              // variant="contained"
              size="large"
              onClick={() => {
                setState([])
                setOpen(true)
              }}
              startIcon={<i className="fas fa-plus "></i>}
            >
              Add Posters
            </Button>
          </Grid> */}
        </Grid>
        <Grid container item xs={12} style={{ padding: "1rem" }}>
          {marketingArray?.length > 0
            ? marketingArray.map((ele, idx) => (
                <Grid
                  item
                  md={4}
                  xs={12}
                  container
                  style={{ padding: "1.5rem" }}
                >
                  <MarketingImageCard
                    data={ele}
                    key={idx}
                    onBroadcastSuccess={onBroadcastSuccess}
                  ></MarketingImageCard>
                </Grid>
              ))
            : null}
        </Grid>
        {marketingArray.length === 0 ? (
          <Grid item xs={12} className="text-center">
            <GenericAnimation
              animationData={comingSoon}
              height={500}
              width={500}
            ></GenericAnimation>
          </Grid>
        ) : null}
      </Grid>

      {open ? (
        <FileUploadModal
          onSubmit={handleSubmit}
          addFilesButtonText="Add images"
          open={open}
          maxWidth="md"
          loading={loading}
          fullWidth={true}
          setOpen={setOpen}
          state={state}
          setState={setState}
        />
      ) : null}
    </>
  )
}

export default Marketing
