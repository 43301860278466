import React, { useEffect, useState } from "react"

// react plugin used to create charts
// reactstrap components
import {
	Container,
	Row,
	Col,
	CardBody,
	Card,
	Button,
	Form,
	FormGroup,
	Input,
	Table,
	Media,
	Badge,
	UncontrolledDropdown,
	DropdownMenu,
	DropdownItem,
	DropdownToggle,
} from "reactstrap"

import Header from "../../../components/User/Header"
import ViewPrescripionArrow from "./ViewPrescriptionArrow.svg"

import deleteImage from "./Delete.png"
import TagsInput from "react-tagsinput"
import "./patient.styles.css"
import axios from "axios"
import moment from "moment"
import { useParams } from "react-router"
import customAxios from "../../../axios"
import { useDispatch } from "react-redux"
import {
	clearAppointmentDetails,
	clearMedicalHistory,
	clearPrescriptionDetails,
	clearVitals,
} from "../../../redux/actions/consultation.actions"

export default function PatientProfile() {
	const dispatch = useDispatch()
	const { id } = useParams()
	const [datadd] = useState("")
	const [prescriptions, setPrescriptions] = useState([{}])
	const [activeTab, setActiveTab] = useState("prescription")
	const [patientData, setPatientData] = useState()
	const [userDetails, setUserDetails] = useState()

	useEffect(() => {
		customAxios
			.get(`/appointments/day/all?patient_id=${id}&type=all&status=all`)
			.then(res => {
				console.log(res.data.data)
				setPatientData(res.data.data)
			})

		customAxios.get(`/patients?patient_id=${id}`).then(res => {
			console.log(res.data.data, "HOOOO")
			setUserDetails(res.data.data)
		})
	}, [id])
	useEffect(() => {
		dispatch(clearPrescriptionDetails())
		dispatch(clearMedicalHistory())
		dispatch(clearVitals())
		dispatch(clearAppointmentDetails())
		// eslint-disable-next-line
	}, [])
	const renderBottom = () => {
		if (activeTab === "prescription") {
			return (
				<React.Fragment>
					<Row style={{ marginTop: "50px" }}>
						<Col xl="4">
							<React.Fragment>
								<div className="ssp-card-title" style={{ padding: "0.25rem" }}>
									Prescribed Tests
								</div>
								<div className="pt-2">
									<Form>
										<TagsInput
											onlyUnique
											className="bootstrap-tagsinput"
											onChange={handleTagsInputTest}
											value={tagsinputTest}
											tagProps={{
												className: "tag badge badge-danger mr-1",
											}}
											inputProps={{
												className: "",
												placeholder: "Add more",
											}}
											style={{ padding: "0px" }}
										/>
									</Form>
								</div>
							</React.Fragment>
						</Col>
						<Col xl="4"></Col>
						<Col xl="4">
							<React.Fragment>
								<div className="ssp-card-title" style={{ padding: "0.25rem" }}>
									Symptoms
								</div>
								<div className="pt-2">
									<Form>
										<TagsInput
											onlyUnique
											className="bootstrap-tagsinput"
											onChange={handleTagsInputSymptoms}
											value={tagsinputSymptoms}
											tagProps={{
												className: "tag badge badge-danger mr-1",
											}}
											inputProps={{
												className: "",
												placeholder: "Add more",
											}}
											style={{ padding: "0px" }}
										/>
									</Form>
								</div>
							</React.Fragment>
						</Col>
					</Row>
					<Row>
						<Col xl="12">
							<Form id="prescription-form" style={{ margin: "0.25rem" }}>
								<Row>
									<Col md="12" className="prescription-form-heading">
										Prescription :
									</Col>
								</Row>
								{prescriptions.map((p, index) => (
									<Row key={index}>
										<Col md="3">
											<FormGroup>
												<Input
													id="example3cols1Input"
													placeholder="Name"
													type="text"
													onChange={e => {
														const value = e.target.value
														setPrescriptions(p => {
															let copy = Array.from(p)
															copy[index].name = value
															return copy
														})
													}}
												/>
											</FormGroup>
										</Col>
										<Col md="3">
											<FormGroup>
												<Input
													id="example3cols2Input"
													placeholder="Quantity"
													type="text"
													onChange={e => {
														const value = e.target.value
														setPrescriptions(p => {
															let copy = Array.from(p)
															copy[index].quantity = value
															return copy
														})
													}}
												/>
											</FormGroup>
										</Col>
										<Col md="4">
											<FormGroup>
												<Input
													//value={prescriptions[index].instruction}
													id={`Instruction${index}`}
													placeholder="Instruction"
													type="text"
													onChange={e => {
														const value = e.target.value
														setPrescriptions(p => {
															let copy = Array.from(p)
															copy[index].instructions = value
															return copy
														})
													}}
												/>
											</FormGroup>
										</Col>
										<Col md="2">
											<img
												src={deleteImage}
												onClick={() => {
													setPrescriptions(p => {
														return p
															.slice(0, index)
															.concat(p.slice(index + 1, p.length))
													})
												}}
												alt="prescription"
												style={{ marginTop: "10px" }}
											/>
										</Col>
									</Row>
								))}
								<Button
									color="primary"
									onClick={() => {
										setPrescriptions(p => [...p, {}])
									}}
									style={{ borderRadius: "20px" }}>
									+
								</Button>
								<div className="text-success">{datadd}</div>

								<div style={{ textAlign: "left", marginTop: "50px" }}>
									<span>
										<Button
											color="primary"
											type="submit"
											style={{ borderRadius: "14px" }}
											onClick={e => {
												e.preventDefault()
												handleSubmit()
											}}>
											Submit
										</Button>
									</span>
								</div>
							</Form>
						</Col>
					</Row>
				</React.Fragment>
			)
		}
		return (
			<Row style={{ marginTop: "50px" }}>
				<Col xl="12">
					<>
						<Table className="align-items-center" responsive>
							<thead className="thead-light">
								<tr>
									<th scope="col">Date</th>
									<th scope="col">Time</th>
									<th scope="col">Status</th>
									<th scope="col">Appointment Details</th>
									<th scope="col"></th>
									<th scope="col" />
								</tr>
							</thead>
							<tbody>
								{patientData ? (
									patientData.map((data, index) => (
										<tr key={index}>
											<th scope="row">
												<Media className="align-items-center">
													<Media>
														<span className="mb-0 text-sm">
															{moment.utc(data.date).format("DD-MM-YYYY")}
														</span>
													</Media>
												</Media>
											</th>
											<td>{moment.utc(data.date).format("hh")}</td>
											<td>
												{data.payment_status ? (
													<Badge color="" className="badge-dot">
														<i className="bg-success" />
														completed
													</Badge>
												) : (
													<Badge color="" className="badge-dot mr-4">
														<i className="bg-warning" />
														pending
													</Badge>
												)}
											</td>
											<td>
												<div className="avatar-group">{data.problems}</div>
											</td>
											<td>
												<Row>
													<Col xs="9" className="view-prescription-description">
														View Prescription
													</Col>
													<Col xs="3">
														<img src={ViewPrescripionArrow} alt="icon1" />
													</Col>
												</Row>
											</td>
											<td className="text-right">
												<UncontrolledDropdown>
													<DropdownToggle
														className="btn-icon-only text-light"
														href=""
														role="button"
														size="sm"
														color=""
														onClick={e => e.preventDefault()}>
														<i className="fas fa-ellipsis-v" />
													</DropdownToggle>
													<DropdownMenu className="dropdown-menu-arrow" right>
														<DropdownItem
															href=""
															onClick={e => e.preventDefault()}>
															Action
														</DropdownItem>
														<DropdownItem
															href=""
															onClick={e => e.preventDefault()}>
															Another action
														</DropdownItem>
														<DropdownItem
															href=""
															onClick={e => e.preventDefault()}>
															Something else here
														</DropdownItem>
													</DropdownMenu>
												</UncontrolledDropdown>
											</td>
										</tr>
									))
								) : (
									<div style={{ textAlign: "center" }}>
										<h1>No data</h1>
									</div>
								)}
							</tbody>
						</Table>
					</>
				</Col>
			</Row>
		)
	}

	const handleSubmit = () => {
		console.log(prescriptions)

		let dataToSend = {}
		dataToSend.symptoms = tagsinputSymptoms
		dataToSend.tests = tagsinputTest
		dataToSend.medicines = prescriptions
		const appointmentId = patientData[0]._id
		axios
			.post(
				`/doctor/complete-prescription?appointment_id=${appointmentId}`,
				dataToSend
			)
			.then(res => {
				console.log(res)
			})
			.catch(err => {
				console.log(err)
			})

		console.log(dataToSend)
	}

	const [tagsinputTest, setTagsinputTest] = useState(["cough", "fever"])
	const [tagsinputSymptoms, setTagsinputSymptoms] = useState(["cough", "fever"])

	const handleTagsInputTest = ip => {
		setTagsinputTest(ip)
	}

	const handleTagsInputSymptoms = ip => {
		setTagsinputSymptoms(ip)
	}
	return (
		<div>
			<>
				<Header parentName="Dashboard" />
				<Container className="mt--6" fluid>
					<Row>
						<Col xl="12">
							<Card>
								<CardBody>
									<Row className="align-items-center">
										<Col xs="5">
											<Row>
												<Col xs="3">
													<a
														className="avatar avatar-xl rounded-circle"
														href=""
														onClick={e => e.preventDefault()}>
														<img
															alt="..."
															src={require("../../../assets/img/theme/team-2.jpg")}
														/>
													</a>
												</Col>
												<Col
													xs="9"
													style={{
														textAlign: "left",
													}}>
													<div>
														<text className="patient-page-name">
															{userDetails ? userDetails.name : "Loading..."}
														</text>
													</div>
													<div>
														<text className="patient-page-right-description">
															{userDetails ? userDetails.age : "Loading..."} |{" "}
															{userDetails ? userDetails.gender : "..."}|
															15-06-2021
														</text>
													</div>
												</Col>
											</Row>
											<Row>
												<Col xs="3" style={{ textAlign: "left" }}>
													<div>
														{" "}
														<text className="patient-page-left-description">
															Phone
														</text>
													</div>
												</Col>
												<Col xs="9" style={{ textAlign: "left" }}>
													<text className="patient-page-right-description"></text>
													{userDetails ? userDetails.phone : "..."}
												</Col>
											</Row>
											<hr style={{ margin: "0px", width: "90%" }} />
											<Row>
												<Col xs="3" style={{ textAlign: "left" }}>
													<div>
														{" "}
														<text className="patient-page-left-description">
															Email
														</text>
													</div>
												</Col>
												<Col xs="9" style={{ textAlign: "left" }}>
													<text className="patient-page-right-description"></text>
													abcxyz@gmail.com
												</Col>
											</Row>
											<hr style={{ margin: "0px", width: "90%" }} />
											<Row>
												<Col xs="3" style={{ textAlign: "left" }}>
													<div>
														{" "}
														<text className="patient-page-left-description">
															Address
														</text>
													</div>
												</Col>
												<Col xs="9" style={{ textAlign: "left" }}>
													<text className="patient-page-right-description"></text>
													New Delhi
												</Col>
											</Row>
										</Col>

										<Col xs="2">
											<div>
												<Button
													color="primary"
													outline
													type="button"
													style={{ marginTop: "-50%", borderRadius: "14px" }}
													onClick={() => {
														if (patientData) {
															window.open(patientData[0].link)
														}
													}}>
													Join Meet
												</Button>
											</div>
										</Col>

										<Col
											xs="5"
											style={{
												display: "flex",
												justifyContent: "flex-end",
												alignItems: "flex-start",
												flexDirection: "row",
												height: "100%",
												flex: "1",
											}}>
											<div
												className="patient-tab-wrapper"
												style={{
													alignSelf: "flex-start",
													marginTop: "-10%",
												}}>
												<button
													className={`
                      patient-tab-wrapper-item-prescription  
                        ${activeTab === "prescription" ? "active-tab" : ""}
                    `}
													onClick={() => {
														setActiveTab("prescription")
													}}>
													Prescription
												</button>
												<button
													className={`
                    patient-tab-wrapper-item-history  
                      ${activeTab === "history" ? "active-tab" : ""}
                  `}
													onClick={() => {
														setActiveTab("history")
													}}>
													History
												</button>
											</div>
										</Col>
									</Row>
									<hr style={{ margin: "0px" }} />
									{renderBottom()}
								</CardBody>
							</Card>
						</Col>
					</Row>

					<Row>
						<Col xl="6"></Col>
					</Row>
					<Row>
						<Col xl="6"></Col>
					</Row>
					<Row>
						<Col xl="12"></Col>
					</Row>
				</Container>
			</>
		</div>
	)
}
