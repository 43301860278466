import { Backdrop, CircularProgress, makeStyles, Grid } from "@material-ui/core"
import AWS from "aws-sdk"

import React, { useEffect, useState } from "react"
import { useParams, useHistory, useLocation } from "react-router"
import customAxios from "../../../axios"
import Sidebar from "../../../components/Consultation(new)"
import CreatePrescription from "../../../components/CreatePrescription"
import { toast } from "react-toastify"
import moment from "moment"
import MedicalRecord from "../../../components/Consultation(new)/MedicalRecord"
import PatientAssessment from "../../../components/Consultation(new)/PatientAssessment"
import AppointmentHistory from "../../../components/Consultation(new)/AppointmentHistory"
import { useSelector } from "react-redux"
import EndConsultation from "../../../components/Consultation(new)/EndConsultation"
import mixpanel from "mixpanel-browser"
import LoaderFullpage from "../../../components/Common/LoaderFullpage"
import successAnimation from "./../../../assets/animationData/success.json"
import celebrationAnimation from "./../../../assets/animationData/celebration.json"
import _ from "lodash"
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}))
function Consultation() {
  const routeHistory = useHistory()
  const location = useLocation()
  console.log("9809", location)
  const classes = useStyles()
  const { id } = useParams()
  const preferences = useSelector((state) => state?.user.preferences)
  const [loading, setLoading] = useState(false)
  const [historyColumnFreeze, setHistoryColumnFreeze] = useState(false)

  const [appointmentData, setAppointmentData] = useState()
  const [previousState, setPreviousState] = useState(null)
  const [loadingEndPrescription, setLoadingEndPrescription] = useState(false)
  const [state, setState] = useState({
    saveTemplate: false,
    medicines: [],
    diagnosis: [],
    symptoms: [],
    tests: [],
    vitals: [],
    exercises: [],
  })
  const [activeStep, setActiveStep] = useState("Write Prescription")
  console.log("docid2", appointmentData)
  const requiredKeys = [
    // { key: "diagnosis", label: "Diagnosis" },
    // { key: "symptoms", label: "Symptoms" },
  ]
  const getActiveStep = (activeStep) => {
    switch (activeStep) {
      case "Write Prescription":
        return (
          <CreatePrescription
            state={state}
            previousState={previousState}
            historyColumnFreeze={historyColumnFreeze}
            setHistoryColumnFreeze={setHistoryColumnFreeze}
            docId={appointmentData?.docId}
            patientData={appointmentData?.patient}
            appointmentData={appointmentData}
            setLoading={setLoading}
            handleEndConsultation={handleEndConsultation}
            loading={loading}
            requiredKeys={requiredKeys}
            setState={setState}
            setActiveStep={setActiveStep}
            preferences={preferences}
          />
        )
      case "Medical Record":
        return (
          <MedicalRecord
            patientData={appointmentData?.patient}
            setLoading={setLoading}
            loading={loading}
          />
        )
      case "Patient Assessment":
        return (
          <PatientAssessment
            patientData={appointmentData?.patient}
            setLoading={setLoading}
            loading={loading}
          />
        )
      case "Appointment History":
        return (
          <AppointmentHistory
            patientData={appointmentData?.patient}
            setLoading={setLoading}
            loading={loading}
          />
        )

      default:
        ;<h1>Error</h1>
    }
  }
  useEffect(() => {
    if (appointmentData)
      customAxios
        .get(
          `/appointments/get-last-appointment/${appointmentData.patient._id}`
        )
        .then((res) => {
          if (res.data.success) {
            customAxios
              .get(
                `/consultation/filter?appointment_id=${res.data.data.appointmentId}&is_template=false`
              )
              .then((res) => {
                if (res.data.success) {
                  if (res.data.data?.length) {
                    const template = res?.data?.data[0]
                    setPreviousState((prev) => ({
                      ...prev,
                      ...template,
                      ...template?.prescription,
                      patientNotes: template?.patientNotes
                        ? template?.patientNotes
                        : "",
                      medicines: template?.prescription?.medicines
                        ? template?.prescription?.medicines
                        : [],
                      diagnosis: template?.prescription?.diagnosis
                        ? template?.prescription?.diagnosis
                        : [],
                      symptoms: template?.prescription?.symptoms
                        ? template?.prescription?.symptoms
                        : [],
                      tests: template?.prescription?.tests
                        ? template?.prescription?.tests?.map((ele) => ({
                            name: ele,
                          }))
                        : [],
                    }))
                  }
                } else {
                  console.log("err")
                }
              })
              .catch((err) => console.log(err))
          } else {
            console.log("err")
          }
        })
        .catch((err) => console.log("err"))
  }, [appointmentData])

  useEffect(() => {
    setLoading(true)
    //call fecth appointment api
    customAxios
      .get(`/appointments/${id}`)
      .then((res) => {
        if (res) {
          if (res.data.data.completed) {
            customAxios
              .get(
                `/consultation/filter?appointment_id=${res.data.data._id}&is_template=false`
              )
              .then((res) => {
                if (res.data.success) {
                  if (res.data.data?.length) {
                    const template = res?.data?.data[0]
                    console.log("345", res.data.data)
                    setState((prev) => ({
                      ...prev,
                      ...template?.prescription,
                      medicines: template?.prescription?.medicines
                        ? template?.prescription?.medicines
                        : [],
                      diagnosis: template?.prescription?.diagnosis
                        ? template?.prescription?.diagnosis
                        : [],
                      symptoms: template?.prescription?.symptoms
                        ? template?.prescription?.symptoms
                        : [],
                      tests: template?.prescription?.tests
                        ? template?.prescription?.tests?.map((ele) => ({
                            name: ele,
                          }))
                        : [],
                    }))
                  } else {
                    toast.warning("No previous prescription found")
                    setState({
                      saveTemplate: false,
                      medicines: [],
                      diagnosis: [],
                      symptoms: [],
                      tests: [],
                      vitals: [],
                      exercises: [],
                    })
                  }
                }
              })
          }
          setAppointmentData(res.data.data)
          customAxios
            .get(
              `/vital/${res.data.data?.patient?._id}?doc_id=${appointmentData?.docId}`
            )
            .then((res) => {
              if (res.data.success) {
                if (res.data.data) {
                  const temp = res.data.data.vitals
                  temp.reverse()
                  setState((prev) => ({
                    ...prev,
                    vitals: _.cloneDeep(temp?.[0].data),
                  }))
                  setPreviousState((prev) => ({
                    ...prev,
                    vitals: _.cloneDeep(temp?.[0].data),
                  }))
                }
              }
            })
            .catch((err) => console.log(err))
        }
        setLoading(false)
      })
      .catch((err) => {
        console.log("Error", err)
        setLoading(false)
      })
  }, [id])
  const handleDownloadPrescription = (
    data,
    download = false,
    print = false
  ) => {
    var a = document.createElement("a")
    document.body.appendChild(a)
    a.style = "display: none"
    AWS.config.update({
      accessKeyId: "AKIAUSXZZ7HZALZ7XCS2",
      secretAccessKey: "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",
    })
    var s3 = new AWS.S3()
    let params = {
      Bucket: "paperplane-s3",
      Key: `${data._id}`,
    }
    s3.getObject(params, (err, res) => {
      let blob = new Blob([res.Body], { type: res.ContentType })
      if (download) {
        a.href = window.URL.createObjectURL(blob)
        a.download = `${appointmentData?.patient?.name}_(#${appointmentData?.patient?.code})_PrescriptionPreview`
        a.click()
        window.URL.revokeObjectURL(data?.url)
      }
      if (print) {
        let pdfw = window.open(window.URL.createObjectURL(blob), "_blank")
        // you need to call print after window loads like this
        if (pdfw)
          pdfw.onload = function () {
            pdfw.print()
          }
        else {
          window.alert("Please allow popups")
        }
      }
      setLoading(false)
    })
  }
  const handleEndConsultation = (config) => {
    const tests = state?.tests?.map((v) => v.name)
    setLoading(true)
    let error = {}
    if (
      config?.additionalPhoneNumber &&
      config?.additionalPhoneNumber?.toString().length !== 10
    )
      error = {
        check: true,
        message: "The addtional phone number must be of 10 digits",
      }

    if (!appointmentData?.patient)
      error = { check: true, message: "Patient not found" }

    requiredKeys.map((v) => {
      if (!state[v.key] || !state[v.key].length) {
        error = { check: true, message: `${v.label} is required` }
      }
    })
    if (error?.check) {
      setLoading(false)

      toast.error(error.message)
    } else {
      const addOnData = {}
      if (config?.additionalPhoneNumber) {
        addOnData["additionalNos"] = [config?.additionalPhoneNumber]
      }

      if (state?.vitals?.length) {
        const objectToSend = {}
        const objInViatls = {
          date: moment.now(),
          data: [...state["vitals"]],
        }
        objectToSend.vitals = []
        objectToSend.vitals.push(objInViatls)
        customAxios
          .put(
            `/vital/${appointmentData?.patient["_id"]}?doc_id=${appointmentData?.docId}`,
            objectToSend
          )
          .then((response) => {
            customAxios
              .put(`consultation/generate-prescription/${id}`, {
                ...state,
                newPrescription: appointmentData.completed ? false : true,
                ...config,
                ...addOnData,
                tests: tests,
              })
              .then((res) => {
                if (res.data?.success) {
                  if (!config?.onlyPrescription) {
                    mixpanel.track("Appointment Completed")
                    mixpanel.people.increment("Appointment Completed")

                    setLoading(false)
                    mixpanel.track("Prescription Sent")
                    setLoadingEndPrescription(true)
                    setTimeout(() => {
                      setLoadingEndPrescription(false)

                      routeHistory.push("/admin/dashboard")
                    }, 3000)
                  } else {
                    handleDownloadPrescription(
                      res.data.data,
                      config.download,
                      config.print
                    )
                  }
                } else {
                  setLoading(false)
                  toast.error("Some error occured")
                }
              })
          })
          .catch((err) => {
            console.log(err)
            setLoading(false)
          })
      } else
        customAxios
          .put(`consultation/generate-prescription/${id}`, {
            ...state,
            newPrescription: appointmentData.completed ? false : true,
            ...config,
            ...addOnData,
            tests: tests,
          })
          .then((res) => {
            if (res.data?.success) {
              if (!config?.onlyPrescription) {
                setLoading(false)
                mixpanel.track("Appointment Completed")
                mixpanel.people.increment("Appointment Completed")
                mixpanel.track("Prescription Sent")
                mixpanel.people.increment("Prescription Sent")
                setLoadingEndPrescription(true)
                setTimeout(() => {
                  setLoadingEndPrescription(false)

                  routeHistory.push("/admin/dashboard")
                }, 3000)
              } else {
                handleDownloadPrescription(
                  res.data.data,
                  config.download,
                  config.print
                )
              }
            } else {
              setLoading(false)
              toast.error("Some error occured")
            }
          })
          .catch((err) => {
            setLoading(false)

            console.log(err)
          })
    }
  }

  return (
    <>
      <LoaderFullpage />
      {loadingEndPrescription ? (
        <LoaderFullpage
          enableSound={true}
          loading={loadingEndPrescription}
          message="Consultation ended successfully. Prescription sent on WhatsApp"
          animationData={{
            animationData: celebrationAnimation,
            height: 600,
            width: 600,
          }}
        />
      ) : null}

      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
      <div style={{ padding: "1rem" }}>
        <Grid container>
          <Grid item xs={12} md={3}>
            <Sidebar
              setLoading={setLoading}
              appointmentData={appointmentData}
              patientData={appointmentData?.patient}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              handleEndConsultation={handleEndConsultation}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <div
              style={{
                height: "90vh",
                overflowY: "auto",

                padding: "0rem 1.5rem",
              }}
            >
              {getActiveStep(activeStep)}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default Consultation
