/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col
} from "reactstrap";
import { useHistory } from "react-router-dom";


export default function Header(props) {
  

    return (
      <>
        <div className="header pb-6" style={{background: "linear-gradient(128.8deg, #4D63DD 28.61%, #22A1F5 99.07%)"}}>
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6" xs="7">
                  {/* <h6 className="h2 text-white d-inline-block mb-0">
                    {props.name}
                  </h6>{" "} */}
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-md-4"
                    listClassName="breadcrumb-links breadcrumb-dark"
                  >
                    <BreadcrumbItem>
                      <a href="#" onClick={e => window.dispatchEvent(new CustomEvent("history_push", {"detail": "/admin/broadcast"}))}>
                        <i className="fas fa-home" />
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <a href="#" onClick={e => props?.onParentClick ? props.onParentClick(e) : e.preventDefault()}>
                        {props.parentName}
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem aria-current="page" className="active">
                      {props.name}
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>

              
            </div>
          </Container>
        </div>
      </>
    );
  }

