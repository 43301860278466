import AWS from "aws-sdk"

export function validateEmail(mail) {
  const mailFormat =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  if (mail.match(mailFormat)) return true
  return false
}
export function validatePhone(tel) {
  const phoneFormat = /^\d{10}$/
  if (tel.match(phoneFormat)) return true
  return false
}

export const getBase64FromUrl = async (url) => {
  const data = await fetch(url, { method: "GET" })
  const blob = await data.blob()
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => {
      const base64data = reader.result
      resolve(base64data)
    }
  })
}
export function getRandomColor() {
  var letters = "0123456789ABCDEF"
  var color = "#"
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}
export function createElementFromHTML(htmlString) {
  var div = document.createElement("div")
  div.innerHTML = htmlString.trim()

  // Change this to div.childNodes to support multiple top-level nodes.
  console.log("935", div)
  return div
}
