export const symptomsValuesForSearch = [
  "Itching",
  "Skin rash",
  "Nodal skin eruptions",
  "Continuous sneezing",
  "Shivering",
  "Chills",
  "Joint pain",
  "Stomach pain",
  "Acidity",
  "Ulcers on tongue",
  "Muscle wasting",
  "Vomiting",
  "Burning micturition",
  "Spotting  urination",
  "Fatigue",
  "Weight gain",
  "Anxiety",
  "Cold",
  "Cold hands and feets",
  "Mood swings",
  "Weight loss",
  "Restlessness",
  "Lethargy",
  "Patches in throat",
  "Irregular sugar level",
  "Cough",
  "High fever",
  "Sunken eyes",
  "Breathlessness",
  "Sweating",
  "Dehydration",
  "Indigestion",
  "Headache",
  "Yellowish skin",
  "Dark urine",
  "Nausea",
  "Loss of appetite",
  "Pain behind the eyes",
  "Back pain",
  "Constipation",
  "Abdominal pain",
  "Diarrhoea",
  "Mild fever",
  "Yellow urine",
  "Yellowing of eyes",
  "Acute liver failure",
  "Fluid overload",
  "Swelling of stomach",
  "Swelled lymph nodes",
  "Malaise",
  "Blurred and distorted vision",
  "Phlegm",
  "Throat irritation",
  "Redness of eyes",
  "Sinus pressure",
  "Runny nose",
  "Congestion",
  "Chest pain",
  "Weakness in limbs",
  "Fast heart rate",
  "Pain during bowel movements",
  "Pain in anal region",
  "Bloody stool",
  "Irritation in anus",
  "Neck pain",
  "Dizziness",
  "Cramps",
  "Bruising",
  "Obesity",
  "Swollen legs",
  "Swollen blood vessels",
  "Puffy face and eyes",
  "Enlarged thyroid",
  "Brittle nails",
  "Swollen extremeties",
  "Excessive hunger",
  "Extra marital contacts",
  "Drying and tingling lips",
  "Slurred speech",
  "Knee pain",
  "Hip joint pain",
  "Muscle weakness",
  "Stiff neck",
  "Swelling joints",
  "Movement stiffness",
  "Spinning movements",
  "Loss of balance",
  "Unsteadiness",
  "Weakness of one body side",
  "Loss of smell",
  "Bladder discomfort",
  "Foul smell of urine",
  "Continuous feel of urine",
  "Passage of gases",
  "Internal itching",
  "Toxic look (typhos)",
  "Depression",
  "Irritability",
  "Muscle pain",
  "Altered sensorium",
  "Red spots over body",
  "Belly pain",
  "Abnormal menstruation",
  "Dischromic  patches",
  "Watering from eyes",
  "Increased appetite",
  "Polyuria",
  "Family history",
  "Mucoid sputum",
  "Rusty sputum",
  "Lack of concentration",
  "Visual disturbances",
  "Receiving blood transfusion",
  "Receiving unsterile injections",
  "Coma",
  "Stomach bleeding",
  "Distention of abdomen",
  "History of alcohol consumption",
  "Blood in sputum",
  "Prominent veins on calf",
  "Palpitations",
  "Painful walking",
  "Pus filled pimples",
  "Blackheads",
  "Scurring",
  "Skin peeling",
  "Silver like dusting",
  "Small dents in nails",
  "Inflammatory nails",
  "Blister",
  "Red sore around nose",
  "Yellow crust ooze",
  "Mobility limitation",
  "Syncope, vasovagal",
  "Skin manifestations",
  "Striae distensae",
  "Intellectual disability",
  "Unconsciousness",
  "Mutism",
  "Psychophysiologic disorders",
  "Albuminuria",
  "Sciatica",
  "Heartburn",
  "Machado-joseph disease",
  "Edema, cardiac",
  "Akathisia, drug-induced",
  "Mevalonate kinase deficiency",
  "Sleep apnea, central",
  "Hypothermia",
  "Muscle rigidity",
  "Korsakoff syndrome",
  "Huntington disease",
  "Brain death",
  "Gait apraxia",
  "Anisocoria",
  "Photophobia",
  "Body temperature changes",
  "Rem sleep behavior disorder",
  "Slit ventricle syndrome",
  "Hypercalciuria",
  "Orthostatic intolerance",
  "Neuralgia, postherpetic",
  "Toothache",
  "Mucopolysaccharidosis ii",
  "Morning sickness",
  "Flushing",
  "Purpura, thrombocytopenic, idiopathic",
  "Speech disorders",
  "Hearing loss, sudden",
  "Sleep-wake transition disorders",
  "Hot flashes",
  "Hyperkinesis",
  "Cerebrospinal fluid rhinorrhea",
  "Waterhouse-friderichsen syndrome",
  "Tetany",
  "Signs and symptoms, respiratory",
  "Hyperlactatemia",
  "Dyslexia, acquired",
  "Snoring",
  "Purpura, schoenlein-henoch",
  "Virilism",
  "Voice disorders",
  "Angina, unstable",
  "Livedo reticularis",
  "Color vision defects",
  "Anhedonia",
  "Fetal hypoxia",
  "Sleep deprivation",
  "Motion sickness",
  "Pallor",
  "Fever of unknown origin",
  "Halitosis",
  "Chorea gravidarum",
  "Blindness",
  "Muscle hypertonia",
  "Hypertriglyceridemic waist",
  "Hearing loss",
  "Sleep disorders",
  "Encopresis",
  "Amnesia, transient global",
  "Aging, premature",
  "Renal colic",
  "Blindness, cortical",
  "Pseudophakia",
  "Dyslexia",
  "Aphonia",
  "Kearns-sayre syndrome",
  "Trismus",
  "Hypergammaglobulinemia",
  "Facial pain",
  "Cardiac output, high",
  "Failure to thrive",
  "Persistent vegetative state",
  "Vision disorders",
  "Gingival hemorrhage",
  "Myoclonus",
  "Signs and symptoms, digestive",
  "Insomnia, fatal familial",
  "Musculoskeletal pain",
  "Somnambulism",
  "Neurologic manifestations",
  "Purpura fulminans",
  "Cyanosis",
  "Ophthalmoplegia",
  "Coffin-lowry syndrome",
  "Hoarseness",
  "Amblyopia",
  "Pruritus vulvae",
  "Amnesia, retrograde",
  "Anoxia",
  "Tonic pupil",
  "Hypercapnia",
  "Torticollis",
  "Aphasia",
  "Gagging",
  "Pudendal neuralgia",
  "Alcohol amnestic disorder",
  "Paresis",
  "Narcolepsy",
  "Sweating sickness",
  "Feminization",
  "Apraxia, ideomotor",
  "Insulin coma",
  "Hypocapnia",
  "Muscle cramp",
  "Disorders of excessive somnolence",
  "Colic",
  "Oral manifestations",
  "Aphasia, primary progressive",
  "Paralysis",
  "Cerebellar ataxia",
  "Deaf-blind disorders",
  "Glossalgia",
  "Schnitzler syndrome",
  "Menkes kinky hair syndrome",
  "Hearing loss, conductive",
  "Heart murmurs",
  "Illusions",
  "Tinea pedis",
  "Horner syndrome",
  "Sensation disorders",
  "Hematemesis",
  "Purpura, thrombocytopenic",
  "Hearing loss, high-frequency",
  "Lesch-nyhan syndrome",
  "Fetal macrosomia",
  "Agraphia",
  "Syncope",
  "Catalepsy",
  "Hypoventilation",
  "Hyperoxia",
  "Hydrops fetalis",
  "Labor pain",
  "Athetosis",
  "Decerebrate state",
  "Memory disorders",
  "Body weight changes",
  "Pyruvate dehydrogenase complex deficiency disease",
  "Tics",
  "Reticulocytosis",
  "Hemianopsia",
  "Flatulence",
  "Adrenoleukodystrophy",
  "Apnea",
  "Nocturnal paroxysmal dystonia",
  "Stuttering",
  "Pain, postoperative",
  "Vision, low",
  "Amnesia, anterograde",
  "Susac syndrome",
  "Myotonia",
  "Fever",
  "Facial paralysis",
  "Sleep disorders, circadian rhythm",
  "Eye pain",
  "Dyskinesias",
  "Alkalosis, respiratory",
  "Optical illusions",
  "Vertigo",
  "Pseudobulbar palsy",
  "Pediatric obesity",
  "Psychomotor agitation",
  "Communication disorders",
  "Causalgia",
  "Proteinuria",
  "Primary progressive nonfluent aphasia",
  "Aphasia, broca",
  "Neurobehavioral manifestations",
  "Birth weight",
  "Hemoglobinuria",
  "Urinoma",
  "Muscular atrophy",
  "Obesity, abdominal",
  "Vocal cord paralysis",
  "Meningism",
  "Urological manifestations",
  "Language disorders",
  "Muscle hypotonia",
  "Cerebrospinal fluid leak",
  "Obesity hypoventilation syndrome",
  "Rem sleep parasomnias",
  "Hiccup",
  "Polydipsia, psychogenic",
  "Cerebrospinal fluid otorrhea",
  "Dyspnea",
  "Hyperalgesia",
  "Vitreous hemorrhage",
  "Nocturia",
  "Hypersomnolence, idiopathic",
  "Oliguria",
  "Space motion sickness",
  "Paresthesia",
  "Paraparesis",
  "Infantile apparent life-threatening event",
  "Failed back surgery syndrome",
  "Opsoclonus-myoclonus syndrome",
  "Scotoma",
  "Prader-willi syndrome",
  "Tinnitus",
  "Somatosensory disorders",
  "Respiratory paralysis",
  "Williams syndrome",
  "Fasciculation",
  "Angina, stable",
  "Lipoid proteinosis of urbach and wiethe",
  "Dysuria",
  "Postoperative nausea and vomiting",
  "Sleep apnea, obstructive",
  "Thinness",
  "Post-exercise hypotension",
  "Nociceptive pain",
  "Restless legs syndrome",
  "Hemifacial spasm",
  "Emaciation",
  "Anorexia",
  "Hearing loss, noise-induced",
  "Miosis",
  "Hyperphagia",
  "Rett syndrome",
  "Mental fatigue",
  "Adie syndrome",
  "Delirium",
  "Cheyne-stokes respiration",
  "Diarrhea",
  "De lange syndrome",
  "Breakthrough pain",
  "Intermittent claudication",
  "Dyscalculia",
  "Neurogenic inflammation",
  "Dysphonia",
  "Pain, intractable",
  "Cataplexy",
  "Abdomen, acute",
  "Hypokinesia",
  "Hirsutism",
  "Phantom limb",
  "Seizures",
  "Hearing loss, bilateral",
  "Mouth breathing",
  "Postural orthostatic tachycardia syndrome",
  "Alien hand syndrome",
  "Consciousness disorders",
  "Dysmenorrhea",
  "Diplopia",
  "Pelvic pain",
  "Flank pain",
  "Oral hemorrhage",
  "Bulimia",
  "Ataxia",
  "Aphasia, wernicke",
  "Retinal hemorrhage",
  "Anomia",
  "Eye manifestations",
  "Coma, post-head injury",
  "Hyperacusis",
  "Reflex, abnormal",
  "Night blindness",
  "Visceral pain",
  "Urinary incontinence, urge",
  "Cachexia",
  "Pain threshold",
  "Gastroparesis",
  "Rubinstein-taybi syndrome",
  "Catatonia",
  "Purpura",
  "Urinary bladder, overactive",
  "Hearing loss, mixed conductive-sensorineural",
  "Taste disorders",
  "Arthralgia",
  "Night terrors",
  "Pupil disorders",
  "Cafe-au-lait spots",
  "Synkinesis",
  "Prosopagnosia",
  "Respiratory sounds",
  "Olfaction disorders",
  "Neuromuscular manifestations",
  "Ataxia telangiectasia",
  "Sarcopenia",
  "Low back pain",
  "Hallucinations",
  "Eye hemorrhage",
  "Articulation disorders",
  "Dysarthria",
  "Ophthalmoplegic migraine",
  "Pelvic girdle pain",
  "Echolalia",
  "Alcohol withdrawal seizures",
  "Necrolytic migratory erythema",
  "Amnesia",
  "Obesity, morbid",
  "Eructation",
  "Parasomnias",
  "Myalgia",
  "Pain",
  "Deafness",
  "Ideal body weight",
  "Hyperemesis gravidarum",
  "Auditory perceptual disorders",
  "Tachypnea",
  "Body weight",
  "Dyssomnias",
  "Glycogen storage disease type iib",
  "Gait ataxia",
  "Earache",
  "Microvascular angina",
  "Supranuclear palsy, progressive",
  "Sleep apnea syndromes",
  "Hearing loss, unilateral",
  "Jet lag syndrome",
  "Hemoptysis",
  "Pain, referred",
  "Sleep disorders, intrinsic",
  "Presbycusis",
  "Mydriasis",
  "Fragile x syndrome",
  "Angina pectoris, variant",
  "Prostatism",
  "Usher syndromes",
  "Stupor",
  "Kleine-levin syndrome",
  "Ecchymosis",
  "Fetal distress",
  "Sleep paralysis",
  "Cardiac output, low",
  "Agnosia",
  "Gerstmann syndrome",
  "Hearing loss, functional",
  "Alice in wonderland syndrome",
  "Neuroacanthocytosis",
  "Quadriplegia",
  "Aerophagy",
  "Monoclonal gammopathy of undetermined significance",
  "Shoulder pain",
  "Hyperventilation",
  "Urinary bladder, neurogenic",
  "Pruritus ani",
  "Dysgeusia",
  "Learning disorders",
  "Urinary incontinence, stress",
  "Angina pectoris",
  "Acute pain",
  "Hearing disorders",
  "Amaurosis fugax",
  "Sleep arousal disorders",
  "Dyspnea, paroxysmal",
  "Wolfram syndrome",
  "Allesthesia",
  "Psychomotor disorders",
  "Chorea",
  "Paraparesis, spastic",
  "Choroid hemorrhage",
  "Hearing loss, central",
  "Purpura, hyperglobulinemic",
  "Sneezing",
  "Down syndrome",
  "Asthenia",
  "Acute coronary syndrome",
  "Systolic murmurs",
  "Pruritus",
  "Alexia, pure",
  "Gait disorders, neurologic",
  "Sleep initiation and maintenance disorders",
  "Fetal weight",
  "Wagr syndrome",
  "Apraxias",
  "Hyphema",
  "Cri-du-chat syndrome",
  "Hemiplegia",
  "Mental retardation, x-linked",
  "Classical lissencephalies and subcortical band heterotopias",
  "Lower urinary tract symptoms",
  "Hyperesthesia",
  "Vomiting, anticipatory",
  "Metatarsalgia",
  "Spasm",
  "Transient tachypnea of the newborn",
  "Dystonia",
  "Edema",
  "Chronic pain",
  "Hypesthesia",
  "Hearing loss, sensorineural",
  "Brown-sequard syndrome",
  "Diarrhea, infantile",
  "Overweight",
  "Neuralgia",
  "Sleep bruxism",
  "Ageusia",
  "Benign paroxysmal positional vertigo",
  "Muscle spasticity",
  "Dyspepsia",
  "Confusion",
  "Hypotension, orthostatic",
  "Jaundice, obstructive",
  "Nocturnal myoclonus syndrome",
  "Aphasia, conduction",
  "Polydipsia",
  "Urinary incontinence",
  "Dyskinesia, drug-induced",
  "Jaundice",
  "Myokymia",
  "Mastodynia",
  "Piriformis muscle syndrome",
  "Perceptual disorders",
  "Tremor",
  "Purpura, thrombotic thrombocytopenic",
  "Prodromal symptoms",
  "Ophthalmoplegia, chronic progressive external",
  "Paraplegia",
  "Spinocerebellar ataxias",
  "Language development disorders",
];
export const diseasesValueForSearch = [
  "Malaria",
  "AIDS",
  "Viral infection",
  "Viral fever",
  "Common cold",
  "COVID-19",
  "Migraine",
  "Hypertensive disease",
  "Diabetes",
  "Depression mental",
  "Depressive disorder",
  "Coronary arteriosclerosis",
  "Coronary heart disease",
  "Pneumonia",
  "Failure heart congestive",
  "Accident cerebrovascular",
  "Asthma",
  "Myocardial infarction",
  "Hypercholesterolemia",
  "Infection",
  "Infection urinary tract",
  "Anemia",
  "Chronic obstructive airway disease",
  "Dementia",
  "Insufficiency renal",
  "Confusion",
  "Degenerative polyarthritis",
  "Hypothyroidism",
  "Anxiety state",
  "Malignant neoplasms",
  "Primary malignant neoplasm",
  "Acquired immuno-deficiency syndrome",
  "HIV",
  "HIV infections",
  "Cellulitis",
  "Gastroesophageal reflux disease",
  "Septicemia",
  "Systemic infection",
  "Sepsis (invertebrate)",
  "Deep vein thrombosis",
  "Dehydration",
  "Neoplasm",
  "Embolism pulmonary",
  "Epilepsy",
  "Cardiomyopathy",
  "Chronic kidney failure",
  "Carcinoma",
  "Hepatitis c",
  "Peripheral vascular disease",
  "Psychotic disorder",
  "Hyperlipidemia",
  "Bipolar disorder",
  "Obesity",
  "Ischemia",
  "Cirrhosis",
  "Exanthema",
  "Benign prostatic hypertrophy",
  "Kidney failure acute",
  "Mitral valve insufficiency",
  "Arthritis",
  "Bronchitis",
  "Hemiparesis",
  "Osteoporosis",
  "Transient ischemic attack",
  "Adenocarcinoma",
  "Paranoia",
  "Pancreatitis",
  "Incontinence",
  "Paroxysmal dyspnea",
  "Hernia",
  "Malignant neoplasm of prostate",
  "Carcinoma prostate",
  "Edema pulmonary",
  "Lymphatic diseases",
  "Stenosis aortic valve",
  "Malignant neoplasm of breast",
  "Carcinoma breast",
  "Schizophrenia",
  "Diverticulitis",
  "Overload fluid",
  "Ulcer peptic",
  "Osteomyelitis",
  "Gastritis",
  "Bacteremia",
  "Failure kidney",
  "Sickle cell anemia",
  "Failure heart",
  "Upper respiratory infection",
  "Hepatitis",
  "Hypertension pulmonary",
  "Deglutition disorder",
  "Gout",
  "Thrombocytopaenia",
  "Hypoglycemia",
  "Pneumonia aspiration",
  "Colitis",
  "Diverticulosis",
  "Suicide attempt",
  "Pneumocystis carinii pneumonia",
  "Hepatitis b",
  "Parkinson disease",
  "Lymphoma",
  "Hyperglycemia",
  "Encephalopathy",
  "Tricuspid valve insufficiency",
  "Alzheimers disease",
  "Candidiasis",
  "Oral candidiasis",
  "Neuropathy",
  "Kidney disease",
  "Fibroid tumor",
  "Glaucoma",
  "Neoplasm metastasis",
  "Malignant tumor of colon",
  "Carcinoma colon",
  "Ketoacidosis diabetic",
  "Tonic-clonic epilepsy",
  "Tonic-clonic seizures",
  "Respiratory failure",
  "Melanoma",
  "Gastroenteritis",
  "Malignant neoplasm of lung",
  "Carcinoma of lung",
  "Manic disorder",
  "Personality disorder",
  "Primary carcinoma of the liver cells",
  "Emphysema pulmonary",
  "Hemorrhoids",
  "Spasm bronchial",
  "Aphasia",
  "Obesity morbid",
  "Pyelonephritis",
  "Endocarditis",
  "Effusion pericardial",
  "Pericardial effusion body substance",
  "Chronic alcoholic intoxication",
  "Pneumothorax",
  "Delirium",
  "Neutropenia",
  "Hyperbilirubinemia",
  "Influenza",
  "Dependence",
  "Thrombus",
  "Cholecystitis",
  "Hernia hiatal",
  "Migraine disorders",
  "Pancytopenia",
  "Cholelithiasis",
  "Biliary calculus",
  "Tachycardia sinus",
  "Ileus",
  "Adhesion",
  "Delusion",
  "Affect labile",
  "Decubitus ulcer",
  "Bile duct cancer",
  "Crohns disease",
  "Pancreatic cancer",
  "Polycystic ovary syndrome",
  "Nicotine dependence",
  "Age related macular degeneration",
  "Multiple sclerosis",
  "Primary biliary cirrhosis",
  "Idiopathic pulmonary fibrosis",
  "Middle ear cancer",
  "Atopic dermatitis",
  "Hypertension",
  "Prostate cancer",
  "Peritoneum cancer",
  "Abdominal aortic aneurysm",
  "Epilepsy syndrome",
  "Breast cancer",
  "Appendix cancer",
  "Celiac disease",
  "Atherosclerosis",
  "Cervical cancer",
  "Pagets disease of bone",
  "Ureter cancer",
  "Head and neck cancer",
  "Membranous glomerulonephritis",
  "Alopecia areata",
  "Esophageal cancer",
  "Creutzfeldt-jakob disease",
  "Spinal cancer",
  "Nasal cavity cancer",
  "Nephrolithiasis",
  "Iga glomerulonephritis",
  "Vascular cancer",
  "Behcets disease",
  "Fuchs endothelial dystrophy",
  "Bone cancer",
  "Salivary gland cancer",
  "Skin cancer",
  "Penile cancer",
  "Endogenous depression",
  "Gallbladder cancer",
  "Malignant glioma",
  "Thyroid cancer",
  "Retroperitoneal cancer",
  "Liver cancer",
  "Uterine fibroid",
  "Pleural cancer",
  "Testicular cancer",
  "Stomach cancer",
  "Barretts esophagus",
  "Lung cancer",
  "Pharynx cancer",
  "Meningioma",
  "Sarcoma",
  "Vulva cancer",
  "Hematologic cancer",
  "Cleft lip",
  "Alcohol dependence",
  "Fallopian tube cancer",
  "Ankylosing spondylitis",
  "Peripheral nervous system neoplasm",
  "Gilles de la tourette syndrome",
  "Kidney cancer",
  "Mediastinal cancer",
  "Psoriasis",
  "Gestational diabetes",
  "Parkinsons disease",
  "Narcolepsy",
  "Panic disorder",
  "Coronary artery disease",
  "Graves disease",
  "Ulcerative colitis",
  "Metabolic syndrome x",
  "Germ cell cancer",
  "Azoospermia",
  "Allergic rhinitis",
  "Conduct disorder",
  "Dilated cardiomyopathy",
  "Amyotrophic lateral sclerosis",
  "Tracheal cancer",
  "Ocular cancer",
  "Refractive error",
  "Systemic lupus erythematosus",
  "Focal segmental glomerulosclerosis",
  "Vaginal cancer",
  "Psoriatic arthritis",
  "Rheumatoid arthritis",
  "Larynx cancer",
  "Osteoarthritis",
  "Chronic obstructive pulmonary disease",
  "Intracranial aneurysm",
  "Periodontitis",
  "Muscle cancer",
  "Type 1 diabetes mellitus",
  "Thoracic aortic aneurysm",
  "Ovarian cancer",
  "Jejunal cancer",
  "Leprosy",
  "Duodenum cancer",
  "Lymphatic system cancer",
  "Autistic disorder",
  "Otosclerosis",
  "Systemic scleroderma",
  "Type 2 diabetes mellitus",
  "Ileum cancer",
  "Sclerosing cholangitis",
  "Vitiligo",
  "Dental caries",
  "Kawasaki disease",
  "Urinary bladder cancer",
  "Degenerative disc disease",
  "Attention deficit hyperactivity disorder",
  "Thymus cancer",
  "Uterine cancer",
  "Brain cancer",
  "Malignant mesothelioma",
  "Rectum cancer",
  "Colon cancer",
  "Adrenal gland cancer",
  "Acquired immunodeficiency syndrome",
  "Restless legs syndrome",
];
export const medicalTestsForSearch = [
  "ACTH (Adrenocorticotropic Hormone) Test",
  "Adrenocorticotropic Hormone",
  "Ammonia Blood Level Test",
  "Antiglobulin Tests",
  "AST (Aspartate Aminotransferase) Test",
  "Angiography, Fluorescein",
  "Antibody test for hepatitis A",
  "Arterial Blood Gases (ABG)",
  "Aldosterone in Blood",
  "Anterior cruciate ligament (ACL) surgery",
  "Antibody Tests -- Antibody Tests",
  "Autopsy",
  "ABO Blood Typing",
  "Arm X-Ray",
  "Acoustic Reflex Test",
  "Audiometry",
  "Auditory Brainstem Evoked Potential (ABEP) Test",
  "Antibody Tests -- Immunoglobulins",
  "Abdominal MRI",
  "ACTH Suppression Test",
  "Activated Partial Thromboplastin Time",
  "APTT",
  "Albumin Test, Blood",
  "AFP (Alpha-Fetoprotein) Test",
  "Alpha-Fetoprotein (AFP) in Blood",
  "Amniocentesis",
  "Amniocentesis for Rh sensitization during pregnancy",
  "Abdominal Ultrasound",
  "Abdominal X-ray",
  "Alanine Aminotransferase (ALT)",
  "ALT (Alanine Aminotransferase)",
  "Alpha-Amylase Test",
  "Amylase Plasma Level",
  "Aspartate Aminotransferase (AST) Test",
  "Air Contrast Study",
  "Anoscopy",
  "Ambulatory Electrocardiogram",
  "Angiogram",
  "Arteriography",
  "Ankle-brachial index test",
  "AIDS Test",
  "Aldosterone in Urine",
  "Antisperm Antibody Test",
  "Angiogram of the Lung",
  "Alcohol, Breath Test",
  "Alkaline Phosphatase",
  "Antinuclear Antibodies (ANA) Test",
  "Arthrogram (Joint X-ray)",
  "Arthroscopy",
  "Back X-Ray",
  "Bacterial Vaginosis Tests",
  "Barium Enema",
  "Barium Swallow",
  "Bernstein Test",
  "Beta-Natriuretic Peptide",
  "Bicarbonate (HCO3) Test",
  "Bilirubin Test",
  "Biophysical Profile (BPP)",
  "Biopsy, Bone",
  "Biopsy, Bone Marrow",
  "Biopsy, Breast",
  "Biopsy, Endometrium",
  "Biopsy, Liver",
  "Biopsy, Lung",
  "Biopsy, Lymph Node",
  "Biopsy, Testicular",
  "Biopsy, Thyroid",
  "Biospy, Prostate",
  "Bladder stress test and Bonney test for urinary incontinence in women",
  "Blood Alcohol Concentration",
  "Blood Ca (Calcium) Level",
  "Blood Carbon Dioxide Level",
  "Blood Carbon Monoxide (CO) Level",
  "Blood Cortisol Levels",
  "Blood Culture",
  "Blood Lead Level",
  "Blood Magnesium (Mg) Test",
  "Blood Osmolality Test",
  "Blood Phosphate (PO4) Test",
  "Blood Phosphate Level",
  "Blood Potassium (K) Level",
  "Blood Protein Test",
  "Blood Sodium (Na) Level",
  "Blood Type Test",
  "Blood Urea Nitrogen (BUN) Test",
  "Blood Uric Acid Level",
  "BNP (Brain Natriuretic Peptide) Test",
  "Body Temperature",
  "Bone Biopsy",
  "Bone Marrow Aspiration and Biopsy",
  "Bone Mineral Density",
  "Bone Scan",
  "Bowel resection for colorectal cancer",
  "Bowel Transit Time",
  "Brain Natriuretic Peptide (BNP) Test",
  "BRCA (Breast Cancer) Gene Test",
  "Breast Biopsy",
  "Breast Cancer (BRCA) Gene Test",
  "Breast enlargement",
  "Breast Examination, Clinical",
  "Breast implants",
  "Breast MRI",
  "Breast reduction",
  "Breast Self-Examination",
  "Breast Ultrasound",
  "Bronchoscopy",
  "BUN (Blood Urea Nitrogen) Test",
  "BUN:Creatinine Ratio",
  "Bunion surgery",
  "Bypass Surgery for Heart Attack and Unstable Angina",
  "Coombs Test",
  "Cold Agglutinins Test",
  "Chemistry Screen",
  "CBC (Complete Blood Count)",
  "Complete Blood Count (CBC)",
  "C-Reactive Protein (CRP)",
  "CRP (C-reactive Protein)",
  "Cobalamin Test",
  "Cyanocobalamin Test",
  "Cortisol Suppression Test",
  "Culture, Stool",
  "Contraction Stress Test",
  "Chorionic Villus Sampling (CVS)",
  "CVS (Chorionic Villus Sampling)",
  "Cordocentesis",
  "Cervical cerclage to prevent preterm delivery",
  "Chromosome Analysis",
  "Carotid Angiogram",
  "Cerebral Angiogram",
  "Cranial echogram",
  "Cranial sonogram",
  "Cranial Ultrasound",
  "Carcinoembryonic Antigen (CEA) Level",
  "CEA (Carcinoembryonic Antigen) Test",
  "CAT Scan, Body",
  "Computed Tomography (CT) Scan of the Body",
  "CT (Computed Tomography), Body",
  "Cholesterol Health Check",
  "Cholesterol and Triglycerides Tests",
  "Colectomy",
  "Continent ileostomy",
  "Colonoscopy",
  "Colostomy",
  "Color Vision Tests",
  "Corneal ring implants",
  "Cardiac Enzyme Studies",
  "CK (Creatine Kinase)",
  "CPK (Creatine Phosphokinase)",
  "Creatine Kinase (CK)",
  "Creatine Phosphokinase (CPK)",
  "Creatine Phosphokinase-MB Isoenzyme (CPK-MB) Level",
  "Cardiac Perfusion Scan",
  "Cardiac Calcium Scoring",
  "Coronary Artery Calcium Scoring",
  "Coronary Calcium Scan",
  "Cardiac Catheterization",
  "Coronary Angiography",
  "Chest X-ray",
  "Coronary artery bypass surgery",
  "Cardiac Event Monitoring",
  "Coronary artery bypass graft (CABG) surgery",
  "CD4+ Count",
  "CA-125 (Cancer Antigen) Test",
  "Cancer Antigen 125 (CA-125)",
  "CT Myelogram",
  "Cystography",
  "Cystoscopy",
  "Cystourethroscopy",
  "Cryotherapy",
  "Carotid endarterectomy",
  "Cervical spinal fusion",
  "CAT Scan, Spine",
  "Computed Tomography (CT) Scan of the Spine",
  "CT (Computed Tomography), Spine",
  "Cystectomy",
  "Cardiac Blood Pool Scan",
  "Chemical peel",
  "Clinical Breast Examination",
  "Cervical Smear",
  "Cystourethrogram",
  "Closure of the vagina (vaginal obliteration)",
  "Cystometrography",
  "Cystometry",
  "Cryosurgery",
  "Curettage and electrosurgery",
  "C-Peptide",
  "Cystic Fibrosis Sweat Test",
  "CAT Scan, Head and Face",
  "CT (Computed Tomography), Head and Face",
  "Cervical Biopsy and Colposcopy",
  "Cervical Exam",
  "Colposcopy and Cervical Biopsy",
  "Creatinine and Creatinine Clearance Tests",
  "DNA Fingerprinting",
  "Dexamethasone Suppression Test (DST)",
  "DST (Dexamethasone Suppression Test)",
  "Drug Screening Test",
  "Decompressive laminectomy",
  "Discectomy or microdiscectomy",
  "Dix-Hallpike test",
  "Dipyridamole Stress Test",
  "Dobutamine Stress Test",
  "Dopamine, Test",
  "Doppler Echocardiography",
  "Dilation and curettage",
  "Dental Plaque Self-Exam",
  "DEXA Scan",
  "Drug Monitoring",
  "Digital Rectal Examination (DRE)",
  "Dermabrasion",
  "Dilation and evacuation (D&amp;E)",
  "Doppler Ultrasound",
  "Dental X-rays",
  "Dilation and Dharp Curettage (D&C)",
  "Endoscopic sinus exam",
  "Endoscopic surgery",
  "Extremity X-ray",
  "Ear Examination",
  "Electronic Fetal Heart Monitoring",
  "Electromyogram (EMG) and Nerve Conduction Studies",
  "EMG (Electromyography)",
  "Echogram, cranial",
  "Electronystagmogram (ENG)",
  "ENG (Electronystagmography)",
  "Electronystagmogram",
  "Ear Exam, Home",
  "Endoscopic Retrograde Cholangiopancreatogram (ERCP)",
  "ERCP (Endoscopic Retrograde Cholangiopancreatogram",
  "Esophagus Tests",
  "Esophagram",
  "EEG (Electroencephalography)",
  "Electroencephalogram (EEG)",
  "Erection Problems Tests",
  "Excimer laser photorefractive keratectomy",
  "Excimer laser photorefractive keratectomy (PRK)",
  "Endoscopy, Upper Gastrointestinal",
  "Epinephrine, Test",
  "ECG (Electrocardiogram)",
  "EKG (Electrocardiogram)",
  "Electrocardiogram",
  "Echocardiogram",
  "Electrocardiography, Ambulatory",
  "Electrocardiography, Exercise",
  "Exercise Electrocardiogram",
  "Evoked potential test",
  "Ejection Fraction Study",
  "Endometrial ablation",
  "Estradiol Level",
  "Estriol Level",
  "Estrogens Level",
  "Endometrial Biopsy",
  "Expressed prostatic secretions",
  "Endoscopic carpal tunnel surgery",
  "Excision",
  "Eye Angiogram",
  "EBV Antibody Test",
  "Epstein-Barr Antibody Test",
  "ECT (Emission Computed Tomography)",
  "Free Cortisol Test",
  "Fecal Culture",
  "Fecal Analysis",
  "Family Planning, Natural",
  "Fertility Awareness",
  "Fetal Biophysical Profile",
  "Fetal blood sampling (FBS)",
  "Fetal Heart Monitoring",
  "Fetal Development Slideshow",
  "Fetal Ultrasound",
  "Flexible Sigmoidoscopy",
  "Folic Acid",
  "Fecal Occult Blood Test (FOBT)",
  "Fibrinogen Uptake Study",
  "Funduscopy (Eye exam)",
  "Fundoplication surgery",
  "Fallopian tube procedures",
  "Face X-Ray",
  "Facial Radiography",
  "Facial X-ray",
  "Flap procedure",
  "Fungal culture",
  "Fetoscopy",
  "Face-lift (rhytidectomy)",
  "Fasting Blood Sugar Test",
  "Follicle-Stimulating Hormone",
  "FSH (Follicle-Stimulating Hormone) Test",
  "Femoropopliteal bypass (fem-pop bypass)",
  "Fluorescein Angiography",
  "Fluorescein Dye Test",
  "Gamma Globulin Tests",
  "Gastrin Test",
  "Globulin Test",
  "Gastric Ulcer Test",
  "Gallbladder Scan",
  "Goniotomy",
  "Glaucoma Screening Test",
  "Glaucoma Test",
  "Gingivectomy",
  "Gallium Scan",
  "Gynecological exam",
  "Gynecologic Exam",
  "Gynecologic Ultrasound",
  "Glucose Challenge Test",
  "Glucose Tolerance Test",
  "Gated Cardiac Scan",
  "Gonioscopy",
  "GHb (Glycohemoglobin) Test",
  "Glycohemoglobin (GHb)",
  "Galactosemia Test",
  "Gait analysis",
  "Genetic Test",
  "GH (Growth Hormone) Levels",
  "Growth Hormone Levels",
  "Histocompatibility Testing",
  "HLA (Human Leukocyte Antigen) Typing",
  "Human Leukocyte Antigen (HLA) Typing",
  "Hydrocortisone Test",
  "Hearing Tests",
  "Home Test",
  "Hemoglobin S Test",
  "Hemochromatosis Gene Test (HFE)",
  "HFE test",
  "Hemoglobin Electrophoresis",
  "Home Pregnancy Tests",
  "HDL Cholesterol Test",
  "Home Ear Examination",
  "hCG (Human Chorionic Gonadotropin) Test",
  "Human Chorionic Gonadotropin (hCG) Quantitative and Qualitative Blood Tests",
  "H. pylori Test",
  "Helicobacter pylori Tests",
  "Hepatobiliary Scan",
  "HIDA Scan",
  "Herpes Tests",
  "Heart Attack Enzymes",
  "Heart Echocardiogram",
  "Homocysteine Test",
  "Holter Monitoring",
  "Heart transplant",
  "History and physical exam",
  "Heart catheterization",
  "Heart valve repair or replacement",
  "HBV Antibody Tests",
  "Hepatitis B Virus Test",
  "HIV Test",
  "Human Immunodeficiency Virus (HIV) Test",
  "HIV Load Measurement",
  "Home Blood Pressure Test",
  "Hamster Zona-Free Ovum Test",
  "HSG (Hysterosalpingography)",
  "Hysterosalpingogram",
  "Hip replacement surgery",
  "Hysteroscopy",
  "Hair transplantation surgery",
  "Home Lung Function Test",
  "HAV Antibody Test",
  "Hepatitis A Virus Test",
  "Hemorrhoidectomy",
  "Home Blood Glucose Test",
  "HbA1c (Hemoglobin A1c) Blood Test",
  "Hemoglobin A1c",
  "Hysterectomy",
  "Hormone Inhibin-A Test",
  "Hair Analysis",
  "Human Growth Hormone (hGH) Levels",
  "Indirect Coombs Test",
  "Iron Stores (Ferritin Levels)",
  "Immunoglobulins Test",
  "IDET (Intradiscal Electrothermic Therapy)",
  "Intradiscal electrothermic therapy (IDET)",
  "Intracavernosal Injection Test (for Erection Problems)",
  "Intravenous Pyelogram (IVP)",
  "Ileoanal or ileorectal anastomosis",
  "Iodine Uptake Test, Radioactive",
  "Indocyanine Green Test",
  "Joint Scan",
  "Joint Fluid Analysis",
  "Joint X-Ray (Arthrogram)",
  "Jaw X-ray",
  "Kidney Scan",
  "Kidney transplant",
  "Karyotype Test",
  "Kidney Biopsy",
  "KUB X-ray",
  "KOH (potassium hydroxide) preparation",
  "KOH (potassium hydroxide) preparation test",
  "Ketone Test",
  "Knee MRI",
  "Knee replacement surgery",
  "Kidney Stone Analysis",
  "Lactic acid dehydrogenase (LDH) -- Lactic acid dehydrogenase (LDH)",
  "Lactogenic Hormone Level",
  "Lower Gastrointestinal Series",
  "Leg X-Ray",
  "Liver and Spleen Scan",
  "Liver resection",
  "LDL Cholesterol Test",
  "Lipid Profile",
  "Lipoprotein Analysis",
  "Liver Biopsy",
  "Laparoscopy",
  "Laparoscopic inguinal hernia repair",
  "Laparoscopic gallbladder surgery",
  "Lumbar Puncture",
  "Laser in-situ keratomileusis",
  "Laser photocoagulation",
  "Laser in-situ keratomileusis (LASIK)",
  "Laser iridotomy",
  "Laser trabeculoplasty",
  "Lactic Acid Dehydrogenase (LDH) -- Cardiac Enzyme Studies",
  "LDH-1 (Lactate Dehydrogenase Isoenzymes) Test",
  "Liver Function Test",
  "Lung surgery (thoracotomy)",
  "Lymph node removal (lymphadenectomy)",
  "Lung Angiogram",
  "Lactate Test",
  "Lactic Acid",
  "Laser resurfacing",
  "Lyme Disease Test",
  "Loop electrosurgical excision procedure (LEEP)",
  "Laparoscopic ovarian drilling (ovarian diathermy)",
  "Laser surgery",
  "Laparoscopic surgery",
  "Laryngoscopy",
  "Lung Function Tests",
  "Lung transplantation",
  "Lung Function Testing, Home",
  "Lung Biopsy",
  "Lung Scan",
  "Liver tests",
  "Lipase",
  "Lymph Node Biopsy",
  "Lymphadenectomy",
  "LH (Leutinizing Hormone) Test",
  "Luteinizing Hormone (LH) Level",
  "Magnetic Resonance Imaging (MRI)",
  "MRI (Magnetic Resonance Imaging)",
  "Magnetic Resonance Image (MRI) of the Shoulder",
  "MRI of the Shoulder",
  "Magnetic Resonance Image (MRI) of the Abdomen",
  "MRI of the Abdomen",
  "Magnetic Resonance Image (MRI) of the Head",
  "MRI (Magnetic Resonance Imaging), Head",
  "MSAFP (Maternal Serum Alpha-Fetoprotein) Test",
  "Magnetic Resonance Imaging (MRI) of the Spine",
  "MRI (Magnetic Resonance Imaging), Spine",
  "Magnetic Resonance Image (MRI) of the Breast",
  "Metanephrine, Test",
  "Magnetic Resonance Angiogram (MRA)",
  "MRA (Magnetic Resonance Angiography)",
  "Male Fertility Test",
  "Mantoux Test",
  "Mental Health Assessment",
  "Mental Status Evaluation",
  "Myelogram",
  "Medication Monitoring",
  "Meniscectomy",
  "Meniscus repair",
  "Medical history and physical examination",
  "MUGA Scan",
  "Mammogram",
  "Myomectomy",
  "Medical history",
  "Manual and vacuum aspiration",
  "Magnetic Resonance Image (MRI) of the Knee",
  "MRI of the Knee",
  "Mohs micrographic surgery",
  "Medical history and physical exam",
  "Microalbumin Urine Test",
  "Mononucleosis Tests",
  "Monospot Test",
  "Needle puncture and aspiration of sinus contents",
  "Nuclear Magnetic Resonance Imaging",
  "Natural Family Planning",
  "Nuclear Magnetic Resonance of the Spine",
  "Nylen-Barany test",
  "Nerve Conduction Studies",
  "Nocturnal Penile Tumescence Test",
  "Nonexercise stress test",
  "Norepinephrine, Test",
  "Neurological examination",
  "Neck X-Ray",
  "Neurotransplantation",
  "Nose Job",
  "Otoacoustic Emissions (OAE) Test",
  "Osmolality Test",
  "Overnight Dexamethasone Suppression Test",
  "Oxytocin Challenge Test",
  "Obstetric Ultrasound",
  "Open gallbladder surgery",
  "Open inguinal hernia repair (herniorrhaphy, hernioplasty)",
  "Ophthalmoscopy",
  "Orbitral X-Ray",
  "Osteotomy",
  "Open-joint arthroplasty",
  "OGTT (Oral Glucose Tolerance Test)",
  "Oral Glucose Tolerance Test",
  "Orchiopexy",
  "Open carpal tunnel surgery",
  "Open surgery",
  "Orthopedic surgery",
  "Orchiectomy",
  "Parentage Testing",
  "Paternity Test",
  "Pure Tone Audiometry",
  "Parathyroid Hormone test",
  "Parathyroid Hormone (PTH) Level",
  "PTH (Parathyroid Hormone) Test",
  "Partial Thromboplastin Time (PTT)",
  "PTT (Partial Thromboplastin Time)",
  "Pro Time (PT) Test",
  "Prothrombin Time (PT) Test",
  "PT (Prothrombin Time) Test",
  "Percutaneous umbilical cord sampling (PU",
  "Pregnancy Tests",
  "Pregnancy: Fetal Development Slideshow",
  "Pregnancy Ultrasound",
  "Partial colectomy",
  "Proctoscopy",
  "Pregnancy Test",
  "Penile implants",
  "PRK (Excimer laser photorefractive kerat",
  "Pneumatic retinopexy",
  "Perimetry test (visual field testing) fo",
  "Plantar fascia release",
  "Postmortem Examination",
  "Pericardial Tap",
  "Pericardiocentesis",
  "Pulse",
  "Pulse Measurement",
  "Pacemaker placement",
  "Pulmonary Angiogram",
  "Plasma Renin Activity (PRA) Test",
  "PRA Test",
  "Postcoital Test",
  "PO4 (Phosphate), Urine Test",
  "Physical exam and history",
  "Phalangeal head resection (arthroplasty)",
  "Pallidotomy (posteroventral pallidotomy)",
  "Potassium (K) in Urine Test",
  "Pelvic Ultrasound",
  "Prostate Ultrasound",
  "Paracentesis",
  "Peritoneal Tap",
  "Pelvic exam",
  "Physical examination of the knee",
  "Physical exam",
  "Parathyroid and Thyroid Ultrasound",
  "Pelvic Examination",
  "Prostate-Specific Antigen (PSA) Test",
  "PSA (Prostate-Specific Antigen) Test",
  "Prostate Biopsy",
  "Pulmonary Function Tests",
  "Pleural Tap",
  "Proctocolectomy and ileostomy",
  "Physical examination",
  "Phenylketonuria (PKU) Test",
  "PKU (Phenylketonuria) Screening",
  "PET (Positron Emission Tomography) Scan",
  "Positron Emission Tomography",
  "Quick Strep Test",
  "Rh Blood Typing",
  "Rinne Test",
  "Renal Scan",
  "Reticulocyte Count",
  "Reticulocyte Index (RI) Test",
  "RI Test",
  "Rubella Test",
  "Renal Biopsy",
  "Radical inguinal orchiectomy",
  "Rigidity Test (for Erection Problems)",
  "Radial keratotomy (RK)",
  "Renin Assay",
  "Root canal treatment",
  "Rotator cuff repair",
  "Rectal Exam",
  "Radiofrequency palatoplasty",
  "Repair of the rectum (rectocele) or small bowel (enterocele)",
  "Repair of the bladder or urethra",
  "Retropubic suspension",
  "Radionuclide Angiography",
  "Rhinoplasty",
  "Rheumatoid Factor (RF)",
  "Random Blood Sugar Test",
  "Restrictive operations (stomach stapling or gastric banding)",
  "Radioactive Iodine Uptake Test",
  "Radioactive Thyroid Scan",
  "Radical prostatectomy",
  "Rapid Strep Test",
  "Retrograde pyelogram",
  "Serum Glutamate-pyruvate Transaminase (SGPT)",
  "SGPT (Serum Glutamate-pyruvate Transaminase)",
  "Serum Glutamic Oxaloacetic Transaminase (SGOT)",
  "SGOT (Serum Glutamic Oxaloacetic Transaminase)",
  "Serum Creatinine Level",
  "Serum Ca (Calcium) Level",
  "Serum Calcium (Ca) Level",
  "Serum Ferritin Levels",
  "Schwabach Test",
  "Serum Iron (Fe) T",
  "Serum PO4 (Phosphate)",
  "Serum Osmolality Level",
  "Sickle Cell Test",
  "Schilling Test",
  "Serum Chloride (Cl) Level",
  "Shoulder MRI",
  "Stool Culture",
  "Serum Protein Electrophoresis (SPE)",
  "Serum Protein Test",
  "Sedimentation Rate (Sed Rate)",
  "Sonogram, Fetal",
  "Spinal MRI (Magnetic Resonance Imaging)",
  "Spinal fusion (arthrodesis)",
  "Spinal fusion",
  "Sonogram, cranial",
  "Spleen and Liver Scan",
  "String test",
  "Stomach Ulcer Test",
  "Stool Antigen Test",
  "Scan, Gallbladder",
  "Spinal Tap",
  "Stamp Test (for Erection Problems)",
  "Scleral buckling surgery",
  "Slit lamp exam",
  "Subacromial smoothing and acromioplasty",
  "Sestamibi Scan",
  "Serum Catecholamines Levels",
  "Stress Test",
  "Serum Sodium Level",
  "Sperm Penetration Tests",
  "Semen Analysis",
  "Sperm Count",
  "Surgical excision of melanoma",
  "Skin and Wound Cultures",
  "Skull X-ray",
  "Synovectomy",
  "Sterilization, Female",
  "Surgical removal of genital warts by excision",
  "Syphilis Tests",
  "Surgical nail removal",
  "Summer Skin Hazards",
  "Sleep Studies",
  "Spinal CT",
  "Spinal X-ray",
  "Scan, Cardiac Blood Pool",
  "Shoulder replacement surgery",
  "Stool analysis",
  "Serum Glucose Level",
  "Self Breast Exam (SBE)",
  "Self-Exam, Vagina",
  "Serum Hexosaminidase A and B Test",
  "Surgery",
  "Sinus X-Ray",
  "Scrotal Scan",
  "Skin Biopsy",
  "Sputum Cytology",
  "Sputum Culture",
  "Slit Lamp Examination",
  "Sigmoidoscopy (Anoscopy, Proctoscopy)",
  "Sweat Test",
  "Somatotropin",
  "Traditional sinus surgery",
  "Tissue Type Test",
  "Tuning",
  "Thick and thin blood smears",
  "Total Testosterone Levels",
  "Total Serum Protein Test",
  "Toxicology Tests",
  "Total Cholesterol Test",
  "Triglycerides and Cholesterol Tests",
  "Trabeculectomy (filtration surgery)",
  "Trabeculotomy",
  "Tonometry",
  "Temperature Measurement",
  "Technetium Scan",
  "Thallium Scan",
  "Transesophageal Echocardiography",
  "Treadmill Test",
  "T-Lymphocyte Measurement",
  "TB Skin Test",
  "Tuberculin Skin Tests",
  "Tooth extraction",
  "Total Body Scan",
  "Therapeutic Drug Monitoring",
  "Thalamotomy",
  "Tension-free vaginal tape",
  "Tests",
  "Transrectal Ultrasound",
  "Transvaginal Ultrasound",
  "Thyroid-Stimulating Hormone (TSH)",
  "Thyrotropin Test",
  "TSH (Thyroid-Stimulating Hormone) Test",
  "Transurethral resection (TUR)",
  "T3 (Triiodothyronine) Test",
  "T4 (Thyroxine) Test",
  "Thyroid Hormone Tests",
  "Thyroxine (T4) Test",
  "Triiodothyronine (T3) Test",
  "Technetium Scan of the Thyroid",
  "Thyroid Scan",
  "Thyroid Biopsy",
  "Tay-Sachs Test",
  "Tonsillectomy",
  "Throat Culture",
  "Testicular Scan",
  "Testicular Ultrasound",
  "Testicular Examination and Testicular Self-Examination (TSE)",
  "Transurethral prostatectomy",
  "Thoracentesis",
  "Tibioperoneal bypass surgery",
  "Tube-shunt surgery",
  "Tympanocentesis",
  "Tympanometry in ear infections (otitis media)",
  "Tubes",
  "Testicular Biopsy",
  "Thyroid surgery",
  "Thyroid and Parathyroid Ultrasound",
  "Teeth X-ray",
  "Urea Nitrogen, Blood (BUN)",
  "Urinary Tract Infection Home Test",
  "Urinary Uric Acid Level",
  "Urinary Calcium (Ca) Level",
  "Ultrasound, Abdominal",
  "Urinary Sodium (Na) Level",
  "Urinary Cortisol Level",
  "Urinalysis",
  "Urine Culture",
  "Ultrasound, Fetal",
  "Ultrasound, Obstetric",
  "Ultrasound, Pregnancy",
  "Ultrasound, cranial",
  "Urea Breath Test",
  "Upper Gastrointestinal (UGI) Series",
  "Upper Gastrointestinal Endoscopy",
  "Urinary Catecholamines Levels",
  "Urinary Phosphate",
  "Urine PO4 (Phosphate) Test",
  "Uvulopalatopharyngoplasty",
  "Urodynamic tests",
  "Urethral sling",
  "Urethral bulking",
  "Urinary Potassium Level",
  "Ultrasound, Gynelcologic",
  "Ultrasound, Prostate",
  "Uterosalpingography",
  "Ultrasound, Breast",
  "Ultrasound, Pelvic",
  "Uroflowmetry",
  "Ultrasound of Testes",
  "Ultrasound, Doppler",
  "Urine Test",
  "Ultrasound, Parathyroid",
  "Utrasound, Thyroid",
  "Vitamin B12 Absorption Test",
  "Vitamin B12 Test",
  "Vaginal exam",
  "Vein Scan",
  "Venogram",
  "Vision Tests",
  "Vitrectomy",
  "Vanillylmandelic Acid, Test",
  "Viral Test",
  "Viral Load Assay",
  "Viral Load Measurement",
  "Vasectomy reversal (vasovasostomy)",
  "Varicocele repair",
  "Vasectomy",
  "Vein ligation and stripping",
  "Vaginal Wet Mount",
  "Vaginal Self Exam",
  "Vaginal Self-Examination (VSE)",
  "VSE (Vaginal Self-Examination)",
  "Vulvar Self-Exam",
  "Visual examination",
  "Ventilation and Perfusion Scan",
  "Whispered Speech Test",
  "Wound and Skin Cultures",
  "Wisdom tooth extraction",
  "Wall Motion Study",
  "Wet Mount, Vaginal",
  "X-Ray, Arm",
  "X-Ray, Leg",
  "X-Ray, Chest",
  "X-ray, Abdominal",
  "X-Ray, Face",
  "X-Ray, Orbits",
  "X-Ray, Sinus",
  "X-Ray, Skull",
  "X-Ray, Back",
  "X-Ray, Neck",
  "X-Ray, Spinal",
  "X-Ray, Breast (Mammography)",
  "X-ray, Dental",
];
