import {
  USER_LOADED,
  USER_LOADING,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  REMOVE_AUTHENTICATION_STATUS,
  ONBOARDING_SUCCESS,
  START_LOADING,
  END_LOADING,
} from "../constants/index.js"

const initialState = {
  access_token: localStorage.getItem("token"),
  isAuthenticated: null,
  isLoading: false,
  user: null,
  auth_error_msg: "",
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
      }
    case REMOVE_AUTHENTICATION_STATUS:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false,
      }
    case REGISTER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        newUser: true,
        isAuthenticated: true,
        isLoading: false,
        isSubscribe: "active",
      }
    case ONBOARDING_SUCCESS:
      return {
        ...state,
        ...action.payload,
        newUser: false,
        isAuthenticated: true,
        isLoading: false,
      }
    case START_LOADING:
      return { ...state, isLoading: true }
    case END_LOADING:
      return { ...state, isLoading: false }
    case LOGIN_FAIL:
      localStorage.removeItem("token")
      return {
        ...state,
        auth_error_msg: action.payload,
        access_token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false,
      }

    case LOGOUT_SUCCESS:
      return initialState

    case REGISTER_FAIL:
      localStorage.removeItem("token")
      return {
        ...state,
        access_token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false,
      }
    default:
      return state
  }
}
