import {
  Grid,
  Typography,
  Button,
  TextField,
  Divider,
  Avatar,
  styled,
  withWidth,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { colors } from "../../../assets/css/colors"
import dashboardSocket from "../../../socket/dashboardSocket"
import { getRandomColor } from "../../helper"
import moment from "moment"
import ChatWindow from "../../../components/ChatDashboard/ChatWindow"
import "./index.css"
import ChatSidebar from "../../../components/ChatDashboard/ChatSidebar"
import ChatDefaultScreen from "../../../components/ChatDashboard/ChatDefaultScreen"
import LoaderFullpage from "../../../components/Common/LoaderFullpage"
import AddPatientModal from "../dashboards/AddPatientModal"
import AddAppointmentDialog from "../../../components/Dashboard/Home/AddAppointmentDialog"
import customAxios from "../../../axios"
import ChatDrawer from "../../../components/ChatDashboard/ChatDrawer"
import whatsappBg from "./../../../assets/img/chatWindow/whatsappBg.png"


const CustomGrid = styled(Grid)({
  // Set a fixed height for the container
  // height: 300,
  // Enable scrolling
  overflowY: 'scroll',
  overflowX: 'hidden',
  // Hide the scrollbar
  // scrollbarWidth: 'thin',
  '&::-webkit-scrollbar': {
    width: 1, // Adjust to desired thickness
    backgroundColor: "transparent",
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'black', // Adjust to desired color
    position: "absolute"
  },
});


function ChatDashboard({ width }) {

  const isMobile = width === "xs" || width === "sm" ? true : false

  const [mobileScreen, setMobileScreen] = useState("sidebar")

  const userId = useSelector((state) => state.user.user._id)
  const isDoctor = useSelector((state) => state.auth.isDoctor)

  const orgId = useSelector((state) => state?.user?.user?.organisation?.createdByOrg ?? state?.user?.user?.organisation?.orgId )
  const isAdmin = useSelector((state) => state?.user?.user?.organisation?.userType === "admin");

  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [patient, setPatient] = useState(null)
  const [newChatModalOpen, setNewChatModalOpen] = useState(false)
  const [appointmentData, setAppointmentData] = useState(null)

  const [messageLoading, setMessageLoading] = useState(false)

  const [openDrawer, setOpenDrawer] = useState(false)

  const [isConsultation, setIsConsultation] = useState(false)

  useEffect(() => {
    if (
      location?.state?.appointmentId &&
      appointmentData?.patient?._id === patient?._id
    )
      setIsConsultation(true)
    else setIsConsultation(false)
  }, [patient, appointmentData])

  const [userList, setUserList] = useState([])

  useEffect(() => {
    dashboardSocket.emit("initialize_user", {
      userId: userId,
      from: "web",
      userType: "user"
    })

  }, [userId])
  
  dashboardSocket.once("user_list", (args) => {
    setUserList([...args])
  })

  useEffect(() => {
    if (location?.state?.patient) 
      setPatient(location?.state?.patient)
  }, [location])

  const [modalData, setModalData] = useState({ gender: "male" })
  const [open, setOpen] = useState(false)

  const [allChats, setAllChats] = useState([])

  return (
    <div style={{}} className="chatDashboard">
      {orgId ? (
        <Grid container style={{overflowX: "hidden"}}>
          {/* <LoaderFullpage loading={loading} /> */}
          {(isMobile && mobileScreen === "sidebar") || (!isMobile) ? <CustomGrid
            item
            xs={12}
            md={3}
            container
            style={{
              height: (orgId === "650d3d526001fd08680985a6" || orgId === "66047e6fe9d3f0c47f5c5fee") ? "100vh" : "88vh",
              position: "relative",
              backgroundColor: "#fff",
              borderRadius: "10px",
              overflowY: "auto",
              width: "22vh",
              overflowX: "hidden",
            }}
          >
            <Grid item xs={12} container>
              <ChatSidebar
                isConsultation={isConsultation}
                appointmentData={appointmentData}
                setLoading={setLoading}
                setPatient={setPatient}
                isDoctor={isDoctor}
                newChatModalOpen={newChatModalOpen}
                setNewChatModalOpen={setNewChatModalOpen}
                patient={patient}
                orgId={orgId}
                userId={userId}
                brokerList={userList}
                isAdmin={isAdmin}
                isMobile={isMobile}
                setMobileScreen={setMobileScreen}
                allChats={allChats}
                setAllChats={setAllChats}
              />
            </Grid>
          </CustomGrid> : null}
          {open ? (
            <AddAppointmentDialog
              defaultTabValue={1}
              open={open}
              setOpen={setOpen}
              modalData={modalData}
              setModalData={setModalData}
            />
          ) : null}

          {(isMobile && mobileScreen === "window") || (!isMobile) ? <CustomGrid
            item
            xs={12}
            md={openDrawer ? 6 : 9}
            container
            style={{
              minHeight: (orgId === "650d3d526001fd08680985a6" || orgId === "66047e6fe9d3f0c47f5c5fee") ? "100vh" : "88vh",
              height: (orgId === "650d3d526001fd08680985a6" || orgId === "66047e6fe9d3f0c47f5c5fee") ? "100vh" : "88vh",
              position: "relative",
              // backgroundColor: "transparent",
              borderRadius: "1px",
              width: "100%",
              overflowY: "auto",

              backgroundImage: `url(${whatsappBg})`,
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
            backgroundRepeat: "no-repeat",
            }}
          >
            <Grid item xs={12} container>
              {patient?._id ? (
                <ChatWindow
                  key={patient._id}
                  setLoading={setLoading}
                  appointmentData={appointmentData}
                  setMessageLoading={setMessageLoading}
                  messageLoading={messageLoading}
                  userId={userId}
                  isConsultation={isConsultation}
                  setModalData={setModalData}
                  setOpen={setOpen}
                  patient={patient}
                  isDoctor={isDoctor}
                  setOpenDrawer={setOpenDrawer}
                  openDrawer={openDrawer}
                  isMobile={isMobile}
                  setMobileScreen={setMobileScreen}
                  />
              ) : (
                <ChatDefaultScreen
                  newChatModalOpen={newChatModalOpen}
                  setNewChatModalOpen={setNewChatModalOpen}
                />
              )}
            </Grid>
          </CustomGrid> : null}

          {patient?._id && openDrawer &&
            <Grid
            item
            xs={12}
            md={3}
            style={{maxHeight: (orgId === "650d3d526001fd08680985a6" || orgId === "66047e6fe9d3f0c47f5c5fee") ? "100vh" : "88vh", overflowY: "hidden", overflowX: "hidden"}}
            container
          >
              <ChatDrawer
                key={patient._id}
                setLoading={setLoading}
                userId={userId}
                setModalData={setModalData}
                setOpen={setOpen}
                patient={patient}
                setPatient={setPatient}
                setOpenDrawer={setOpenDrawer}
                openDrawer={openDrawer}
                isDoctor={isDoctor}
                />
          </Grid>}
        </Grid>
      ) : null}
    </div>
  )
}

export default withWidth()(ChatDashboard)
