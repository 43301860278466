import React, { useState, useEffect } from "react"
import GenericCardTable from "../GenericCardTable"
import { Typography, Grid, Button } from "@material-ui/core"
import GenericDialogForm from "../GenericDialog"
import customAxios from "../../../axios"
import prescriptionSocket from "../../../socket"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"

function Drugs({ state, setState, docId: doctorId, previousState }) {
  const [openDialog, setOpenDialog] = useState({})
  const [selectedRxGroup, setSelectedRxGroup] = useState({})
  const [doctorRxGroup, setDoctorRxGroup] = useState([])
  const [options, setOptions] = useState([])

  const user = useSelector((state) => state.user)
  useEffect(() => {
    const testsLength = state?.diagnosis?.length
    if (testsLength) {
      let suggestedState = []
      let querytests = state.diagnosis.map((ele) => ele.name)
      console.log("resd", user.user)
      customAxios
        .put(`/ai/drugs`, {
          names: querytests.reverse(),
          doc_id: doctorId,
          spec: user?.user?.additionalDetail?.qualification
            ? user?.user?.additionalDetail?.qualification
            : "",
        })
        .then((res) => {
          if (res?.data?.success) {
            console.log("resd", res.data.data)
            const newSuggested = res.data.data.map((ele) => ({ name: ele }))

            console.log("newSuggested", suggestedState)
            suggestedState = [...newSuggested, ...suggestedState]

            // suggestedState = [...new Set(suggestedState)]
            suggestedState = suggestedState?.filter(
              (symp) =>
                !state?.tests?.find(
                  (f) => f?.name?.toLowerCase() === symp?.name?.toLowerCase()
                )
            )
            setOptions([...suggestedState, ...options])
          }
        })
    }
  }, [state?.diagnosis])
  useEffect(() => {
    getRxGroup()
  }, [])

  const getRxGroup = () => {
    customAxios.get("doctor/rx-group").then((res) => {
      if (res?.data?.success) setDoctorRxGroup([...res?.data?.data])
    })
  }

  const cardTableHeaders = [
    {
      label: "Drug",
      key: "name",
      placeholder: "eg. Crocin",
      type: "text",
      onChange: (value, state, setState) => {
        console.log("798", state)
        const temp = { ...state }

        if (temp?.composition) {
          temp["shortComposition"] = null
          temp["composition"] = null
        }
        temp["name"] = value.toUpperCase()
        setState({ ...temp })
      },

      required: true,
      width: "16%",
    },
    {
      label: "Frequency",
      key: "frequence",
      placeholder: "None",
      type: "autocomplete",
      width: "15%",
      onChange: (value, state, setState) => {
        var reg = new RegExp("^[0-9-/]*$")
        if (reg.test(value)) {
          console.log("90", value)
          const temp = { ...state }
          temp["frequence"] = value
          setState({ ...temp })
        }
      },
      options: ["1-1-1", "1-0-1", "1-1-0", "0-1-1", "1-0-0", "0-1-0", "0-0-1"],

      dependencyKey: "name",
    },
    {
      label: "Dose Timing",
      key: "timing",
      placeholder: "None",
      type: "autocomplete",
      width: "20%",
      dependencyKey: "name",
      options: ["Before Meal", "After Meal"],
    },
    {
      label: "Duration",
      key: "duration",
      placeholder: "None",
      type: "text",
      width: "20%",
      endAdornment: "days",
      dependencyKey: "name",
    },
    {
      label: "Advice",
      key: "instructions",
      placeholder: "None",
      type: "text",
      width: "17%",
      limit: 25,
      dependencyKey: "name",
    },
  ]

  const addRxDialogContent = [
    {
      label: "Name",
      key: "name",
      placeholder: "eg. Dengue(Advanced)",
      type: "text",
      required: true,
      md: 12,
    },
    {
      errorKey: "Atleast one option",
      key: "rxMeds",
      type: "checkbox",
      options: [...state.medicines],
      optionKey: "name",
      required: true,
    },
  ]

  const selectMedsDialogContent = [
    {
      label: selectedRxGroup?.["name"],
      key: "rxMeds",
      type: "checkbox",
      options: selectedRxGroup?.["rxMeds"]
        ? [...selectedRxGroup?.["rxMeds"]]
        : [],
      optionKey: "name",
    },
  ]

  const addRxGroup = (rxData, isEdit = false) => {
    customAxios.put("doctor/rx-group", rxData).then((res) => {
      if (res?.data?.success) {
        getRxGroup()
      }
    })
  }

  const removeRxGroup = (rxData) => {
    customAxios.put("doctor/delete-rx-group", rxData).then((res) => {
      if (res?.data?.success) {
        getRxGroup()
      }
    })
  }

  const chooseRxGroup = (rxData) => {
    setSelectedRxGroup({ ...rxData })
    setOpenDialog({ selectMeds: true })
  }

  const handleSelectMeds = (rxData) => {
    if (rxData?.rxMeds && rxData?.rxMeds?.length) {
      const temp = [...state.medicines]
      rxData?.rxMeds?.map((f) => {
        const check = state.medicines.findIndex((val) => val?.name === f?.name)
        if (check === -1) temp.push({ ...f })
        else temp.splice(check, 1, { ...f })
      })
      setState({ ...state, medicines: [...temp] })
    }
  }

  const autoCompleteDrug = (cardState, setCardState) => {
    if (
      !cardTableHeaders.find((f) =>
        f.key !== "name" ? cardState?.[f?.key] : false
      ) &&
      cardState["name"] &&
      !cardState["prefilled"]
    ) {
      prescriptionSocket.emit("drug_autocomplete", {
        name: cardState["name"],
        docId: doctorId,
      })

      prescriptionSocket.on("drug_autocomplete", (args) => {
        if (args) {
          setCardState({
            ...cardState,
            ...args,
            name: args?.name?.toUpperCase(),
            prefilled: true,
          })
        }
      })
    }

    if (cardState?.name) {
      prescriptionSocket.emit("get_meds", {
        search: cardState.name,
      })

      prescriptionSocket.once("get_meds", (args) => {
        if (args) {
          setOptions([...args, ...options])
        }
      })
    }
  }

  return (
    <React.Fragment>
      <GenericCardTable
        cardTitle={"Drug Details:"}
        suggestedTab={options.length}
        tabSwitching={true}
        suggestedData={options}
        onSuggestedHoverLabel={"shortComposition"}
        suggestedDataLabel="name"
        suggestedTitle={"Suggested Drugs"}
        tableHeaders={cardTableHeaders}
        tableData={state}
        cardKey="medicines"
        setTableData={setState}
        addOnFunction={autoCompleteDrug}
      />
      {previousState?.medicines?.length ? (
        <Grid
          item
          xs={12}
          style={{ display: "flex", flex: "flex-wrap", alignItems: "center" }}
        >
          <Typography variant="body2">
            *{""} Copy drugs from patient's previous prescription
          </Typography>
          <Button
            variant="text"
            size="small"
            color="primary"
            onClick={() =>
              setState((prev) => ({
                ...prev,
                medicines: [...previousState?.medicines],
              }))
            }
          >
            <Typography variant="body2">Click Here</Typography>
          </Button>
        </Grid>
      ) : null}

      <Grid container>
        <Grid
          item
          xs={6}
          style={{ display: "flex", flex: "flex-wrap", alignItems: "center" }}
        >
          <Typography variant="body2">
            * In order to choose from existing Rx groups
          </Typography>
          <Button
            variant="text"
            size="small"
            color="primary"
            onClick={() => setOpenDialog({ chooseRx: true })}
          >
            <Typography variant="body2">Click Here</Typography>
          </Button>
        </Grid>

        <Grid item container xs={6} justifyContent="flex-end">
          {state.medicines?.length ? (
            <Grid item>
              <Button
                variant="text"
                size="small"
                color="primary"
                onClick={() => setOpenDialog({ addRx: true })}
              >
                <Typography variant="body2">Create Rx Group</Typography>
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      {openDialog?.chooseRx ? (
        <GenericDialogForm
          open={true}
          handleClose={() => setOpenDialog({})}
          dialogTitle={"Choose Rx Group"}
          dialogContent={[...doctorRxGroup]}
          handleCancel={() => setOpenDialog({})}
          handleSubmit={chooseRxGroup}
          showDialogAction={false}
          isList={true}
          editListItem={true}
          removeListItem={true}
          onEditItem={(data) =>
            setOpenDialog({ editRx: true, data: { ...data } })
          }
          onRemoveItem={removeRxGroup}
        />
      ) : null}
      {openDialog?.selectMeds ? (
        <GenericDialogForm
          open={true}
          handleClose={() => setOpenDialog({})}
          dialogTitle={"Select Medicines"}
          dialogContent={selectMedsDialogContent}
          handleCancel={() => setOpenDialog({})}
          handleSubmit={handleSelectMeds}
          cancelButtonText={"Cancel"}
          submitButtonText={"Add"}
          formData={{
            rxMeds: selectedRxGroup?.["rxMeds"]?.filter((f) => {
              const check = state?.medicines?.find(
                (val) => val?.name === f?.name
              )
              if (check) return false
              else return true
            }),
          }}
        />
      ) : null}
      {openDialog?.addRx ? (
        <GenericDialogForm
          open={true}
          handleClose={() => setOpenDialog({})}
          dialogTitle={"Create Rx Group"}
          dialogContent={addRxDialogContent}
          handleCancel={() => setOpenDialog({})}
          handleSubmit={addRxGroup}
          cancelButtonText={"Cancel"}
          submitButtonText={"Add"}
          formData={{ rxMeds: [...state?.medicines] }}
        />
      ) : null}
      {openDialog?.editRx ? (
        <GenericDialogForm
          open={true}
          handleClose={() => setOpenDialog({})}
          dialogTitle={"Update Rx Group"}
          dialogContent={[
            { ...addRxDialogContent[0] },
            {
              ...addRxDialogContent[1],
              options: [...openDialog?.data?.rxMeds],
            },
          ]}
          handleCancel={() => setOpenDialog({ chooseRx: true })}
          handleSubmit={(data) =>
            addRxGroup({ ...data, prevName: openDialog?.data?.name }, true)
          }
          cancelButtonText={"Cancel"}
          submitButtonText={"Update"}
          formData={{ ...openDialog?.data }}
        />
      ) : null}
    </React.Fragment>
  )
}

export default Drugs
