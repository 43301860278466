import React, { useEffect, useState } from "react"
import {
  TableCell,
  TableBody,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  Paper,
  Grid,
  Typography,
} from "@material-ui/core"
import Button from "reactstrap/lib/Button"
import ShowRevenueGenerated from "../../../components/Dashboard/Revenue/RevenueGenerated"
import customAxios from "../../../axios"
import { Card, CardBody, CardHeader } from "reactstrap"
import { toast } from "react-toastify"
import { colors } from "../../../assets/css/colors"
require("./Revenue.css")

function Revenue() {
  const [revenueData, setRevenueData] = useState({})
  const [totalRevenue, setTotalRevenue] = useState({})
  const [rows, setRows] = useState([])

  useEffect(() => {
    customAxios
      .get(`/analytics/revenue-detail`)
      .then((res) => {
        if (res.data.success) {
          setTotalRevenue(res.data.data)
          console.log("pot4", res.data.data)
        } else {
          toast.error("Some error occured")
        }
      })
      .catch((err) => {
        console.log("err")
      })
  }, [])
  useEffect(() => {
    customAxios
      .get(`/analytics/doctor-revenue`)
      .then((res) => {
        setRevenueData(res?.data?.data)
      })
      .catch((error) => {
        console.log(error)
      })

    customAxios
      .get(`/analytics/get-transactions`)
      .then((res) => {
        setRows(res?.data?.data.reverse())
      })
      .catch((error) => {
        console.log(error)
      })
    //eslint-disable-next-line
  }, [])

  // const labels = Utils.months({count: 7});
  return (
    <>
      <div style={{ padding: "4vh" }}>
        <Card>
          <Grid container xs={12} style={{ padding: "1rem" }} spacing={2}>
            <Grid item md={4}>
              <Typography variant="h4">
                Total Revenue:
                <span style={{ color: colors.primary }}>
                  {" "}
                  ₹{totalRevenue.totalApolloRevenue}
                </span>
              </Typography>
              <Typography variant="body2">
                {"(Consultation + Procedure)"}
              </Typography>
              <Typography>
                Total revenue collected from regular, follow-up, procedural appointments{" "}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography variant="h4">
                Consultation Revenue:{" "}
                <span style={{ color: colors.primary }}>
                  ₹{totalRevenue.totalConsultationRevenue}
                </span>
              </Typography>
              <Typography variant="body2">
              </Typography>
              <Typography>
                Revenue collected from regular and follow-up appointments
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography variant="h4">
                Total Appointments:{" "}
                <span style={{ color: colors.primary }}>
                  ₹{totalRevenue.totalAppointments}
                </span>
              </Typography>
              <Typography variant="body2">
              </Typography>
              <Typography>
                Number of total appointments
              </Typography>
            </Grid>
          </Grid>
        </Card>
        <Card>
          <CardHeader>
            <h5 className="h3 mb-0">Past Transactions</h5>
            <h6 className="surtitle">Your past transactions</h6>
          </CardHeader>
          <CardBody>
            {rows?.length ? (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Transaction ID</TableCell>
                      <TableCell>Transaction</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, idx) => (
                      <TableRow
                        key={row?.id + idx}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {new Date(row?.date).toDateString()}
                        </TableCell>
                        <TableCell>{"Rs." + row?.amount}</TableCell>
                        <TableCell>{row?.id}</TableCell>
                        <TableCell>
                          <Button
                            color="primary"
                            outline
                            type="button"
                            pill
                            onClick={() => window.open(row?.shortUrl)}
                          >
                            View Transaction
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <h3 style={{ textAlign: "center" }}>No Data Found</h3>
            )}
          </CardBody>
        </Card>
      </div>
    </>
  )
}

export default Revenue
