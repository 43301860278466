import io from "socket.io-client";

const prescriptionSocket = io(
  "https://pplane-api-prod.paperplane.health/prescription", {
  transports: ["websocket", "polling"]
})

prescriptionSocket.on("connect", () => {
  console.log(prescriptionSocket.id);
});
prescriptionSocket.on("disconnect", () => {
  console.log(prescriptionSocket.id);
});

export default prescriptionSocket;
