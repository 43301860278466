import React, { useState, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Button, Grid, Icon, InputAdornment, Tooltip, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Close, ImageOutlined } from '@material-ui/icons';


export default function CustomTextField ({ handleSendMessage, patient, menuActions, onFileUpload, isMobile, uploadFiles, openDrawer }) {
  const [inputValue, setInputValue] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);

  const userData = useSelector((state) => state?.user?.user)

  const anchorRef = useRef(null);

  const handleInputChange = (event) => {
    if(event?.nativeEvent?.inputType !== "insertLineBreak")
      setInputValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if(event.keyCode === 13 && event?.shiftKey){
      setInputValue(inputValue+"\n")
    }
    else if (event.key === 'Enter') {
      // Handle sending the message
      // For example, you can call a function to send the message
    //   sendMessage();

        if(image){
          uploadFiles(image)
          setImage(null)
        }

        if(showOptions) {
            setShowOptions(false);
            setInputValue(options?.[selectedOptionIndex]?.value)
            anchorRef?.current?.focus(); // Set focus on the input field
            anchorRef?.current?.setSelectionRange(inputValue?.length - 1, inputValue?.length - 1);
        } else if(inputValue?.trim() && inputValue !== " ") {
          handleSendMessage(inputValue, "text", false)
          setInputValue("");
        }
    } else if (event.key === '/' && (event?.target?.value === '')) {
      // Show options
      setShowOptions(true);
      setSelectedOptionIndex(0);
    } else if (event.key === 'ArrowUp' && inputValue?.startsWith('/')) {
      event.preventDefault();
        setShowOptions(true);
      setSelectedOptionIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : options.length - 1));
    } else if (event.key === 'ArrowDown' && inputValue?.startsWith('/')) {
      event.preventDefault();
        setShowOptions(true);
      setSelectedOptionIndex((prevIndex) => (prevIndex < options.length - 1 ? prevIndex + 1 : 0));
    } else {
        setShowOptions(false)
    }
  };

  const handleClose = () => {
    setShowOptions(false);
  };

  const handleBoldClick = () => {
    document.execCommand('bold', false, null);
    anchorRef.current.focus();
  };

  const handleOptionClick = (option) => {
    setInputValue(option?.value);
    setShowOptions(false);
  };

  const sendMessage = () => {
    // Handle sending the message
    // For example, you can send the message to a chat API
    console.log('Message sent:', inputValue);
    setInputValue('');
  };

  const options = userData?.organisation && userData?.organisation?.orgId === "63c7abe4cc77d245a7e1404e" ? [
    {"label": "Medicines Team Intro", "value": `Greetings from BeatO,

Are you spending too much on medicines?

Save money on repeat orders of medicines for diabetes, BP, cholesterol and other conditions.

Get Free Home Delivery of medicines upto 22% discount from BeatO.

Share your prescription or medicine name with quantity.

Hurry it’s a Limited Time Offer!!!

"Offer is applicable on orders above INR 1299"

Stay Safe!
Stay Healthy!`},
{"label": "Medicine-Order Offer", "value": `Our daily dose is just a message away! 💊

Order your essential medicines with BeatO and enjoy the following benefits:

Up to 28% OFF on your first order 🎉
Doorstep delivery within 48 hours in Delhi/NCR 🚚
Easy and hassle-free ordering process 📲

Don’t delay good health! Reply with "Hi" to place your order.

Stay Healthy,
Team BeatO 👩‍⚕️👨‍⚕️`}
  ] : [
    {label: "Check well being", value: `Hey ${patient['name']}. Just dropping a message to see how you've been doing since we last connected. I genuinely care about your well-being, so please share any updates or concerns you may have 💬, simply by clicking button below 👇`},
  ]

  
  const [image, setImage] = useState(null);

  const handlePaste = async (event) => {
    const items = event.clipboardData.items;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (item.type.indexOf('image') !== -1) {
        const blob = item.getAsFile();
        const imageUrl = URL.createObjectURL(blob);
        console.log(item, '9879898979897ceck')
        setImage([blob]);
        break;
      }
    }
  };

  return (
    <React.Fragment>
      <Grid item xs={11}>
        <TextField
          multiline
          rows={3}
          variant="outlined"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          fullWidth
          onPaste={handlePaste}
          autoFocus
          inputRef={anchorRef}
          style={{backgroundColor: 'white', borderRadius: "30px"}}

          placeholder={"Press / for quick replies ⚡"}
          //   Press / for quick replies
          //   <GenericAnimation animationData={quickAnimation} height={"40px"} width={"40px"} />
          // </div>}
          InputProps={{
            startAdornment: (
                <Grid container style={{position: 'absolute', bottom: 0}} alignItems="center">
                    {/* <Grid item>
                        <IconButton onClick={handleBoldClick}>
                            <FormatBold />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton>
                            <FormatItalic />
                        </IconButton>
                    </Grid> */}
                    {menuActions?.map(v => (
                      <Grid item>
                        <Tooltip title={v?.label}>
                          <IconButton onClick={v?.onClick}>
                            <i className={v?.icon} style={{fontSize: 18, opacity: "90%"}}></i>
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    ))}
                    <Grid item>
                      <Tooltip title="Upload File">
                        <IconButton onClick={onFileUpload} title="Upload File">
                            <AttachFileIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>

                    {image ? (
                      <Grid item style={{position: "absolute", top: -35, left: 50}}>
                        <div style={{position: "relative"}}>
                          <ImageOutlined style={{height: "60px", width: "60px", opacity: "0.7"}}/>
                          <IconButton size='small' style={{position: "absolute", top: 0, left: 60}} onClick={() => setImage(null)}>
                            <Close />
                          </IconButton>
                        </div>
                        <div style={{fontSize: "10px", color: "grey"}}>
                          Image Copied! Press enter to send
                        </div>
                      </Grid>
                    ) : null}

                    <Grid item>
                      {/* <Typography style={{marginTop: '20px', position: "absolute", bottom: isMobile ? 10 : 10, zIndex: 10, left: openDrawer ? "25%" : "40%", fontSize: "12px"}}>
                        Powered By 
                        <span style={{
                          // color: "#4D63DD",
                          background: "linear-gradient(128.8deg, #4D63DD 28.61%, #22A1F5 99.07%)",
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent', 
                          fontWeight: "bold",
                          marginLeft: 3,
                          fontSize: "12px"
                          }}>
                          <i>Paperplane</i>
                        </span>
                      </Typography> */}
                    </Grid>

                    {!isMobile ? <Grid item style={{marginLeft: "auto", marginRight: "40px", opacity: "60%"}}>
                        {//<Button variant="contained" color="primary" onClick={() => handleSendMessage(inputValue)} endIcon={<KeyboardReturn />}>
                            //Send 
                        //</Button>
                      } 
                      Press Enter to send message
                        {/* <div style={{display: "flex", alignItems: "center"}}>
                          Press / for quick replies
                          <GenericAnimation animationData={quickAnimation} height={"30px"} width={"30px"} />
                        </div> */}
                    </Grid> : null}
                </Grid>
            ),
            style: { position: 'relative', borderRadius: "30px", paddingBottom: isMobile ? "15%" : "5%" },
            // inputComponent: CustomInput
          }}
          // InputProps={{
          //   // readOnly: true,
          //   multiline: true,
          //   rows: 6,
          //   startAdornment: (
          //     <Typography variant="body1" dangerouslySetInnerHTML={{ __html: inputValue }} />
          //   ),
          // }}
        />

        {/* <QuillTextField /> */}

        {showOptions && (
          <Popper open={showOptions} anchorEl={anchorRef.current} placement="top-start" style={{width: "55%", marginBottom: "1%"}}>
            <Paper style={{width: "100%", opacity: "90%"}} elevation={0}>
              <ClickAwayListener onClickAway={handleClose}>
                <List>
                  {options.map((option, index) => (
                    <ListItem
                      key={option?.label}
                      button
                      selected={selectedOptionIndex === index}
                      onClick={() => handleOptionClick(option)}
                    >
                      {option?.label}
                    </ListItem>
                  ))}
                </List>
              </ClickAwayListener>
            </Paper>
          </Popper>
        )}
      </Grid>
      <Grid item xs={1}>
        <IconButton
          color="primary"
          style={{marginLeft: "1%", position: "absolute", bottom: 50}}
          onClick={() => {
            if (inputValue?.trim()) {
              handleSendMessage(inputValue, "text", true)
              setInputValue("")
            }
          }}
          title="Send Message"
        >
          <SendIcon fontSize='large'/>
        </IconButton>
      </Grid>
      </React.Fragment>
  );
};

