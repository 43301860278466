import io from "socket.io-client"

const dashboardSocket =  io(
  "https://pplane-pcs-wsocket.paperplane.health/chat-message", {
  transports: ["websocket", "polling"]
})

// const dashboardSocket =  io(
//   "localhost:8000/chat-message", {
//   transports: ["websocket", "polling"]
// })

// const dashboardSocket =  io(
//   "https://wmapi.paperplane.health:8000/chat-message", {
//   transports: ["websocket", "polling"]
// })

dashboardSocket.on("connect", () => {
  console.log(dashboardSocket.id)
})
dashboardSocket.on("disconnect", () => {
  console.log(dashboardSocket.id)
})

export default dashboardSocket
