import { FormControlLabel, Grid, Switch, Typography } from "@material-ui/core"
import moment from "moment"
import GenericAnimation from "../animation"
import highlighterAnimation from "./../../assets/animationData/highlighter.json"

export const TabSwitchingHelperText = () => {
  return (
    <Typography
      style={{
        fontSize: "14px",
        position: "relative",
        display: "flex",
        alignItems: "center",
      }}
    >
      Use
      <span
        style={{
          margin: "0 0.5rem",
          fontWeight: "800",
          fontStyle: "oblique",
          fontSize: "16px",
        }}
      >
        "Tab"
      </span>
      <GenericAnimation
        style={{ margin: "0 0.2rem" }}
        animationData={highlighterAnimation}
        height={40}
        width={17}
      ></GenericAnimation>{" "}
      to go fast
    </Typography>
  )
}
export const SlotBlock = (data) => {
  switch (data.type) {
    case "empty_slot":
      return (
        <Grid
          item
          xs={12}
          style={{
            padding: "1rem",
            background: "#f3f3f3",
            borderRadius: "12px",
            fontSize: "12px",
          }}
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={6}>
              <Typography>
                {moment.utc(data.start).format("hh:mm A") +
                  " - " +
                  moment.utc(data.end).format("hh:mm A")}{" "}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Switch checked={false} />}
                label="Block"
              ></FormControlLabel>
            </Grid>
          </Grid>
        </Grid>
      )
    case "booked_slot":
      return (
        <Grid
          item
          xs={12}
          container
          style={{
            padding: "1rem",
            background: "#f3f3f3",
            borderRadius: "12px",
            fontSize: "12px",
          }}
        >
          <div
            style={{ minHeight: "50px", height: "50px", background: "blue" }}
          >
            Upcoming
          </div>
          <Typography>
            {moment.utc(data.start).format("hh:mm A") +
              " - " +
              moment.utc(data.end).format("hh:mm A")}{" "}
          </Typography>
          <Grid container item xs={12}>
            <Grid item xs={6}></Grid>

            <Typography>{data.patient.name}</Typography>
            <Grid item>
              <FormControlLabel
                control={<Switch checked={true} />}
                label="Block"
              ></FormControlLabel>
            </Grid>
          </Grid>
        </Grid>
      )

    default:
      return null
  }
}
