/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react"
// nodejs library to set properties for components
import PropTypes from "prop-types"
// reactstrap components
import { Breadcrumb, BreadcrumbItem, Container, Row, Col } from "reactstrap"

class Header extends React.Component {
	render() {
		return (
			<>
				<div className="header bg-primary pb-6">
					<Container fluid>
						<div className="header-body">
							<Row className="align-items-center py-4">
								<Col lg="6" xs="7">
									<h6 className="h2 text-white d-inline-block mb-0">
										{this.props.name}
									</h6>{" "}
									<Breadcrumb
										className="d-none d-md-inline-block ml-md-4"
										listClassName="breadcrumb-links breadcrumb-dark">
										<BreadcrumbItem>
											<a href="#" onClick={e => e.preventDefault()}>
												<i className="fas fa-home" />
											</a>
										</BreadcrumbItem>
										<BreadcrumbItem>
											<a href="#" onClick={e => e.preventDefault()}>
												{this.props.parentName}
											</a>
										</BreadcrumbItem>
										<BreadcrumbItem aria-current="page" className="active">
											{this.props.name}
										</BreadcrumbItem>
									</Breadcrumb>
								</Col>
							</Row>
						</div>
					</Container>
				</div>
			</>
		)
	}
}

Header.propTypes = {
	name: PropTypes.string,
	parentName: PropTypes.string,
}

export default Header
