/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from 'react';

// react plugin used to create charts
// reactstrap components
import { Container, Row, Col, CardBody, Card, Button } from 'reactstrap';
import customAxios from '../../../axios';

// core components
import Header from '../../../components/User/Header';

//import HistoryTable from '../../../components/Dashboard/Patients/historyTable';
import './patient.styles.css';

// import customAxios from '../../../axios';
// import moment from 'moment';

//import th from "assets/vendor/fullcalendar/dist/locale/th";

export default function PatientProfile() {
  //const patient_id = '60e1f4c01181e350f5915dc3';

  const [
    ,
    // patientData
    setPatientData,
  ] = useState();

  useEffect(() => {

    customAxios
      .get(
        `appointments/day/month?patient_id=60e1f4c01181e350f5915dc3&status=all`,
      )
      .then((res) => {
        console.log(res.data.data);
        setPatientData(res.data.data);
      });
  }, []);

  return (
    <div>
      <>
        <Header parentName='Dashboard' />
        <Container className='mt--6' fluid>
          <Row>
            <Col xl='12'>
              <Card>
                <CardBody>
                  <Row className='align-items-center'>
                    <Col xs='5'>
                      <Row>
                        <Col xs='3'>
                          <a
                            className='avatar avatar-xl rounded-circle'
                            href=''
                            onClick={(e) => e.preventDefault()}>
                            <img
                              alt='...'
                              src={require('../../../assets/img/theme/team-2.jpg')}
                            />
                          </a>
                        </Col>
                        <Col
                          xs='9'
                          style={{
                            textAlign: 'left',
                          }}>
                          <div>
                            <text className='patient-page-name'>
                              Arhan Choudhury
                            </text>
                          </div>
                          <div>
                            <text className='patient-page-right-description'>
                              19 | F | 15-06-2021
                            </text>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs='3' style={{ textAlign: 'left' }}>
                          <div>
                            {' '}
                            <text className='patient-page-left-description'>
                              Phone
                            </text>
                          </div>
                        </Col>
                        <Col xs='9' style={{ textAlign: 'left' }}>
                          <text className='patient-page-right-description'></text>
                          (+91) 9854342766
                        </Col>
                      </Row>
                      <hr style={{ margin: '0px' }} />
                      <Row>
                        <Col xs='3' style={{ textAlign: 'left' }}>
                          <div>
                            {' '}
                            <text className='patient-page-left-description'>
                              Email
                            </text>
                          </div>
                        </Col>
                        <Col xs='9' style={{ textAlign: 'left' }}>
                          <text className='patient-page-right-description'></text>
                          abcxyz@gmail.com
                        </Col>
                      </Row>
                      <hr style={{ margin: '0px' }} />
                      <Row>
                        <Col xs='3' style={{ textAlign: 'left' }}>
                          <div>
                            {' '}
                            <text className='patient-page-left-description'>
                              Address
                            </text>
                          </div>
                        </Col>
                        <Col xs='9' style={{ textAlign: 'left' }}>
                          <text className='patient-page-right-description'></text>
                          New Delhi
                        </Col>
                      </Row>
                    </Col>

                    <Col xs='2'>
                      <div>
                        <Button
                          color='primary'
                          outline
                          type='button'
                          style={{ marginTop: '-50%', borderRadius: '14px' }}>
                          Primary
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <hr style={{ margin: '0px' }} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl='6'></Col>
          </Row>
          <Row>
            <Col xl='6'></Col>
          </Row>
          <Row>
            <Col xl='12'></Col>
          </Row>
        </Container>
      </>
    </div>
  );
}
