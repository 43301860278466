import React, { useEffect, useState } from "react"
// import Header from "../../../components/Dashboard/Home/Header"
// import UtilizedCapacity from "../../../components/Dashboard/Analytics/UtlizedCapacity";
import Revenue from "../../../components/Dashboard/Analytics/Revenue"
import Gender from "../../../components/Dashboard/Analytics/Gender"
import Age from "../../../components/Dashboard/Analytics/Age"
import moment from "moment"
import customAxios from "../../../axios"
import { useSelector } from "react-redux"
import ToggleButton from "@material-ui/lab/ToggleButton"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Table,
} from "reactstrap"
import Lottie from 'react-lottie';

import { toast } from "react-toastify"
import DateRangePicker from "../../../components/Dashboard/Home/DateRangePicker"
import LoadingAnimation from "../examples/LoadingAnimation"
import DateTypeToggle from "../../../components/Common/DateTypeToggle"
import { Autocomplete } from "@material-ui/lab"
import TextField from '@mui/material/TextField';

import {
  curveCatmullRom,
  area,
} from 'd3-shape';
import { RangeDatePicker } from "react-google-flight-datepicker"

import { scalePoint } from 'd3-scale';
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  Title,
  Legend,
  Tooltip
} from '@devexpress/dx-react-chart-material-ui';
import { Stack, Animation, ArgumentScale, AreaSeries, EventTracker } from '@devexpress/dx-react-chart';
import { styled } from "@material-ui/styles"
import Header from "../../../components/User/Header"
import { CircularProgress, Grid, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@material-ui/core"
import animationData from '../../../assets/animationData/97071-infinite-scroll-loader.json'

import ReactDatetime from "react-datetime";


const PREFIX = 'PPL';

const classes = {
  chart: `${PREFIX}-chart`,
};


const Item = props => (
  <Legend.Item {...props} sx={{ flexDirection: 'column-reverse' }} />
);

const StyledChart = styled(Chart)(() => ({
  [`&.${classes.chart}`]: {
    paddingRight: '20px',
  },
}));

const Area = props => (
  <AreaSeries.Path
    {...props}
    path={area()
      .x(({ arg }) => arg)
      .y1(({ val }) => val)
      .y0(({ startVal }) => startVal)
      .curve(curveCatmullRom)}
  />
);

const Root = props => (
  <Legend.Root {...props} sx={{ display: 'flex', margin: 'auto', flexDirection: 'row' }} />
);
const Label = props => (
  <Legend.Label {...props} sx={{ whiteSpace: 'nowrap' }} />
);

const broadcastAnalytics1 = [{
  name: 'Broadcast 1',
  sent: 36,
  delivered: 38,
  read: 36,
}, {
  name: 'Broadcast 2',
  sent: 51,
  delivered: 21,
  read: 28,
}, {
  name: 'Broadcast 3',
  sent: 23,
  delivered: 21,
  read: 28,
}, {
  name: 'Broadcast 4',
  sent: 19,
  delivered: 13,
  read: 15,
}, {
  name: 'Broadcast 5',
  sent: 14,
  delivered: 15,
  read: 17,
}, {
  name: 'Broadcast 6',
  sent: 16,
  delivered: 10,
  read: 15,
}];

const broadcastAnalytics2 = [
  {
    name: 'Broadcast 1', response_no: 59.8, total: 937.6
  }, {
    name: 'Broadcast 2', response_no: 74.2, total: 308.6
  }, {
    name: 'Broadcast 3', response_no: 40, total: 128.5
  }, {
    name: 'Broadcast 4', response_no: 22.6, total: 241.5
  }, {
    name: 'Broadcast 5', response_no: 19, total: 119.3
  }, {
    name: 'Broadcast 6', response_no: 6.1, total: 123.6
  }];


const Analytics = () => {

  const loadingAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    renderer: 'svg',
  };

  
  const phoneNo = useSelector((state) => state.user.user.phoneNo)
  const userData = useSelector((state) => state?.user?.user)


  const [currentBroadcast, setCurrentBroadcast] = useState(null)

  const [broadcastList, setBroadcastList] = useState([])

  const [broadcastState, setBroadcastState] = useState({analytics1: [], analytics2: []})

  const [eventState, setEventState] = useState(null)
  const [broadcastDate, setBroadcastDate] = useState({'start': moment.utc(new Date()).subtract(7, "days").toISOString(), "end": moment.utc(new Date()).toISOString()})
  const [eventDate, setEventDate] = useState({'start': null, "end": null})

  const [broadcastClick, setBroadcastClick] = useState(0);
  const [clickLoader, setClickLoader] = useState(false)
  const [broadcastLoader, setBroadcastLoader] = useState(false)

  useState(() => {
    customAxios
        .get(
        `/broadcast/filter`
        )
        .then((res) => {
          const temp = res.data.data.reverse()
          setCurrentBroadcast({...temp[0]})
          setBroadcastList([...res.data?.data])
        })
        .catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    if(currentBroadcast?._id){

      setBroadcastLoader(true)
      setClickLoader(true)
      customAxios
        .get(
          `/analytics/broadcast?broadcast_id=${currentBroadcast?._id}`
        )
        .then((res) => {
          setBroadcastState({...res?.data?.data})
          setBroadcastLoader(false)

          customAxios.get(`/analytics/broadcast-click?broadcast_id=${currentBroadcast?._id}`).then((res) => {
            if(res?.data?.success)
            // console.log(res?.data)
            setBroadcastClick(res?.data?.data)
            setClickLoader(false)
          }).catch((err) => setClickLoader(false))
        })
        .catch((err) => console.log(err))
      
      
    }
      
  }, [currentBroadcast])

  return (
    <>
      <Header parentName="Analytics" />
      <Container className="mt--6" fluid>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h5 className="h1 mb-0">Broadcast</h5>
                  </div>
                  <div style={{maxWidth: "40%", width: "40%"}}>
                  {/* <RangeDatePicker
                    startDate={new Date(broadcastDate?.start)}
                    endDate={new Date(broadcastDate?.end)}
                    onChange={(startDate, endDate) => {
                      if(startDate && endDate) {
                        setBroadcastDate({...broadcastDate, "start": moment.utc(startDate).toISOString(), "end": moment.utc(endDate).toISOString()})
                      }
                    }}
                    // fullWidth
                    startDatePlaceholder={"From"}
                    endDatePlaceholder={"To"}
                    // singleCalendar
                  />{" "}
                   */}
                   <Autocomplete 
                      fullWidth
                      options={broadcastList}
                      disableClearable
                      onChange={(e, value) => {
                        setCurrentBroadcast({...value})
                      }}
                      value={currentBroadcast}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField {...params} label="Select Broadcast" variant="standard" />}
                    />
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <Chart
                      data={broadcastState?.analytics1}
                    >
                      <ArgumentAxis />
                      <ValueAxis />

                      <BarSeries
                        name="Sent"
                        valueField="sent"
                        argumentField="name"
                        color="#F7DC6F"
                      />
                      <BarSeries
                        name="Delivered"
                        valueField="delivered"
                        argumentField="name"
                        color="#5DADE2"
                      />
                      <BarSeries
                        name="Read"
                        valueField="read"
                        argumentField="name"
                        color="#58D68D"
                      />
                      <EventTracker />
                      <Tooltip />
                      <Animation />
                      <Legend position="bottom" rootComponent={Root} labelComponent={Label} />
                      <Title text={<Typography variant="h2" style={{fontFamily: "Roboto, Helvetica, Arial, sans-serif"}}>Overview</Typography>} />
                      <Stack />
                    </Chart>
                  </Col>
                  <Col md={6}>
                  {/* <StyledChart
                      data={broadcastState?.analytics2}
                    >
                      <ArgumentScale factory={scalePoint} />
                      <ArgumentAxis />
                      <ValueAxis
                      />

                      <AreaSeries
                        seriesComponent={Area}
                        name="Total"
                        valueField="total"
                        argumentField="name"
                      />
                      <AreaSeries
                        seriesComponent={Area}
                        name="No. of Response"
                        valueField="response_no"
                        argumentField="name"
                        color="#58D68D"
                      />
                      <Legend position="bottom" rootComponent={Root} labelComponent={Label} />
                      <Title
                        text="Responses"
                      />
                      <Animation />
                    </StyledChart> */}
                    <Grid container>
                      <Grid item container xs={12} justifyContent="center"> 
                        <Typography variant="h2" style={{fontFamily: "Roboto, Helvetica, Arial, sans-serif"}}>Clicks</Typography>
                      </Grid>
                      <Grid item container xs={12} justifyContent="center"> 
                        {!clickLoader ? <Typography style={{fontSize: 162, marginTop: "4%"}}>{broadcastClick}</Typography> : 
                        <Lottie
                          options={loadingAnimationOptions}
                          height={"85%"}
                          width={"85%"}
                          isStopped={false}
                          isPaused={false}
                        />}
                      </Grid>
                    </Grid>
                  </Col>
                  <Col>
                  <Typography variant="caption">Users who already stopped: {broadcastState?.alreadyStopped ? broadcastState?.alreadyStopped : "-"}</Typography>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>  
        {userData?.organisation && userData?.organisation?.orgId === "63c7abe4cc77d245a7e1404e" ? <Row>
          <Col md="12">
            <Card>
              {/* <CardHeader>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{maxWidth: "20%", width: "20%"}}>
                      <Autocomplete 
                      fullWidth
                      options={[]}
                      disableClearable
                      onChange={(e, value) => {
                        setCurrentBroadcast({...value})
                      }}
                      value={currentBroadcast}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField {...params}  inputProps={{style: {fontSize: 26}, ...params?.inputProps}}  label="Select Event" variant="standard" />}
                    />
                  </div>
                  <div style={{maxWidth: "40%", width: "40%"}}>
                  <RangeDatePicker
                    // startDate={new Date()}
                    // endDate={new Date()}
                    onChange={(startDate, endDate) => {
                      const value = { startDate: startDate, endDate: endDate }
                      console.log("range date", value)
                    }}

                    // fullWidth
                    startDatePlaceholder={"From"}
                    endDatePlaceholder={"To"}
                    // singleCalendar
                  />{" "}
                  </div>
                </div>
              </CardHeader> */}
              <CardBody>
                <Row>
                    <EventAnalytics />
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row> : null}
      </Container>
    </>
  )
}

export default Analytics



const EventAnalytics = ({}) => {

  const [rangeDate, setRangeDate] = useState({
    start: moment.utc(new Date()),
    end: moment.utc(new Date()).add(1, "day"),
  })

  const [eventData, setEventData] = useState([])

  const [eventLoading, setEventLoading] = useState(false)

  useEffect(() => {
    setEventLoading(true)
    customAxios.get(`beat-o/get-event-by-params?start_date=${rangeDate?.start?.format("DD/MM/YYYY")}&end_date=${rangeDate?.end?.format("DD/MM/YYYY")}`).then((res) => {
      setEventLoading(false)
      if(res?.data?.success)
      setEventData(res?.data?.data)
    })

  }, [rangeDate]) 

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  return (
    <Grid container spacing={2}>
      <Grid item container xs={12} justifyContent="flex-start" alignItems="center">
        <Grid item style={{marginLeft: 10}}>
          <ReactDatetime
            dateFormat="DD/MM/YYYY"
            inputProps={{
              placeholder: "Start Date",
              label: "Start Date"
            }}
            value={rangeDate?.start}
            timeFormat={false}
            renderDay={(props, currentDate, selectedDate) => {
              let classes = props.className;
              // if (state?.startDate && state?.startDate?._d + "" === currentDate._d + "") {
              // 	classes += " start-date";
              // } else if (
              // 	state?.startDate &&
              // 	state?.endDate &&
              // 	new Date(state?.startDate?._d + "") < new Date(currentDate._d + "") &&
              // 	new Date(state?.endDate._d + "") > new Date(currentDate._d + "")
              // ) {
              // 	classes += " middle-date";
              // } else if (state?.endDate && state?.endDate?._d + "" === currentDate._d + "") {
              // 	classes += " end-date";
              // }
              return (
                <td {...props} className={classes}>
                  {currentDate.date()}
                </td>
              );
            }}
            onChange={(e) => {
              if (e && typeof(e) === "object")
                setRangeDate({...rangeDate, "start": e})                                
            }}
            
            isValidDate= {
              (current) =>  current.isAfter(moment.utc(new Date("2023-09-18")).format("YYYY-MM-DD"))
            }
          />
        </Grid>
        <Grid item style={{marginLeft: 10}}>
          <Typography>
            - to -
          </Typography>
        </Grid>
        <Grid item style={{marginLeft: 10}}>
          <ReactDatetime
            dateFormat="DD/MM/YYYY"
            inputProps={{
              placeholder: "End Date",
              label: "End Date"
            }}
            timeFormat={false}
            value={rangeDate?.end}
            renderDay={(props, currentDate, selectedDate) => {
              let classes = props.className;
              // if (state?.startDate && state?.startDate?._d + "" === currentDate._d + "") {
              // 	classes += " start-date";
              // } else if (
              // 	state?.startDate &&
              // 	state?.endDate &&
              // 	new Date(state?.startDate?._d + "") < new Date(currentDate._d + "") &&
              // 	new Date(state?.endDate._d + "") > new Date(currentDate._d + "")
              // ) {
              // 	classes += " middle-date";
              // } else if (state?.endDate && state?.endDate?._d + "" === currentDate._d + "") {
              // 	classes += " end-date";
              // }
              return (
                <td {...props} className={classes}>
                  {currentDate.date()}
                </td>
              );
            }}
            onChange={(e) => {
              if (e && typeof(e) === "object")
                setRangeDate({...rangeDate, "end": e})
            }}
            // isValidDate={disableEndDt}
            isValidDate= {
              (current) => rangeDate?.start ? current.isAfter(rangeDate?.start?.format("YYYY-MM-DD")) : current.isAfter(moment.utc(new Date("2023-09-18")).format("YYYY-MM-DD"))
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
        <Table>
          <TableHead>
            <TableCell>Event Name Aryan Test</TableCell>
            <TableCell>Total Count</TableCell>
            <TableCell>Sent Count</TableCell>
            <TableCell>Delivered Count</TableCell>
            <TableCell>Read Count</TableCell>
            {/* <TableCell>Bill For</TableCell> */}
          </TableHead>
          <TableBody>
            {!eventLoading ? (eventData?.length ? eventData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map(v => (<TableRow>
              <TableCell>{(v?.eventName)}</TableCell>
              <TableCell>{(v?.totalCount)}</TableCell>
              <TableCell>{(v?.sentCount)}</TableCell>
              <TableCell>{(v?.deliveredCount)}</TableCell>
              <TableCell>{(v?.readCount)}</TableCell>
            </TableRow>)) : <>
            <TableCell /><TableCell />
            <TableCell>No events for selected dates!</TableCell>
            <TableCell /><TableCell />
            </> ) : <>
            <TableCell /><TableCell />
            <TableCell><CircularProgress /></TableCell>
            <TableCell /><TableCell />
            </>
            }
          </TableBody>
        </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: eventData?.length }]}
          component="div"
          count={eventData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  )
}
