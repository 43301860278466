import { Grid, TextField } from "@material-ui/core"
import React from "react"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { Button, Modal, Row } from "reactstrap"
import customAxios from "../../../axios"
import { loadOrganisations } from "../../../redux/actions/user.actions"

export default function UpdateOrganizationModal({
  isOpen,
  state,
  setState,
  setIsOpen,
}) {
  const dispatch = useDispatch()
  const submitOrgUpdate = () => {
    let objToSend = {}
    objToSend.fees = state.fees
    objToSend.followUp = state.followUp
    objToSend.address = state.address
    objToSend.organisationName = state.organisationName
    objToSend.locationName = state.locationName
    objToSend.position = {
      longitude: 0,
      latitude: 0,
    }
    customAxios
      .put(`organisation/update/${state._id}`, objToSend)
      .then((res) => {
        console.log(res)
        if (res.data.success) {
          toast.success("Location Updated Successfully")
          dispatch(loadOrganisations())
          setIsOpen(false)
        } else {
          toast.error("Something went wrong")
        }
      })
      .catch((err) => console.log(err))
  }
  return (
    <div>
      <Modal
        className="modal-dialog-centered modal-xl "
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Update Organisation
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setIsOpen(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Row className="p-3">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  value={state.locationName}
                  variant="outlined"
                  fullWidth
                  label="Clinic Name"
                  onChange={(e) =>
                    setState({
                      ...state,
                      locationName: e.target.value,
                      organisationName: e.target.value,
                    })
                  }
                ></TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={state.address}
                  variant="outlined"
                  fullWidth
                  label="Address"
                  onChange={(e) =>
                    setState({ ...state, address: e.target.value })
                  }
                ></TextField>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  type="number"
                  value={state.fees}
                  variant="outlined"
                  fullWidth
                  label="	Fees"
                  onChange={(e) => setState({ ...state, fees: e.target.value })}
                ></TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  value={state.followUp}
                  variant="outlined"
                  fullWidth
                  label="Follow Up "
                  onChange={(e) =>
                    setState({ ...state, followUp: e.target.value })
                  }
                ></TextField>
              </Grid>
            </Grid>
          </Row>
          <Button
            color="default"
            size="lg"
            onClick={submitOrgUpdate}
            style={{ textAlign: "center" }}
          >
            Submit
          </Button>
        </div>
      </Modal>
    </div>
  )
}
