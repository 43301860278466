import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core"
import {
  Check,
  EditOutlined,
  DeleteOutline,
  ExpandLess,
  ExpandMore,
} from "@material-ui/icons"
import { Autocomplete } from "@material-ui/lab"
import Autosuggest from "react-autosuggest"
import CustomAutocomplete from "../../Common/AutoComplete"
import mixpanel from "mixpanel-browser"
import { TabSwitchingHelperText } from "../helper"

function GenericCardTable({
  cardTitle,
  suggestedTitle,
  suggestedTab,
  suggestedData = [],
  onSuggestedHoverLabel = "",
  suggestedDataLabel,
  tableHeaders,
  tableData = {},
  setTableData,
  cardKey,
  hello,
  tabSwitching = false,
  addOnFunction = null,
  ...props
}) {
  const [state, setState] = useState({})
  const [addState, setAddState] = useState({})
  const [suggestExpand, setSuggestExpand] = useState(false)
  const [suggestions, setSuggestions] = useState([])

  const theme = useTheme()

  useEffect(() => {
    if (!tableHeaders?.find((f) => (f?.required ? !state?.[f?.key] : false))) {
      setAddState({ ...addState, add: true })
    } else setAddState({ ...addState, add: false })
  }, [state])

  useEffect(() => {
    if (addOnFunction) addOnFunction(state, setState)
  }, [state?.name])

  const getTableColumn = (field, index) => {
    switch (field?.type) {
      case "text": {
        return (
          <Grid container>
            <Grid item xs={12}>
              <Typography>{field?.label}</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                autoFocus={field.focus}
                InputProps={{
                  endAdornment: field?.endAdornment
                    ? field?.endAdornment
                    : null,
                }}
                placeholder={field?.placeholder}
                value={state?.[field?.key] ? state?.[field?.key] : ""}
                variant="outlined"
                disabled={
                  field?.dependencyKey ? !state?.[field?.dependencyKey] : false
                }
                onChange={(e) => {
                  if (field.onChange)
                    field.onChange(e.target.value, state, setState)
                  else {
                    const temp = { ...state }
                    temp[field?.key] = e.target.value
                    setState({ ...temp })
                  }
                }}
                inputProps={{ maxLength: field.limit ? field.limit : 30 }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && addState?.add) updateTableData()
                }}
              />
            </Grid>
          </Grid>
        )
      }

      case "radio": {
        return (
          <Grid container>
            <Grid item xs={12}>
              <Typography>{field?.label}</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  value={state?.[field?.key] ? state?.[field?.key] : ""}
                  onChange={(e) => {
                    const temp = { ...state }
                    temp[field?.key] = e.target.value
                    setState({ ...temp })
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && addState?.add) updateTableData()
                  }}
                >
                  {field?.options?.map((v) => (
                    <FormControlLabel
                      value={v}
                      label={v}
                      disabled={
                        field?.dependencyKey
                          ? !state?.[field?.dependencyKey]
                          : false
                      }
                      control={<Radio color="primary" />}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            {field.helperText ? (
              <Grid item xs={12} style={{ marginTop: "0.5rem" }}>
                <Typography color="secondary" style={{ fontSize: "14px" }}>
                  {" "}
                  {field.helperText}
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        )
      }

      case "select": {
        return (
          <Grid container>
            <Grid item xs={12}>
              <Typography>{field?.label}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={field?.options}
                getOptionLabel={(option) => option}
                freeSolo={field?.freeSolo ? true : false}
                disabled={
                  field?.dependencyKey ? !state?.[field?.dependencyKey] : false
                }
                openOnFocus
                fullWidth
                value={state?.[field?.key] ? state?.[field?.key] : ""}
                onChange={(e, val) => {
                  if (field.onChange) field.onChange(val, state, setState)
                  else {
                    const temp = { ...state }

                    temp[field?.key] = val
                    console.log("temp", temp)
                    setState({ ...temp })
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    // helperText={
                    //   <Typography style={{ fontSize: "14px" }}>
                    //     Use arrow keys <i class="fas fa-angle-down"></i> &{" "}
                    //     <i class="fas fa-angle-up"></i>
                    //   </Typography>
                    // }
                    {...params}
                    fullWidth
                    placeholder={field?.placeholder}
                    variant="outlined"
                    onKeyDown={(e) => {
                      if (
                        e.key === "Enter" &&
                        addState?.add &&
                        e?.target?.value
                      )
                        updateTableData()
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        )
      }
      case "autocomplete":
        const onChange = (event, { newValue }) => {
          if (field.onChange) {
            field?.onChange(newValue, state, setState)
          } else {
            const temp = { ...state }
            temp[field?.key] = newValue
            setState({ ...temp })
          }
        }

        // Autosuggest will call this function every time you need to update suggestions.
        // You already implemented this logic above, so just use it.

        // Autosuggest will call this function every time you need to clear suggestions.
        return (
          <Grid container>
            <Grid item xs={12}>
              <Typography>{field?.label}</Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomAutocomplete
                field={field}
                inputProps={{
                  placeholder: field.placeholder,
                  value: state?.[field?.key] ? state?.[field?.key] : "",
                  onChange: onChange,
                  disabled: field?.dependencyKey
                    ? !state?.[field?.dependencyKey]
                    : false,
                }}
              />
            </Grid>
          </Grid>
        )

      default:
        return <></>
    }
  }

  const updateTableData = () => {
    if (addState?.index === 0 || addState?.index) {
      const temp = [...tableData[cardKey]]
      temp[addState?.index] = { ...state }
      setTableData({ ...tableData, [cardKey]: [...temp] })
    } else {
      const temp = [...tableData[cardKey]]
      temp.push({ ...state })
      setTableData({ ...tableData, [cardKey]: [...temp] })
    }
    mixpanel.track(`${cardKey.toUpperCase()} Added`, { ...state })
    setState({})
    setAddState({})
  }

  const onEdit = (obj, idx) => {
    setState({ ...obj })
    setAddState({ add: true, index: idx })
  }

  const onDelete = (idx) => {
    const temp = [...tableData[cardKey]]
    temp.splice(idx, 1)
    setTableData({ ...tableData, [cardKey]: [...temp] })
  }

  const onSuggestedClick = (val) => {
    mixpanel.track(`${suggestedTitle} Clicked`, { value: val })
    mixpanel.people.increment(`${suggestedTitle} Clicked`)
    const temp = {}
    if (typeof val === "object") {
      setState({ ...temp, ...val, composition: val?.shortComposition })
    } else {
      temp[tableHeaders?.[0]?.["key"]] = val
      setState({ ...temp, focus: true })
    }
  }

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          // direction="column"
        >
          <Grid item>
            <Typography variant="h6">{cardTitle}</Typography>
          </Grid>
          {tabSwitching ? (
            <Grid item>
              <TabSwitchingHelperText />
            </Grid>
          ) : null}
        </Grid>

        {suggestedTab ? (
          <Grid
            item
            container
            xs={12}
            spacing={1}
            alignItems={"center"}
            style={{ marginBottom: "0.5rem" }}
          >
            <Grid item xs={12}>
              <Typography style={{ opacity: 0.7 }}>{suggestedTitle}</Typography>
            </Grid>
            <Grid item container xs={12} md={10} spacing={1}>
              {suggestedData?.length ? (
                suggestedData?.length < 4 || suggestExpand ? (
                  suggestedData.map((v) => (
                    <Grid item xs={"auto"}>
                      <Chip
                        label={suggestedDataLabel ? v[suggestedDataLabel] : v}
                        onClick={() => onSuggestedClick(v)}
                        title={v[onSuggestedHoverLabel]}
                        tabIndex="-1"
                      />
                    </Grid>
                  ))
                ) : (
                  suggestedData?.slice(0, 3)?.map((v) => (
                    <Grid item xs={"auto"}>
                      <Chip
                        label={suggestedDataLabel ? v[suggestedDataLabel] : v}
                        title={v[onSuggestedHoverLabel]}
                        tabIndex="-1"
                        onClick={() => onSuggestedClick(v)}
                      />
                    </Grid>
                  ))
                )
              ) : (
                <Grid item xs={"auto"}>
                  <Chip label={"--"} style={{ width: "65px" }} tabIndex="-1" />
                </Grid>
              )}
              {suggestedData?.length ? (
                <Grid item xs={"auto"}>
                  <Chip
                    tabIndex="-1"
                    label={suggestExpand ? <ExpandLess /> : <ExpandMore />}
                    onClick={() => setSuggestExpand(!suggestExpand)}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <TableContainer
            style={{
              marginBottom: "0.5%",
              marginTop: "-0.5rem",
              overflow: "visible",
            }}
          >
            <Table>
              <TableHead>
                <TableRow style={{ verticalAlign: "bottom" }}>
                  {tableHeaders?.map((v, index) => (
                    <TableCell width={v?.width}>
                      {getTableColumn(v, index)}
                    </TableCell>
                  ))}
                  <TableCell>
                    <IconButton
                      disabled={!addState?.add}
                      style={{
                        backgroundColor: addState?.add
                          ? theme.palette.primary.main
                          : "#D3D3D3",
                        color: "white",
                      }}
                      onClick={updateTableData}
                    >
                      <Check />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ backgroundColor: "#f8f9fe" }}>
                {tableData[cardKey]?.map((v, i) => (
                  <TableRow>
                    {tableHeaders?.map((field) => (
                      <TableCell style={{ padding: "4px" }}>
                        {v?.[field?.key]}
                      </TableCell>
                    ))}
                    <TableCell style={{ padding: "4px" }}>
                      <IconButton onClick={() => onEdit(v, i)} tabIndex="-1">
                        <EditOutlined />
                      </IconButton>
                      <IconButton
                        tabIndex="-2"
                        style={{ color: "#EC7063" }}
                        onClick={() => onDelete(i)}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  )
}

export default GenericCardTable
