import { Grid, Typography, TextField, Button } from "@material-ui/core"
import React, { useEffect } from "react"
import GenericAnimation from "../../animation"
import { TabSwitchingHelperText } from "../helper"
function SystemExamination({ state, setState }) {
  const seFields = [
    { label: "CVS" },
    { label: "CNS" },
    { label: "P/A" },
    { label: "R/S" },
  ]
  useEffect(() => {
    const res = state?.otherData?.systemExamination
      ? state?.otherData?.systemExamination
      : seFields
    setState({
      ...state,
      otherData: { ...state?.otherData, systemExamination: [...res] },
    })
  }, [])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} container justifyContent="space-between">
        <Typography variant="h6">System Examination:</Typography>
        <TabSwitchingHelperText />
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid
          item
          xs={12}
          style={{ flexWrap: "nowrap", marginTop: "-0.8rem" }}
          container
          alignItems="center"
        >
          <Typography color="secondary">
            *In order to apply ‘Normal’ to all fields{" "}
          </Typography>
          <Button
            color="primary"
            onClick={() => {
              const temp = state?.otherData?.systemExamination.map((ele) => ({
                label: ele.label,
                value: "Normal",
              }))
              console.log("999", temp)
              setState({
                ...state,
                otherData: { ...state.otherData, systemExamination: [...temp] },
              })
            }}
          >
            Click Here
          </Button>
        </Grid>
        <Grid
          container
          xs={12}
          item
          spacing={2}
          style={{ marginTop: "-1.5rem" }}
        >
          {state?.otherData?.systemExamination?.map((v, i) => (
            <Grid item container xs={6} md={3} alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h6">{v?.label}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  autoFocus={i === 0}
                  // InputProps={{
                  //   endAdornment: (
                  //     <>
                  //       <Typography color="secondary">{v?.metricUnit}</Typography>
                  //       <DeleteForever
                  //         color="error"
                  //         style={{ cursor: "pointer" }}
                  //         onClick={() => {
                  //           const temp = [...state.vitals];
                  //           temp.splice(i, 1);
                  //           setState({ ...state, vitals: [...temp] });
                  //         }}
                  //       />
                  //     </>
                  //   ),
                  // }}
                  //   onKeyDown={(e) => {
                  //   if (e.key === "Enter" &&(e.target.value === "" || re.test(e.target.value)) ){

                  //   }
                  // }}
                  value={state?.otherData?.systemExamination?.[i]["value"]}
                  placeholder="None"
                  onChange={(e) => {
                    const temp = [...state?.otherData?.systemExamination]
                    temp[i]["value"] = e.target.value
                    setState({
                      ...state,
                      otherData: {
                        ...state?.otherData,
                        systemExamination: [...temp],
                      },
                    })
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SystemExamination
