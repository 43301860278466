import {
  TablePagination,
  Button,
  IconButton,
  Typography,
  Grid,
  Divider,
  TextField,
} from "@material-ui/core"
import moment from "moment"
import mixpanel from "mixpanel-browser"

import React, { useEffect, useState } from "react"
import { useHistory, withRouter } from "react-router-dom"
import { toast } from "react-toastify"
import { Card, CardHeader, Table, Row, Col } from "reactstrap"

import Input from "reactstrap/lib/Input"

import { useSelector } from "react-redux"

import customAxios from "../../axios"
import LoadingAnimation from "../../views/pages/examples/LoadingAnimation"
import FileUploadModal from "../Common/FileUploadModal"
import GenericDialogForm from "../CreatePrescription/GenericDialog"
import whatsappLogo from "./../../assets/img/icons/common/whatsapp.png"
function AllPatinetsTable({ data, ...props }) {
  const history = useHistory()
  const [dialogLoading, setDialogLoading] = useState(false)
  const [open, setIsOpen] = useState(false)
  const [uploadOpen, setUploadOpen] = useState(false)
  const [csvFile, setCsvFile] = useState([])
  const [locations, setLocations] = useState([])
  const [currentLocation, setCurrentLocation] = useState("")
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [searchQuery, setSearchQuery] = useState("")
  const [filterContact, setFilterContact] = useState([])

  const handleBulkUpload = (files) => {
    setDialogLoading(true)
    customAxios
      .put("/contacts/upload-csv", { ...files[0] })
      .then((res) => {
        if (res.data.success) {
          setUploadOpen(false)
          setDialogLoading(false)

          toast.success("File uploaded successfully. ")
          toast.info("It might take upto 5 minutes to update the patient list.")
        } else {
          setDialogLoading(false)

          toast.error(
            "File upload failed. Please check the format of the sample file for reference."
          )
        }
        setCsvFile([])
      })
      .catch((err) => {
        setDialogLoading(false)
        toast.error("Some error occured")
      })
  }
  const getAllPatients = () => {
    setIsLoading(true)

    customAxios
      .put(`/contacts/filter`,
        {}
      )
      .then((res) => {
        setIsLoading(false)
        const temp = res.data.data.reverse()
        setTableData([...temp])
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    getAllPatients()
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (searchQuery) {
      const filterData = tableData.filter(
        (ele) =>
          ele?.name
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          ele?.phoneNo?.toString().includes(searchQuery.toLowerCase())
      )

      setFilterContact(filterData.slice())
    } else {
      setFilterContact([...tableData.slice()])
    }
  }, [searchQuery, tableData])

  return (
    <Col className="mt-2">
      <Card>
        <CardHeader className="border-0">
          <Row style={{ alignItems: "center" }}>
            <Col md="5">
              <h3 className="mb-0">All Contacts</h3>
            </Col>
            <Col md="3">
              <TextField
                variant="outlined"
                fullWidth
                value={searchQuery}
                onChange={(e) => {
                  mixpanel.track("Searched for contact")
                  setSearchQuery(e.target.value)
                }}
                InputProps={{
                  startAdornment: (
                    <i
                      className="fas fa-search"
                      style={{ color: "grey", marginRight: "0.4rem" }}
                    ></i>
                  ),
                }}
                style={{ backgroundColor: "#fff" }}
                color="primary"
                placeholder="Search"
              ></TextField>
            </Col>
            <Col md="2">
              <Button
                color="primary"
                startIcon={<i className="fas fa-plus "></i>}
                fullWidth
                variant="contained"
                onClick={() => {
                  setIsOpen(true)
                }}
              >
                Add new contact
              </Button>
            </Col>{" "}
            <Col md="2">
              <Button
                color="primary"
                startIcon={<i class="fas fa-file-import"></i>}
                fullWidth
                variant="contained"
                onClick={() => {
                  setUploadOpen(true)
                }}
              >
                Import Contacts
              </Button>
            </Col>
          </Row>
        </CardHeader>

        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">Name</th>

              <th scope="col">Contact Number</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          {isLoading && (
            <tbody>
              <tr>
                <td colSpan={6}>
                  <LoadingAnimation></LoadingAnimation>
                </td>
              </tr>
            </tbody>
          )}
          {!isLoading && tableData?.length === 0 && (
            <tbody>
              <tr>
                <td colSpan={6} style={{ textAlign: "center" }}>
                  <h3>No contact found.</h3>
                </td>
              </tr>
            </tbody>
          )}
          <tbody className="list">
            {!isLoading &&
              filterContact &&
              filterContact
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <th scope="row">
                        <div className="">
                          {data.name} <br />
                          {data.age ? data?.age + "," : null}{" "}
                          {data?.gender?.charAt(0).toUpperCase()}
                        </div>
                      </th>
                      <td>{data.phoneNo}</td>
                      <td>
                        {/* <IconButton
                          color="textPrimary"
                          title="View Profile"
                          onClick={() => {
                            history.push(`/admin/patientProfile/${data._id}`)
                          }}
                        >
                          <i class="fas fa-eye"></i>
                        </IconButton> */}
                        {/* <IconButton
                          color="primary"
                          title="Call Patient"
                          onClick={() => {
                            var a = document.createElement("a")
                            document.body.appendChild(a)
                            a.style = "display: none"
                            a.href = `tel:${data.phoneNo}`
                            a.click()
                          }}
                        >
                          <i class="fas fa-phone-alt fa-flip-horizontal fa-0.75x"></i>{" "}
                        </IconButton> */}

                        <IconButton
                          style={{ color: "green" }}
                          title="Chat"
                          onClick={() => {
                            history.push(`/admin/chat`, { patient: data })
                          }}
                        >
                          <img
                            src={whatsappLogo}
                            style={{
                              width: "30px",
                              objectFit: "contain",
                            }}
                          />{" "}
                        </IconButton>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
                <Divider />
            <tr>
              <td colSpan={3}>
                <TablePagination
                  align="center"
                  rowsPerPageOptions={[
                    5,
                    10,
                    20,
                    50,
                    { label: "All", value: tableData?.length },
                  ]}
                  component="div"
                  count={tableData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(e, page) => {
                    setPage(page)
                  }}
                  onRowsPerPageChange={(e) => {
                    setRowsPerPage(e.target.value)
                    setPage(0)
                  }}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </Card>
      <GenericDialogForm
        dialogContent={[
          { label: "Name", key: "name", required: true },
          {
            label: "Phone",
            key: "phoneNo",
            required: true,
            type: "number",
            maxLength: 10,
          },
          { label: "Age", key: "age" },
          {
            label: "Gender",
            key: "gender",
            type: "select",
            options: [
              { label: "Male", value: "male" },
              { label: "Female", value: "female" },
              { label: "Other", value: "other" },
            ],
          },
          {
            label: "Address",
            key: "address",
          }
        ]}
        showRequired={true}
        open={open}
        handleClose={() => setIsOpen(false)}
        handleSubmit={(data) => {
          console.log(data)
          customAxios
            .post("/contacts/create", { ...data, isNew: true })
            .then((res) => {
              if (res.data.success) {
                toast.success("Patient added successfully!")
                getAllPatients()
              } else {
                toast.error("Some error occured")
              }
            })
            .catch((err) => {
              toast.error("Some error occured")
            })
        }}
        dialogTitle="Add New Contact"
      ></GenericDialogForm>
      <FileUploadModal
        multiple={false}
        allowedFileTypes={".csv"}
        message={
          <Grid item xs={12} style={{ marginBottom: "1rem" }}>
            <Typography>
              <ul>
                <li>
                  Upload a <strong>.csv</strong> file to import exisiting
                  contacts from your database
                </li>
                <li>
                  <strong>Phone number</strong> is a <strong>required</strong>{" "}
                  field
                </li>
                <li>
                  First row should be the same as mentioned in the file below
                </li>
                <li>
                  It might take <strong>upto 5 minutes</strong> to update the
                  patient list.
                </li>
              </ul>
              Download a sample csv from
              <span
                onClick={() => [
                  window.open(
                    "https://storage.googleapis.com/website-paperplane/Bulk_Upload_Sample.csv"
                  ),
                ]}
                style={{
                  textDecoration: "underline",
                  color: "blue",
                  cursor: "pointer",
                }}
              >
                {" "}
                here
              </span>
            </Typography>
          </Grid>
        }
        open={uploadOpen}
        setOpen={setUploadOpen}
        state={csvFile}
        loading={dialogLoading}
        dialogTitle="Upload .csv"
        setState={setCsvFile}
        onSubmit={() => handleBulkUpload(csvFile)}
      ></FileUploadModal>
    </Col>
  )
}

export default withRouter(AllPatinetsTable)
