import React, { useEffect, useState } from "react"
import {
  Avatar,
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from "@material-ui/core"
import customAxios from "../../axios"
import {
  Add,
  AddBoxOutlined,
  BorderColor,
  CheckCircle,
  CheckCircleOutline,
  ChevronRight,
  Description,
  Edit,
  EditAttributesOutlined,
  FastForward,
  History,
  Home,
  HomeOutlined,
  Info,
  LocationCity,
  PersonPinCircle,
  PinDrop,
  Print,
} from "@material-ui/icons"
import whatsappLogo from "./../../assets/img/icons/common/whatsapp.png"
import "./sider.styles.css"
import userImage from "./../../assets/user.png"
import moment from "moment"
import { toast } from "react-toastify"
import { useSelector } from "react-redux"
import GenericDialogForm from "../CreatePrescription/GenericDialog"
import { useHistory } from "react-router"
function Sidebar({ setActiveTab, activeTab, id, setLoading }) {
  // const { id } = useParams()
  // const history = useHistory()
  const [patientData, setPatientData] = useState({})
  const [updatePatientOpen, setUpdatePatientOpen] = useState(false)
  const [updater, setUpdater] = useState(false)
  const orgId = useSelector((state) =>
    state.auth.isDoctor
      ? state.user.organisations?.[0]?._id
      : state?.user?.user?._id
  )
  const isDoctor = useSelector((state) => state.auth.isDoctor)
  const history = useHistory()
  const handleUpdatePatient = (data) => {
    setLoading(true)
    customAxios
      .put(`contacts/update/${id}`, { ...data })
      .then((res) => {
        setLoading(false)
        if (res.data.success) {
          setUpdater((prev) => !prev)
          toast.success("Patient details updated successfully.")
        } else {
          toast.error("Some error occured")
        }
      })
      .catch((Err) => {
        toast.error("Some error occured")
        setLoading(false)
      })
  }
  const updatePatientHeaders = [
    {
      label: "Name",
      key: "name",
      type: "text",
    },
    {
      label: "Phone",
      key: "phoneNo",
      disabled: true,
      type: "number",
    },
    {
      label: "Age",
      key: "age",
      type: "number",
    },
    {
      label: "Gender",
      key: "gender",
      type: "select",
      options: [
        { label: "Male", value: "male" },
        { label: "Female", value: "female" },
        { label: "Other", value: "other" },
      ],
    },
  ]
  useEffect(() => {
    customAxios
      .get(`/patients?patient_id=${id}`)
      .then((res) => {
        const responseData = res.data.data
        setPatientData({
          ...responseData,
        })
      })
      .catch((error) => console.log(error))
    //eslint-disable-next-line
  }, [id, updater])

  const patientInforoutes = [
    {
      label: "Add Vitals",
      icon: Add,
    },
    {
      label: "Medical Record",
      icon: AddBoxOutlined,
    },
    {
      label: "Patient Assessment",
      icon: Description,
    },
    {
      label: "History",
      icon: History,
    },
  ]
  const handleGenerateSticker = () => {
    setLoading(true)
    customAxios

      .get(`doctor/patient-sticker/${patientData?._id}?org_id=${orgId}`)
      .then((res) => {
        if (res.data.success) {
          setLoading(false)
          let sticker = window.open(res.data.data.url, "_blank")
          if (sticker)
            sticker.onLoad = function () {
              sticker.print()
            }
        } else {
          setLoading(false)
          toast.error("Some error occured")
        }
      })
      .catch((err) => {
        setLoading(false)
        toast.error("Some error occured")
      })
  }
  return (
    <Grid
      container
      style={{
        height: "80vh",
        width: "300px",
        padding: "1rem",
        backgroundColor: "#fff",
        margin: "1rem 1rem 1rem 2rem",
        marginLeft: "2rem",
        boxShadow: "0 0 2rem 0 rgba(136, 152, 170, 0.15)",
        borderRadius: "40px 0px 40px 0px",
      }}
    >
      <Grid
        item
        xs={12}
        container
        alignItems="center"
        justifyContent="center"
        style={{ position: "relative" }}
      >
        <IconButton
          style={{
            position: "absolute",
            top: "0rem",
            zIndex: "10",
            // backgroundColor: "#f3f3f3 ",
            padding: "0.5rem",
            right: "0",
          }}
          onClick={() => setUpdatePatientOpen(true)}
        >
          <Edit style={{ fontSize: "16px" }} color="primary" />
        </IconButton>

        <Grid
          item
          xs={4}
          container
          style={{
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar
            style={{
              objectFit: "contain",
              width: "60px",
              height: "60px",
              backgroundColor: "#5e72e4",
            }}
          >
            {patientData?.name?.split("")[0]}
          </Avatar>
        </Grid>
        <Grid item xs={8}>
          <Typography style={{ fontWeight: "700" }}>
            {patientData?.name}
          </Typography>
          <Typography>
            {patientData?.age} |{" "}
            {patientData?.gender?.split("")[0].toUpperCase()} |{" "}
            {patientData?.birthDate}
          </Typography>
          <Typography>{patientData?.phoneNo}</Typography>
          <Typography>
            <span style={{}}># {patientData?.code}</span>
          </Typography>
          <Button
            onClick={() => handleGenerateSticker()}
            title="Print patient details on a sticker"
            color="primary"
            style={{ borderRadius: "11px" }}
            variant="outlined"
          >
            Print Sticker
          </Button>
          <div style={{ marginTop: "0.5rem" }}>
            <IconButton
              color="primary"
              title="Call Patient"
              onClick={() => {
                var a = document.createElement("a")
                document.body.appendChild(a)
                a.style = "display: none"
                a.href = `tel:${patientData.phoneNo}`
                a.click()
              }}
            >
              <i class="fas fa-phone-alt fa-flip-horizontal fa-0.75x"></i>{" "}
            </IconButton>

            <IconButton
              style={{ color: "green" }}
              title="Chat"
              onClick={() => {
                history.push(`/admin/chat`, { patient: patientData })
              }}
            >
              <img
                src={whatsappLogo}
                style={{
                  width: "28px",
                  objectFit: "contain",
                }}
              />{" "}
            </IconButton>
          </div>
        </Grid>
        {patientData?.address ? (
          <Grid item xs={12} style={{ margin: "0.5rem 0" }}>
            <Typography>
              <PinDrop color="primary" fontSize="small" />{" "}
              <span style={{}}> {patientData?.address}</span>
            </Typography>
          </Grid>
        ) : null}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ fontWeight: "600" }}>Patient Info</Typography>
      </Grid>
      {patientInforoutes?.map((ele) => {
        const { icon: Icon } = ele
        if (!isDoctor && ele?.onlyDoctor) return null
        else
          return (
            <Grid
              item
              xs={12}
              container
              onClick={() => setActiveTab(ele.label)}
              onMouseEnter={() => setActiveTab(ele.label)}
              alignItems="center"
              style={{
                cursor: "pointer",
                flexWrap: "nowrap",
                backgroundColor: activeTab === ele.label ? "#5e72e4" : "#fff",
                borderRadius: "11px",
                padding: "0.5rem ",
                margin: "0.5rem 0 0 0 ",
              }}
              justifyContent="space-between"
            >
              <Grid item container>
                <Icon
                  style={{
                    color: activeTab === ele.label ? "#fff" : "#5e72e4",
                  }}
                />
                <Typography
                  style={{
                    paddingLeft: "1rem",
                    color: activeTab === ele.label ? "#fff" : "#696969",
                  }}
                >
                  {ele.label}
                </Typography>
              </Grid>
              <Grid item>
                <ChevronRight
                  style={{
                    color: activeTab === ele.label ? "#fff" : "#5e72e4",
                  }}
                />
              </Grid>
            </Grid>
          )
      })}
      {updatePatientOpen ? (
        <GenericDialogForm
          style={{ overflowY: "visible" }}
          formData={patientData}
          open={updatePatientOpen}
          handleClose={() => setUpdatePatientOpen(false)}
          dialogContent={updatePatientHeaders}
          dialogTitle="Update Patient Details"
          handleSubmit={handleUpdatePatient}
        ></GenericDialogForm>
      ) : null}
    </Grid>
  )
}

export default Sidebar
