import {
    TablePagination,
    Button,
    IconButton,
    Typography,
    Grid,
    Divider,
  } from "@material-ui/core"
  import moment from "moment"
  
  import React, { useEffect, useState } from "react"
  import { useHistory, withRouter } from "react-router-dom"
  import { toast } from "react-toastify"
  import { Card, CardHeader, Table, Row, Col } from "reactstrap"
  
  import Input from "reactstrap/lib/Input"
  
  import { useSelector } from "react-redux"
  
  import customAxios from "../../axios"
  import LoadingAnimation from "../../views/pages/examples/LoadingAnimation"
  import FileUploadModal from "../Common/FileUploadModal"
  import GenericDialogForm from "../CreatePrescription/GenericDialog"
  import whatsappLogo from "./../../assets/img/icons/common/whatsapp.png"
import { AccessTime, FastForward } from "@material-ui/icons"


  function Broadcast({ ...props }) {
    const history = useHistory()
    const [dialogLoading, setDialogLoading] = useState(false)
    const [open, setIsOpen] = useState(false)
    const [uploadOpen, setUploadOpen] = useState(false)
    const [csvFile, setCsvFile] = useState([])
    const [locations, setLocations] = useState([])
    const [currentLocation, setCurrentLocation] = useState("")
    const [tableData, setTableData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(0)

    const getStatus = (status) => {
      switch(status) {
        case "PENDING": 
          return (
            <Typography style={{color: "#E59866"}}>
            <AccessTime /> SCHEDULED
            </Typography>
          )
        
        case "STARTED": 
          return (
            <Typography style={{color: "#7DCEA0"}}>
            <FastForward /> RUNNING
            </Typography>
          )
        
          case "COMPLETED": 
          return (
            <Typography>
            COMPLETED
            </Typography>
          )
        
      }
    }
  
    const handleBulkUpload = (files) => {
      setDialogLoading(true)
      customAxios
        .put("/patients/upload-csv", { ...files[0] })
        .then((res) => {
          if (res.data.success) {
            setUploadOpen(false)
            toast.success("File uploaded successfully. ")
            toast.info("It might take upto 5 minutes to update the patient list.")
          } else {
            setDialogLoading(false)
  
            toast.error(
              "File upload failed. Please check the format of the sample file for reference."
            )
          }
          setCsvFile([])
        })
        .catch((err) => {
          setDialogLoading(false)
          toast.error("Some error occured")
        })
    }
    const getBroadcastList = () => {
      setIsLoading(true)
  
      customAxios
        .get(
        `/broadcast/filter`
        )
        .then((res) => {
          setIsLoading(false)
          const temp = res.data.data.reverse()
          setTableData([...temp])
        })
        .catch((err) => console.log(err))
    }

    useEffect(() => {
      getBroadcastList()
    }, [])
  
    return (
      <Col className="mt-2">
        <Card>
          <CardHeader className="border-0">
            <Row style={{ alignItems: "center" }}>
              <Col md="10">
                <h3 className="mb-0">Broadcasts</h3>
              </Col>{" "}
              <Col md="2">
                <Button
                  color="primary"
                  startIcon={<i className="fas fa-plus "></i>}
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    history.push(`/admin/broadcast/create`)
                }}
                >
                  New Broadcast
                </Button>
              </Col>
            </Row>
          </CardHeader>
  
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Status</th>
                <th scope="col">Template Name</th>
                <th scope="col">Recipients</th>
                <th scope="col">Broadcast Time</th>
                <th scope="col">Created At</th>
              </tr>
            </thead>
            {isLoading && (
              <tbody>
                <tr>
                  <td colSpan={6}>
                    <LoadingAnimation></LoadingAnimation>
                  </td>
                </tr>
              </tbody>
            )}
            {!isLoading && tableData?.length === 0 && (
              <tbody>
                <tr>
                  <td colSpan={6} style={{ textAlign: "center" }}>
                    <h3>No Broadcast Found</h3>
                  </td>
                </tr>
              </tbody>
            )}
            <tbody className="list">
              {!isLoading &&
                tableData &&
                tableData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <th scope="row">
                            {data.name}
                        </th>
                        <th>{getStatus(data.status?.toUpperCase())}</th>
                        <td>{data.templateData?.name}</td>
                        <td>{data?.totalNos ? data?.totalNos : 0}</td>
                        <td>
                          {data?.start ? moment(data.start).format("DD/MM/YYYY hh:mm A") : moment(data.date).format("DD/MM/YYYY hh:mm A")}
                        </td>
                        <td>
                          {moment(data.date).format("DD/MM/YYYY hh:mm A")}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
              <tr>
                <td colSpan={6}>
                  <TablePagination
                    align="center"
                    rowsPerPageOptions={[
                      5,
                      10,
                      20,
                      50,
                      { label: "All", value: tableData?.length },
                    ]}
                    component="div"
                    count={tableData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, page) => {
                      setPage(page)
                    }}
                    onRowsPerPageChange={(e) => {
                      setRowsPerPage(e.target.value)
                      setPage(0)
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </Card>
      </Col>
    )
  }
  
  export default withRouter(Broadcast)
  