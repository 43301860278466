import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import customAxios from "../../../axios"
import {
  Grid,
  Typography,
  Button,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Chip,
} from "@material-ui/core"
import { Send } from "@material-ui/icons"
import moment from "moment"
import noRecords from "./../../../assets/img/consultation/NoMedicalHistory.png"

function PaymentHistory({
  patientId,
  setLoading,
  setPaymentHistoryDialogOpen,
}) {
  const [tableData, setTableData] = useState([])
  useEffect(() => {
    getPaymentHistory()
  }, [patientId])
  const getPaymentHistory = () => {
    setLoading(true)
    customAxios
      .get(`/analytics/payment-invoice/all?patient_id=${patientId}`)
      .then((res) => {
        if (res.data.success) {
          setTableData(res.data.data.reverse().slice())
        }
        setLoading(false)
      })
      .catch((err) => {
        toast.error("Some error occured")
        setLoading(false)
      })
  }
  return (
    <Grid container style={{ padding: "1rem" }}>
      {tableData.length ? (
        <Grid item xs={12}>
          <TableContainer style={{ width: "100%" }}>
            <Table style={{ width: "100%" }}>
              <TableHead>
                <TableRow
                  style={{
                    background: "aliceblue",
                    fontWeight: "600",
                    fontSize: "16px",
                  }}
                >
                  <TableCell>Date</TableCell>
                  <TableCell>Bill For</TableCell>
                  <TableCell>Mode</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row) => {
                  return (
                    <TableRow>
                      <TableCell style={{ maxWidth: "23%" }}>
                        {moment.utc(row.date).format("DD MMM YYYY hh:MM A ")}
                      </TableCell>
                      <TableCell>{row.invoiceFor}</TableCell>
                      <TableCell>
                        {row?.modeOfPayment}
                        {row.modeOfPayment === "Online(Paperplane)" ? (
                          row.paid ? (
                            <Chip
                              label="Paid"
                              style={{
                                backgroundColor: "green",
                                color: "white",
                                borderRadius: "0.25em",
                                marginLeft: "0.3rem",
                              }}
                            ></Chip>
                          ) : (
                            <Chip
                              label="Pending"
                              style={{
                                backgroundColor: "red",
                                color: "white",
                                borderRadius: "0.25em",
                                marginLeft: "0.3rem",
                              }}
                            ></Chip>
                          )
                        ) : null}
                      </TableCell>
                      <TableCell>
                        <strong>₹{row.amount}</strong>
                      </TableCell>
                      <TableCell style={{ maxWidth: "23%" }}>
                        {row.treatmentId ? (
                          <Typography>
                            <i
                              class="fas fa-procedures"
                              title={row?.treatment?.name}
                              style={{
                                fontSize: "24px",
                                color: "goldenrod",
                                marginRight: "0.3rem",
                              }}
                            ></i>
                            {row.treatment[0].name}
                          </Typography>
                        ) : row?.isOnline ? (
                          <i
                            className="fas fa-wallet"
                            title="Online Appointment"
                            style={{ fontSize: "24px", color: "green" }}
                          ></i>
                        ) : row.vaccination ? (
                          <i
                            className="fas fa-syringe"
                            title="Vaccination Appointment"
                            style={{ fontSize: "24px", color: "green" }}
                          ></i>
                        ) : (
                          <>
                            <i
                              class="fas fa-walking"
                              title="Offline Appointment"
                              style={{
                                fontSize: "24px",
                                color: "grey",
                              }}
                            ></i>{" "}
                            {row.emergency ? (
                              <Chip
                                label="Emergency Appointment"
                                style={{
                                  backgroundColor: "#FF3E43",
                                  color: "white",
                                  borderRadius: "0.25em",
                                  marginLeft: "1rem",
                                }}
                              ></Chip>
                            ) : null}
                          </>
                        )}{" "}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ) : null}
      {tableData.length === 0 ? (
        <Grid
          item
          xs={12}
          style={{
            height: "60vh",
            justifyContent: "center",
            alignItems: "center",
          }}
          container
          direction="column"
        >
          <img
            src={noRecords}
            style={{ width: "400px", objectFit: "contain" }}
            alt="empty"
          />
          <Typography variant="h6">No records found.</Typography>
        </Grid>
      ) : null}
    </Grid>
  )
}

export default PaymentHistory
